





import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiAppliedPosition } from '@/admin/services/api/AdminService.dtos';
import { formatDate, formatTime, formatTimezone } from '@/tests/utils/general.utils';

@Component({})
export default class JobApplication extends Vue {
    @Prop()
    readonly application!: IApiAppliedPosition;

    get applicationDate(): string {
        const date = new Date(this.application.appliedAt);
        const dateString = formatDate(date);
        const timeString = formatTime(date);
        const timezone = formatTimezone(date);

        return `${dateString} - ${timeString} ${timezone}`;
    }
}
