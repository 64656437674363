





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class TagInput extends Vue {
    @Prop()
    value!: string[];

    @Prop({ default: false })
    disabled!: boolean;

    @Prop({ default: null })
    placeholder!: string | null;

    @Prop()
    availableData!: string[];

    @Watch('availableData')
    onAvailableDataChange(newVal: string[]) {
        this.availableData = newVal;
    }

    @Watch('value')
    onValueChange(newVal: string[]) {
        this.cValue = newVal;
    }

    @Prop({ default: false })
    allowNew!: boolean;

    @Prop({ default: false })
    allowDuplicates!: boolean;

    @Prop({ default: null })
    type!: string | null;

    cValue: string[] = [];
    filteredData: string[] = [];

    mounted() {
        this.cValue = this.value;
    }

    filter(text: string) {
        const applicableData = this.allowDuplicates ? this.availableData :
                this.availableData.filter(d => !this.value.includes(d));

        this.filteredData = applicableData.filter(e => {
            return e.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });
    }

    async trim() {
        this.cValue = this.cValue.map(v => v.trim());
    }


    async emitInput(data: string[]) {
        this.$emit('input', data);
    }
}
