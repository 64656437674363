export type UUID = string;

export enum USER_TYPE {
    talent = 'talent',
    company = 'company',
}

export enum USER_STATE {
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
    TEMP = 'TEMP',
    PENDING_DEMO = 'PENDING_DEMO',
    IN_APPROVAL = 'IN_APPROVAL',
}

export enum UI_USER_STATE {
    UNREGISTERED = 'Unregistered - frontend only state',

    REGISTRATION_BASIC_INFO = 'Registration - Basic Info',
    REGISTRATION_ROLE = 'Registration - Role',
    REGISTRATION_EXPERIENCE = 'Registration - Experience',
    REGISTRATION_PROJECT_EXPERIENCE = 'Registration - Project Experiences',
    REGISTRATION_COMPLETED = 'Registration - Completed',

    WAITING_SKILL_VERIFICATION = 'Waiting Skill Verification',
    WAITING_SKILL_VERIFICATION_RESULTS = 'Waiting Skill Verification Results',
    WAITING_MANUAL_VERIFICATION = 'Waiting Manual Verification',
    WAITING_MANUAL_VERIFICATION_RESULTS = 'Waiting Manual Verification Results',
    WAITING_VERIFICATION = 'Waiting Verification',

    SCHEDULED_CALL = 'Scheduled Call',
    VERIFIED = 'Verified',
    VERIFIED_CHECKED = 'Verified Checked',
    REJECTED = 'Rejected',
    DEACTIVATED = 'Deactivated',
    DELETED = 'Deleted',
}

export enum CALENDLY_LINK_TYPE {
    DATA_ENGINEER_TECHNICAL_INTERVIEW = 'data_engineer_technical_interview',
    BLOCKCHAIN_ETH_ENGINEER_TECHNICAL_INTERVIEW = 'blockchain_eth_engineer_technical_interview',
    BACKEND_TECHNICAL_INTERVIEW = 'backend_technical_interview',
    FRONTEND_TECHNICAL_INTERVIEW = 'frontend_technical_interview',
    FLUTTER_TECHNICAL_INTERVIEW = 'flutter_technical_interview',
    DEVOPS_KUBERNETES_TECHNICAL_INTERVIEW = 'devops_kubernetes_technical_interview',
    TECHNICAL_INTERVIEW = 'technical_interview',
    FINAL_INTERVIEW = 'final_interview',
    COMPANY_CALL = 'company_call',
}

export enum AVAILABILITY {
    ACTIVELY_LOOKING = 'ACTIVELY_LOOKING',
    OPEN = 'OPEN',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum INTEREST_STATUS {
    FULL_TIME = 'full-time',
    PART_TIME = 'part-time',
    BOTH = 'both'
}

export enum TALENT_PROJECT_EXPERIENCE_TYPE {
    WORK = 'WORK',
    EDUCATION = 'EDUCATION',
    PROJECT = 'PROJECT',
    CERTIFICATE = 'CERTIFICATE',
}

export enum TALENT_EVENT {
    REGISTERED = 'REGISTERED',
    REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED',
    APPROVED = 'APPROVED',
    SKILL_VERIFICATION_STARTED = 'SKILL_VERIFICATION_STARTED',
    SKILL_VERIFICATION_COMPLETED_SUCCESSFULLY = 'SKILL_VERIFICATION_COMPLETED_SUCCESSFULLY',
    SKILL_VERIFICATION_COMPLETED_UNSUCCESSFULLY = 'SKILL_VERIFICATION_COMPLETED_UNSUCCESSFULLY',
    TECHNICAL_INTERVIEW_SCHEDULED = 'TECHNICAL_INTERVIEW_SCHEDULED',
    TECHNICAL_INTERVIEW_COMPLETED_SUCCESSFULLY = 'TECHNICAL_INTERVIEW_COMPLETED_SUCCESSFULLY',
    TECHNICAL_INTERVIEW_COMPLETED_UNSUCCESSFULLY = 'TECHNICAL_INTERVIEW_COMPLETED_UNSUCCESSFULLY',
    FINAL_INTERVIEW_SCHEDULED = 'FINAL_INTERVIEW_SCHEDULED',
    FINAL_INTERVIEW_COMPLETED_SUCCESSFULLY = 'FINAL_INTERVIEW_COMPLETED_SUCCESSFULLY',
    FINAL_INTERVIEW_COMPLETED_UNSUCCESSFULLY = 'FINAL_INTERVIEW_COMPLETED_UNSUCCESSFULLY',
    RECEIVED_INTERVIEW_INVITE = 'RECEIVED_INTERVIEW_INVITE',
    CANCELED_INTERVIEW = 'CANCELED_INTERVIEW',
    OFFERED_INTERVIEW_DATES = 'OFFERED_INTERVIEW_DATES',
    DECLINED_JOB = 'DECLINED_JOB',
    ACCEPTED_JOB = 'ACCEPTED_JOB',
    DEACTIVATED = 'DEACTIVATED',
    ACTIVATED = 'ACTIVATED',
    REJECTED = 'REJECTED',
    DELETED = 'DELETED',
    WAITLISTED = 'WAITLISTED',
}

export enum QUIZ_STATE {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    COMING_SOON = 'COMING_SOON',
}

export enum CATEGORY_STATE {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
}

export enum QUESTION_STATE {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
}

export enum QUESTION_TYPE {
    TALENT_SKILL_CHECK_MULTICHOICE = 'TALENT_SKILL_CHECK_MULTICHOICE',
    TALENT_SKILL_CHECK_MULTICHOICE_CODE_SNIPPET = 'TALENT_SKILL_CHECK_MULTICHOICE_CODE_SNIPPET',
}

export enum QUESTION_DIFFICULTY {
    EASY = 'EASY',
    MEDIUM = 'MEDIUM',
    HARD = 'HARD',
}

export enum JOB_STATE {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    DISABLED = 'DISABLED',
}

export enum USER_VETTING_RECORD_TYPE {
    QUIZ = 'QUIZ',
    TECHNICAL_INTERVIEW = 'TECHNICAL_INTERVIEW',
    FINAL_INTERVIEW = 'FINAL_INTERVIEW',
    HACKERRANK = 'HACKERRANK',
    COMPANY_INTERVIEW = 'COMPANY_INTERVIEW',
}

export enum DEVELOPER_ROLE {
    FRONTEND = 'FRONTEND',
    BACKEND = 'BACKEND',
    FULLSTACK = 'FULLSTACK',
    MOBILE = 'MOBILE',
    DEVOPS = 'DEVOPS',
    DATA_SCIENCE = 'DATA_SCIENCE',
    DATA_ENGINEER = 'DATA_ENGINEER',
    MACHINE_LEARNING = 'MACHINE_LEARNING',
    BLOCKCHAIN_ETH = 'BLOCKCHAIN_ETH',
    QA_ENGINEER = 'QA_ENGINEER',
    PROJECT_MANAGER = 'PROJECT_MANAGER',
    PRODUCT_MANAGER = 'PRODUCT_MANAGER',
    UI_UX_DESIGNER = 'UI_UX_DESIGNER',
    DATA_ANALYST = 'DATA_ANALYST',
}

export enum SENIORITY_LEVEL {
    BELLOW_JUNIOR = 'BELLOW_JUNIOR',
    JUNIOR = 'JUNIOR',
    UPPER_JUNIOR = 'UPPER_JUNIOR',
    LOW_MID = 'LOW_MID',
    MID = 'MID',
    UPPER_MID = 'UPPER_MID',
    LOW_SENIOR = 'LOW_SENIOR',
    SENIOR = 'SENIOR',
    UPPER_SENIOR = 'UPPER_SENIOR',
}

export enum SKILL_EXPERIENCE_LEVEL {
    PERSONAL_PROJECTS = 'PERSONAL_PROJECTS',
    LESS_THAN_2_YEARS_PROFESSIONALLY = 'LESS_THAN_2_YEARS_PROFESSIONALLY',
    MORE_THAN_2_YEARS_PROFESSIONALLY = 'MORE_THAN_2_YEARS_PROFESSIONALLY',
}

export enum TECH_EXPERIENCE {
    SYSTEM_DESIGN = 'SYSTEM_DESIGN',
    ANDROID_DEVELOPMENT = 'ANDROID_DEVELOPMENT',
    LOW_LEVEL_PROGRAMMING = 'LOW_LEVEL_PROGRAMMING',
    SECURITY_SYSTEMS = 'SECURITY_SYSTEMS',
    IOS_DEVELOPMENT = 'IOS_DEVELOPMENT',
    ARCHITECTURE = 'ARCHITECTURE',
    DEEP_LEARNING = 'DEEP_LEARNING',
    PAYMENT_SYSTEM = 'PAYMENT_SYSTEM',
    DATA_PROCESSING = 'DATA_PROCESSING',
    DEVOPS = 'DEVOPS',
    API_DEVELOPMENT = 'API_DEVELOPMENT',
    MICROSERVICES = 'MICROSERVICES',
    MACHINE_LEARNING = 'MACHINE_LEARNING',
    DATA_SCIENCE = 'DATA_SCIENCE',
    ENGINEERING_MANAGEMENT = 'ENGINEERING_MANAGEMENT',
    VIDEO_STREAMING = 'VIDEO_STREAMING',
}

export enum MANAGEMENT_TEAM_SIZE {
    TS_1_4 = '1_4',
    TS_5_10 = '5_10',
    TS_11_20 = '11_20',
    TS_21_50 = '21_50',
    TS_51_100 = '51_100',
    TS_100 = '100',
}

export enum RESTRICTED_TOKEN_TYPE {
    COMPANY_REGISTRATION_AUTO_CONFIRM = 'COMPANY_REGISTRATION_AUTO_CONFIRM',
    COMPANY_CANDIDATE_ACCESS = 'COMPANY_CANDIDATE_ACCESS',
}

export enum ADMIN_USER_TAG {
    NOT_CULTURE_FIT = 'NOT_CULTURE_FIT',
    LACK_OF_COMMUNICATIONS_SKILLS = 'LACK_OF_COMMUNICATIONS_SKILLS',
    LACK_OF_TECHNICAL_SKILLS = 'LACK_OF_TECHNICAL_SKILLS',
    NO_SHOW = 'NO_SHOW',
    NOT_INTERESTED_IN_JOB = 'NOT_INTERESTED_IN_JOB',
    UNAVAILABLE_FOR_NEW_JOB = 'UNAVAILABLE_FOR_NEW_JOB',
    CANDIDATE_LIES = 'CANDIDATE_LIES',
    POOR_ENGLISH = 'POOR_ENGLISH',
    UNPROFESSIONAL = 'UNPROFESSIONAL',
}

export enum ENGLISH_LEVEL {
    A1 = 'A1',
    A2 = 'A2',
    B1 = 'B1',
    B2 = 'B2',
    C1 = 'C1',
    C2 = 'C2',
}

export enum DOMAIN_EXPERIENCE_LEVEL {
    BEGINNER = 'BEGINNER',
    ADVANCED_BEGINNER = 'ADVANCED_BEGINNER',
    COMPETENT = 'COMPETENT',
    ADVANCED = 'ADVANCED',
    EXPERT = 'EXPERT',
}

export enum PRODUCTIVITY_LEVEL {
    VERY_SLOW = 'VERY_SLOW',
    SLOW = 'SLOW',
    INTERMEDIATE = 'INTERMEDIATE',
    FAST = 'FAST',
    VERY_FAST = 'VERY_FAST',
}

export enum PROBLEM_SOLVING_LEVEL {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
    BEGINNER = 'BEGINNER',
    INTERMEDIATE = 'INTERMEDIATE',
    ADVANCED = 'ADVANCED',
    MASTER = 'MASTER',
}

export enum TASK_RESULT_LEVEL {
    VERY_POOR = 'VERY_POOR',
    POOR = 'POOR',
    BASIC = 'BASIC',
    GOOD = 'GOOD',
    EXCEPTIONAL = 'EXCEPTIONAL',
}

export enum INTERVIEW_POSITION_RECOMMENDATION_LEVEL {
    NOT_RECOMMENDED = 'NOT_RECOMMENDED',
    NOT_SURE = 'NOT_SURE',
    WOULD_RECOMMEND = 'WOULD_RECOMMEND',
    HIGHLY_RECOMMEND = 'HIGHLY_RECOMMEND',
}

export enum ADMIN_DATA_TYPE {
    TALENT_SEARCH_FILTER = 'TALENT_SEARCH_FILTER',
}

export const ALL_DEVELOPER_ROLES: DEVELOPER_ROLE[] = Object.keys(DEVELOPER_ROLE) as DEVELOPER_ROLE[];
export const ALL_USER_STATES: USER_STATE[] = Object.keys(USER_STATE) as USER_STATE[];
export const ALL_AVAILABILITIES: AVAILABILITY[] = Object.keys(AVAILABILITY) as AVAILABILITY[];
export const ALL_SENIORITY_LEVELS: SENIORITY_LEVEL[] = Object.keys(SENIORITY_LEVEL) as SENIORITY_LEVEL[];
export const ALL_ONE_TIME_TOKEN_TYPES: RESTRICTED_TOKEN_TYPE[] = Object.keys(RESTRICTED_TOKEN_TYPE) as RESTRICTED_TOKEN_TYPE[];
