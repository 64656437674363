import { AuthProfile } from '../service/AuthService';
import Vuex, { GetterTree, ModuleTree, MutationTree, Store } from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

export interface XStateAuth {
  idToken: string | null;
  profile: AuthProfile | null;
}

export interface XCommonState {
  auth: XStateAuth;
}

export enum COMMON_GETTERS {
  AUTH_DATA = 'AUTH_DATA',
  ID_TOKEN = 'ID_TOKEN',
  AUTH_PROFILE = 'AUTH_PROFILE',
}

export enum COMMON_MUTATIONS {
  LOGIN = 'LOGIN',
}

const getters: GetterTree<XCommonState, XCommonState> = {
  [COMMON_GETTERS.AUTH_DATA]: (state) => state.auth,
  [COMMON_GETTERS.ID_TOKEN]: (state) => state.auth.idToken,
  [COMMON_GETTERS.AUTH_PROFILE]: (state) => state.auth.profile,
};

const mutations: MutationTree<XCommonState> = {
  [COMMON_MUTATIONS.LOGIN]: (state, payload) => {
    state.auth.idToken = payload.idToken;
    state.auth.profile = payload.profile;
  },
};

export let store: Store<XCommonState>;
export default (modules: ModuleTree<XCommonState>): Store<XCommonState> => {
  store = new Store<XCommonState>({
    state: {
      auth: {
        idToken: null,
        profile: null,
      },
    },
    getters,
    mutations,
    modules,
  });

  return store;
};
