






import { Component, Prop } from 'vue-property-decorator';
import { IApiAdminTag, IApiAdminUser } from '@/admin/services/api/AdminService.dtos';
import { IApiAvailableTag } from '@/admin/services/api/AdminUserTagService.dtos';
import { ADMIN_USER_TAG_SERVICE } from '@/admin/services/api/AdminUserTagService';
import { Getter } from 'vuex-class';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { UUID } from '@/shared/data/constants';
import ComponentBase from '@/admin/components/ComponentBase';
import { ADMIN_PERMISSIONS, checkPermission } from '@/admin/services/PermissionService';

@Component({})
export default class AdminTag extends ComponentBase {
    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;
    checkPermission = checkPermission;
    ADMIN_PERMISSIONS = ADMIN_PERMISSIONS;

    @Prop()
    tag!: IApiAdminTag;

    @Prop()
    userId!: UUID;

    availableTags: IApiAvailableTag[] = [];

    get displayName(): string {
        const filtered = this.availableTags.find(t => this.tag.tag === t.name);

        if (filtered) {
            return filtered.displayName;
        }

        return this.tag.tag;
    }

    async mounted() {
        this.availableTags = await ADMIN_USER_TAG_SERVICE.getAvailableTags();
    }

    async removeTag() {
        await this.safeExecuteWithConfirmation(async () => {
                    await ADMIN_USER_TAG_SERVICE.removeTag({
                        tag: this.tag.tag,
                        userId: this.userId,
                    });
                    await this.$emit('tag-removed', this.tag.tag);
                }, 'Removing Tag',
                'Are you sure?',
                'Failed to remove tag! Please check logs/refresh page!',
                'is-warning');
    }
}
