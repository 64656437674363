




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiAdminNote } from '@/admin/services/api/AdminService.dtos';
import { UUID } from '@/shared/data/constants';
import { ADMIN_USER_NOTE_SERVICE } from '@/admin/services/api/AdminUserNoteService';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

@Component({
    components: {
        RichTextEditor,
    }
})
export default class AdminNoteEditor extends Vue {
    @Prop({ default: null })
    existingNote!: IApiAdminNote | null;

    @Prop()
    userId!: UUID;

    buttonLoading = false;

    note: string | null = null;

    mounted() {
        if (this.existingNote) {
            this.note = this.existingNote.note;
        }
    }

    closeModal() {
        this.$emit('close');
    }

    async save() {
        this.buttonLoading = true;

        if (this.existingNote) {
            try {
                await ADMIN_USER_NOTE_SERVICE.updateNote(this.existingNote.id as UUID, {
                    note: this.note as string,
                });
                this.buttonLoading = false;
                this.closeModal();
            } catch (err) {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: `Error while editing admin note! ${err.message}`,
                    type: 'is-danger',
                });
            }
        } else {
            try {
                await ADMIN_USER_NOTE_SERVICE.createNote({
                    userId: this.userId,
                    note: this.note as string,
                });
                this.buttonLoading = false;
                this.closeModal();
            } catch (err) {
                this.$buefy.dialog.alert({
                    title: 'Error',
                    message: `Error while adding admin note! ${err.message}`,
                    type: 'is-danger',
                });
            }
        }
    }
}
