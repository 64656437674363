


























































































































































































































import { Component, Prop } from 'vue-property-decorator';
import { formatDate, formatTime, formatTimezone } from '@/tests/utils/general.utils';
import { ADMIN_CALENDLY_SERVICE } from '@/admin/services/api/AdminCalendlyService';
import { Getter } from 'vuex-class';
import { COMMON_GETTERS } from '@/shared/store';
import { AuthProfile } from '@/shared/service/AuthService';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { CALENDLY_LINK_TYPE, USER_TYPE, USER_VETTING_RECORD_TYPE, UUID } from '@/shared/data/constants';
import { IApiAdminUser, IApiBasicTalentData } from '@/admin/services/api/AdminService.dtos';
import { CALENDLY_EVENT_RESOLUTION } from '@/admin/services/api/AdminCalendlyService.dtos';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { ADMIN_PERMISSIONS, checkPermission } from '@/admin/services/PermissionService';
import { CALENDLY_LINK_TYPE_LABEL } from '@/shared/data/default-labels';
import TechnicalInterview from '@/admin/views/dashboard/views/user-search/TechnicalInterview.vue';
import FinalInterview from '@/admin/views/dashboard/views/user-search/FinalInterview.vue';
import Quiz from '@/admin/views/dashboard/views/user-search/Quiz.vue';
import HackerRank from '@/admin/views/dashboard/views/user-search/HackerRank.vue';
import { S3ClientService } from '@/admin/services/S3ClientService';
import {
    default as SetOrEditTechnicalInterviewResultsV3,
} from '@/admin/views/dashboard/views/modals/technical-interview/v3/SetOrEditTechnicalInterviewResults.vue';
import TalentCard from '@/admin/components/TalentCard.vue';
import ComponentBase from '@/admin/components/ComponentBase';

@Component({
    components: {
        TechnicalInterview,
        FinalInterview,
        Quiz,
        HackerRank,
        TalentCard,
    },
})
export default class Interview extends ComponentBase {
    @Getter(COMMON_GETTERS.AUTH_PROFILE) authProfile!: AuthProfile | null;
    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;

    @Prop()
    eventId!: string;

    data: any | null = null;

    user: IApiBasicTalentData | null = null;
    canSetTechnicalInterviewResults = false;

    selectedResolution: CALENDLY_EVENT_RESOLUTION | null = null;
    resolutionNotes: string | null = null;
    resolutionButtonLoading = false;

    TYPE_MAP = CALENDLY_LINK_TYPE_LABEL;

    file: File | null = null;

    progress: number | null = null;

    closeListenerId: string | null = null;

    CALENDLY_EVENT_RESOLUTION = CALENDLY_EVENT_RESOLUTION;
    VETTING_RECORD_TYPES = USER_VETTING_RECORD_TYPE;
    CALENDLY_EVENT_RESOLUTION_DISPLAY_NAMES = {
        [CALENDLY_EVENT_RESOLUTION.COMPLETED_RECORDED_EVALUATED]: 'Completed, recorded & evaluated',
        [CALENDLY_EVENT_RESOLUTION.COMPLETED_RECORDED]: 'Completed & recorded',
        [CALENDLY_EVENT_RESOLUTION.COMPLETED_EVALUATED]: 'Completed & evaluated',
        [CALENDLY_EVENT_RESOLUTION.NO_SHOW]: 'Candidate did not show up',
        [CALENDLY_EVENT_RESOLUTION.COMPLETED_ISSUES]: 'Completed with issues',
        [CALENDLY_EVENT_RESOLUTION.ISSUES]: 'Did not complete - had issues',
    };

    formatDateSlovenian = formatDate;
    formatTimeSlovenian = formatTime;
    formatTimezoneSlovenian = formatTimezone;

    get myInterview() {
        return this.data.interviewer === this.authProfile?.email;
    }

    get hasInterviewer() {
        return this.data.interviewer !== null;
    }

    get hasZoomLink() {
        return this.data.event.location?.type === 'zoom' && this.data.event.location?.join_url;
    }

    get startDate() {
        return new Date(this.data.event.start_time);
    }

    get endDate() {
        return new Date(this.data.event.end_time);
    }

    get creationDate() {
        return new Date(this.data.event.created_at);
    }

    get interviewTypeLabel(): string {
        return this.data.event.name;
    }

    get reminderEmailText() {
        if (this.hasZoomLink) {
            return `Hi ${this.user ? this.user.properties.TALENT_NAME : this.data.invitee.name},
We are supposed to have a ${this.interviewTypeLabel} at Remotesome.
In case you have trouble finding the link to join the interview,
you can join the interview via the following link:
${this.data.event.location?.join_url}

If you are experiencing technical difficulties please reschedule using this link:
${this.data.invitee.reschedule_url}

If you don't respond, join the interview or reschedule within the next 5 minutes,
I will have to set your interview results as unsuccessful.


Looking forward to meeting you soon,
<Interviewer Name> from Remotesome`;
        }

        return null;
    }

    isVideoUploadRequired(): boolean {
        if (this.selectedResolution !== null &&
            ([CALENDLY_EVENT_RESOLUTION.COMPLETED_RECORDED, CALENDLY_EVENT_RESOLUTION.COMPLETED_RECORDED_EVALUATED] as CALENDLY_EVENT_RESOLUTION[]).includes(this.selectedResolution) &&
            this.data.event.rs_event_type &&
            !([CALENDLY_LINK_TYPE.COMPANY_CALL] as CALENDLY_LINK_TYPE[]).includes(this.data.event.rs_event_type as CALENDLY_LINK_TYPE)) {
            return true;
        }
        return false;
    }

    async mounted() {
        this.closeListenerId = this.addCloseEventListener(async () => await this.closeModal());

        await this.refresh();
    }

    async copyToClipboard(data: string) {
        await navigator.clipboard.writeText(data);
    }

    async refresh() {
        this.data = await ADMIN_CALENDLY_SERVICE.getEvent(this.eventId);
        if (this.data.invitee.rs_user_id && this.data.event.rs_event_type !== CALENDLY_LINK_TYPE.COMPANY_CALL) {
            this.user = (await this.getUser(this.data.invitee.rs_user_id)) as IApiBasicTalentData;

            this.canSetTechnicalInterviewResults = this.user.type === USER_TYPE.talent &&
                checkPermission(this.profile, ADMIN_PERMISSIONS.TALENT_SET_TECHNICAL_INTERVIEW_RESULTS) &&
                this.user.vettingRecords.find(v => v.type === USER_VETTING_RECORD_TYPE.TECHNICAL_INTERVIEW && v.metadata?.type === this.data.event.rs_event_type && v.results === null) !== undefined;
        }
    }

    async claim() {
        try {
            await ADMIN_CALENDLY_SERVICE.claimInterview(this.data.event.uri);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error while claiming event!',
                message: 'Failed to claim event - usually this happens when event is already claimed! Please check logs/refresh page!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        }
    }

    async release() {
        try {
            await ADMIN_CALENDLY_SERVICE.releaseInterview(this.data.event.uri);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error while claiming event!',
                message: 'Failed to release event - usually this happens when event is not claimed by you! Please check logs/refresh page!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        }
    }

    async getUser(userId: UUID): Promise<IApiBasicTalentData> {
        return await ADMIN_SERVICE.getTalentUserData(userId);
    }

    async setResults() {
        try {
            const user = await this.getUser(this.data.invitee.rs_user_id);

            this.removeCloseEventListener(this.closeListenerId);
            this.closeListenerId = null;

            await new Promise<void>((resolve) => {
                this.$buefy.modal.open({
                    component: SetOrEditTechnicalInterviewResultsV3,
                    fullScreen: true,
                    props: {
                        user: user as IApiBasicTalentData,
                        scheduledOn: this.data.event.start_time,
                        eventId: this.data.event.uri,
                        type: this.data.event.rs_event_type as CALENDLY_LINK_TYPE,
                    },
                    parent: this,
                    trapFocus: true,
                    canCancel: false,
                    hasModalCard: true,
                    events: {
                        close: () => {
                            resolve();
                        },
                    },
                });
            });

            this.closeListenerId = this.addCloseEventListener(async () => await this.closeModal());

            this.user = null;
            this.user = await this.getUser(this.data.invitee.rs_user_id);
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error while setting technical interview results!',
                message: 'Failed to set technical interview results - Please check logs/refresh page!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        }
    }

    async setResolution() {
        try {
            this.resolutionButtonLoading = true;

            let videoUrl: string | undefined = undefined;

            if (this.file !== null) {
                const extension = this.file.name.split('.').pop() as string;
                const contentType = S3ClientService.getVideoContentType(extension);
                const data = await ADMIN_SERVICE.getStaticFileUploadCredentials();
                const s3Client = new S3ClientService(data);

                videoUrl = await s3Client.uploadFile(this.file,
                    true,
                    'interview_videos',
                    contentType,
                    undefined,
                    percentage => {
                        this.progress = percentage;
                    });
            }

            await ADMIN_CALENDLY_SERVICE.resolveInterview(
                this.data.event.uri,
                this.selectedResolution as CALENDLY_EVENT_RESOLUTION,
                this.resolutionNotes !== null ? (this.resolutionNotes as string) : undefined,
                videoUrl,
            );
            await this.refresh();
        } catch (err) {
            console.error('Error while setting interview resolution!', err);
            this.$buefy.dialog.alert({
                title: 'Error while resolving event!',
                message: 'Failed to set interview resolution - Please check logs/refresh page!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        } finally {
            this.resolutionButtonLoading = false;
        }
    }

    async closeModal() {
        this.removeCloseEventListener(this.closeListenerId);
        this.closeListenerId = null;

        await this.$emit('close');
    }
}
