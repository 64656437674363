




























































































































import { Component, Vue } from 'vue-property-decorator';
import { QUESTION_STATE, QUESTION_TYPE, UUID } from '@/shared/data/constants';
import CreateNewQuizCategoryQuestionModal from '@/admin/views/dashboard/views/quizzes/modals/CreateNewQuizCategoryQuestionModal.vue';
import { IApiQuiz, IApiQuizCategory, IApiQuizCategoryQuestion } from '@/admin/services/api/AdminQuizService.dtos';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';

@Component({})
export default class QuizCategoryQuestions extends Vue {
    quizId: UUID | null = null;
    quiz: IApiQuiz | null = null;
    categoryId: UUID | null = null;
    category: IApiQuizCategory | null = null;
    questions: IApiQuizCategoryQuestion[] = [];
    ids: UUID[] = [];
    dataLoading = false;

    QUESTION_TYPE = QUESTION_TYPE;
    QUESTION_STATE = QUESTION_STATE;

    async refresh() {
        try {
            this.dataLoading = true;
            this.questions = await ADMIN_QUIZ_SERVICE.getAllQuizCategoryQuestions(this.quizId as string, this.categoryId as string);
            this.ids = this.questions.map((q) => q.id);
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while fetching quiz category questions! ${err.message}`,
                type: 'is-danger',
            });
        } finally {
            this.dataLoading = false;
        }
    }

    async mounted() {
        this.quizId = this.$route.params.quizId;
        this.categoryId = this.$route.params.categoryId;

        this.quiz = await ADMIN_QUIZ_SERVICE.getQuiz(this.quizId);
        this.category = await ADMIN_QUIZ_SERVICE.getQuizCategory(this.quizId, this.categoryId);

        await this.refresh();
    }

    async editQuestion(question: IApiQuizCategoryQuestion) {
        const refresh = await new Promise<boolean>((resolve) => {
            this.$buefy.modal.open({
                component: CreateNewQuizCategoryQuestionModal,
                props: {
                    quiz: this.quiz,
                    category: this.category,
                    question: question,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                fullScreen: true,
                events: {
                    close: (refreshParent: boolean) => {
                        resolve(refreshParent);
                    },
                },
            });
        });

        if (refresh) {
            await this.refresh();
        }
    }

    async addNewQuestion() {
        const refresh = await new Promise<boolean>((resolve) => {
            this.$buefy.modal.open({
                component: CreateNewQuizCategoryQuestionModal,
                props: {
                    quiz: this.quiz,
                    category: this.category,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                fullScreen: true,
                events: {
                    close: (refreshParent: boolean) => {
                        resolve(refreshParent);
                    },
                },
            });
        });

        if (refresh) {
            await this.refresh();
        }
    }

    async activateQuestion(question: IApiQuizCategoryQuestion) {
        try {
            await ADMIN_QUIZ_SERVICE.updateQuizCategoryQuestionState(question, QUESTION_STATE.ACTIVE);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: e.message,
                type: 'is-danger',
            });
        }
    }

    async putQuestionToDraft(question: IApiQuizCategoryQuestion) {
        try {
            await ADMIN_QUIZ_SERVICE.updateQuizCategoryQuestionState(question, QUESTION_STATE.DRAFT);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: e.message,
                type: 'is-danger',
            });
        }
    }
}
