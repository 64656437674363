





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { UUID } from '@/shared/data/constants';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

@Component({
    components: {
        RichTextEditor,
    }
})
export default class UpdateTalentSummary extends Vue {
    @Prop()
    userId!: UUID;

    @Prop()
    currentSummary!: string;

    buttonLoading = false;

    summary: string | null = null;

    mounted() {
        this.summary = this.currentSummary;
    }

    closeModal() {
        this.$emit('close');
    }

    async update() {
        this.buttonLoading = true;
        try {
            await ADMIN_SERVICE.updateTalentSummary(this.userId, this.summary as string);
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while updating summary! ${err.message}`,
                type: 'is-danger',
            });
        }

        this.buttonLoading = false;
        this.closeModal();
    }
}
