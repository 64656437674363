












import { AuthService } from '@/shared/service/AuthService';
import { default as Auth0Lock } from 'auth0-lock';
import uuid from 'uuid';
import { Component, Vue } from 'vue-property-decorator';
import { ADMIN_ACTIONS } from '@/admin/store';
import { AUTH_CALLBACK_URL } from '@/shared/router';
import { localStorageSet } from '@/shared/utils/LocalStorageHelpers';
import {ADMIN_ROUTES} from '@/admin/router';

type LoginHint = 'login' | 'signUp' | 'forgotPassword';

@Component({
    head: {
        title: () => {
            return {
                inner: 'Remotesome.com',
                complement: 'Login',
            };
        },
    },
})
export default class AdminAuth extends Vue {
    public mounted() {
        const clientId = process.env.VUE_APP_AUTH_ADMIN_CLIENT_ID as string;
        const domain = process.env.VUE_APP_AUTH_ADMIN_CLIENT_DOMAIN as string;

        const secret = uuid.v4();
        localStorageSet('secret', secret);

        const loginHint: LoginHint = this.$route.query.login_hint as LoginHint;

        const lock = new Auth0Lock(clientId, domain, {
            container: 'auth0-component',
            closable: false,
            initialScreen: loginHint,
            autofocus: true,
            avatar: null,
            rememberLastLogin: false,
            languageDictionary: {
                emailInputPlaceholder: 'email@example.com',
                passwordInputPlaceholder: 'password',
                title: 'Access your account',
                loginLabel: 'Login',
                signUpTitle: 'Create an account',
                signUpLabel: 'Sign up',
                loginSubmitLabel: 'Login',
            },
            theme: {
                primaryColor: '#168EEA',
                logo: '',
            },
            auth: {
                responseType: 'token id_token',
                redirectUrl: AUTH_CALLBACK_URL,
                params: {
                    scope: 'openid profile email',
                    state: secret,
                },
            },
        });

        lock.on('authenticated', async (authResult) => {
            AuthService.localLogin(authResult);
            try {
                await this.$store.dispatch(ADMIN_ACTIONS.PROCESS_LOGIN, AuthService.authData());
            } catch(error) {
                AuthService.localLogout();
                this.$router.push({name: ADMIN_ROUTES.AUTH});
            }
        });

        lock.show();
    }
}
