var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[(_vm.question)?[_vm._v(" Edit ")]:[_vm._v(" Create New ")],_vm._v(" Quiz Category Question for Quiz "),_c('span',{staticStyle:{"font-style":"italic"}},[_vm._v(_vm._s(_vm.quiz.name))]),_vm._v(" and Category "),_c('span',{staticStyle:{"font-style":"italic"}},[_vm._v(_vm._s(_vm.category.name))])],2)]),_c('section',{staticClass:"modal-card-body"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"content"},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Title","type":{ 'is-danger': errors.length > 0 },"message":{ 'Title is required': errors.length > 0 }}},[_c('b-input',{attrs:{"placeholder":"Enter title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Type","type":{ 'is-danger': errors.length > 0 },"message":{ 'Type is required': errors.length > 0 }}},[_c('b-select',{attrs:{"size":"is-default","placeholder":"Select type","expanded":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('option',{domProps:{"value":_vm.QUESTION_TYPE.TALENT_SKILL_CHECK_MULTICHOICE}},[_vm._v("Multichoice Question")]),_c('option',{domProps:{"value":_vm.QUESTION_TYPE.TALENT_SKILL_CHECK_MULTICHOICE_CODE_SNIPPET}},[_vm._v("Multichoice Question With Code Snippet")])])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Difficulty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Difficulty","type":{ 'is-danger': errors.length > 0 },"message":{ 'Difficulty is required': errors.length > 0 }}},[_c('b-select',{attrs:{"size":"is-default","placeholder":"Select difficulty","expanded":""},on:{"input":_vm.setDefaults},model:{value:(_vm.difficulty),callback:function ($$v) {_vm.difficulty=$$v},expression:"difficulty"}},_vm._l((_vm.allQuestionDifficulties),function(d){return _c('option',{key:d.name,domProps:{"value":d.name}},[_vm._v(_vm._s(d.displayName))])}),0)],1)]}}],null,true)}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"name":"AvailableTimeSeconds","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Available Time in Seconds","type":{ 'is-danger': errors.length > 0 },"message":{ 'Available Time in Seconds is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","step":"1","min":"1"},model:{value:(_vm.availableTimeSeconds),callback:function ($$v) {_vm.availableTimeSeconds=$$v},expression:"availableTimeSeconds"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"name":"CorrectPoints","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Correct Points","type":{ 'is-danger': errors.length > 0 },"message":{ 'Correct Points is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","step":"0.01","min":"0"},model:{value:(_vm.correctPoints),callback:function ($$v) {_vm.correctPoints=$$v},expression:"correctPoints"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"name":"InCorrectPoints","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Incorrect Points","type":{ 'is-danger': errors.length > 0 },"message":{ 'Incorrect Points is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","step":"0.01","min":"0"},model:{value:(_vm.incorrectPoints),callback:function ($$v) {_vm.incorrectPoints=$$v},expression:"incorrectPoints"}})],1)]}}],null,true)})],1)]),(_vm.type === _vm.QUESTION_TYPE.TALENT_SKILL_CHECK_MULTICHOICE_CODE_SNIPPET)?[_c('validation-provider',{attrs:{"name":"CodeSnippetImageURL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Code Snippet Image URL","type":{ 'is-danger': errors.length > 0 },"message":{ 'Code Snippet Image URL is required': errors.length > 0 }}},[_c('b-input',{attrs:{"placeholder":"Enter Code Snippet Image URL"},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}})],1)]}}],null,true)})]:_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"name":"Choice1","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Answer Choice 1","type":{ 'is-danger': errors.length > 0 },"message":{ 'Answer Choice 1 is required': errors.length > 0 }}},[_c('rich-text-editor',{attrs:{"show-menu":"true","resizable":"true"},model:{value:(_vm.choice1),callback:function ($$v) {_vm.choice1=$$v},expression:"choice1"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"name":"Choice2","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Answer Choice 2","type":{ 'is-danger': errors.length > 0 },"message":{ 'Answer Choice 2 is required': errors.length > 0 }}},[_c('rich-text-editor',{attrs:{"show-menu":"true","resizable":"true"},model:{value:(_vm.choice2),callback:function ($$v) {_vm.choice2=$$v},expression:"choice2"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"name":"Choice3","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Answer Choice 3","type":{ 'is-danger': errors.length > 0 },"message":{ 'Answer Choice 3 is required': errors.length > 0 }}},[_c('rich-text-editor',{attrs:{"show-menu":"true","resizable":"true"},model:{value:(_vm.choice3),callback:function ($$v) {_vm.choice3=$$v},expression:"choice3"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"name":"Choice4","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Answer Choice 4","type":{ 'is-danger': errors.length > 0 },"message":{ 'Answer Choice 4 is required': errors.length > 0 }}},[_c('rich-text-editor',{attrs:{"show-menu":"true","resizable":"true"},model:{value:(_vm.choice4),callback:function ($$v) {_vm.choice4=$$v},expression:"choice4"}})],1)]}}],null,true)})],1)]),_c('validation-provider',{attrs:{"name":"Correct Answer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Correct Answer","type":{ 'is-danger': errors.length > 0 },"message":{ 'Correct Answer is required': errors.length > 0 }}},[_c('b-select',{attrs:{"size":"is-default","placeholder":"Select which choice is correct","expanded":""},model:{value:(_vm.correctAnswerIndex),callback:function ($$v) {_vm.correctAnswerIndex=$$v},expression:"correctAnswerIndex"}},[_c('option',{domProps:{"value":0}},[_vm._v("Answer Choice 1")]),_c('option',{domProps:{"value":1}},[_vm._v("Answer Choice 2")]),_c('option',{domProps:{"value":2}},[_vm._v("Answer Choice 3")]),_c('option',{domProps:{"value":3}},[_vm._v("Answer Choice 4")])])],1)]}}],null,true)}),_c('div',{staticClass:"has-text-centered"},[_c('b-button',{staticStyle:{"margin":"10px 10px 10px 10px"},attrs:{"type":"is-warning"},on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v("Cancel ")]),_c('b-button',{staticStyle:{"margin":"10px 10px 10px 10px"},attrs:{"native-type":"submit","type":"is-success","loading":_vm.buttonLoading}},[_vm._v(" Save ")])],1)],2)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }