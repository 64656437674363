




































































































import { Component, Prop } from 'vue-property-decorator';
import ComponentBase from '@/admin/components/ComponentBase';
import { IEditTask } from '@/admin/views/dashboard/views/modals/technical-interview/v3/types';
import {
    CodetableResult,
    Codetables,
    CodetableService,
    IInterviewTaskStage,
    InterviewTaskDefinition,
    TaskResultLevel,
} from '@/shared/service/CodetableService';
import { ArrayHelpers } from '@/shared/utils/ArrayHelpers';
import { required } from 'vuelidate/lib/validators';
import { DEVELOPER_ROLE } from '@/shared/data/constants';

@Component({})
export default class InterviewTask extends ComponentBase {
    @Prop()
    task!: IEditTask;

    @Prop()
    role!: DEVELOPER_ROLE;

    @Prop()
    allSortedTasks!: IEditTask[];

    allInterviewTaskDefinitions: InterviewTaskDefinition[] = [];

    get taskStages(): IInterviewTaskStage[] {
        const task = this.taskDefinition;

        if (task?.properties) {
            return task.properties.stages.sort((a, b) => a.sequence - b.sequence);
        }

        return [];
    }

    get availableTaskDefinitions(): InterviewTaskDefinition[] {
        return this.allInterviewTaskDefinitions.filter(t => t.role === (this.role as string));
    }

    get taskDefinition(): InterviewTaskDefinition | undefined {
        if (this.task.task && this.allInterviewTaskDefinitions.length > 0) {
            return ArrayHelpers.single(this.allInterviewTaskDefinitions, td => td.name === this.task.task);
        }

        return undefined;
    }

    get taskResultLevel(): TaskResultLevel | undefined {
        if (this.task.taskResult && this.codetables) {
            return ArrayHelpers.single(this.codetables.task_result_levels as TaskResultLevel[], l => l.name === this.task.taskResult);
        }

        return undefined;
    }

    validations() {
        return {
            task: {
                task: {
                    required,
                },
                taskResult: {
                    required,
                },
            },
        };
    }

    codetables: CodetableResult | null = null;

    async beforeCreate() {
        this.codetables = await CodetableService.getCodetables(
            Codetables.INTERVIEW_TASKS,
            Codetables.TASK_RESULT_LEVELS,
        );

        this.allInterviewTaskDefinitions = this.codetables.interview_tasks as InterviewTaskDefinition[];
    }
}
