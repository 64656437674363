














import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiUserVettingRecord } from '@/admin/services/api/AdminService.dtos';

@Component({})
export default class HackerRank extends Vue {
    @Prop()
    readonly record!: IApiUserVettingRecord;

    get passedLabel(): string {
        if (this.record.success === true) {
            return 'Passed';
        } else {
            return 'Failed';
        }
    }
}
