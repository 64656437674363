






































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { AuthProfile, AuthService } from '@/shared/service/AuthService';
import { COMMON_GETTERS } from '@/shared/store';
import { ADMIN_ROUTES } from '@/admin/router';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { IApiAdminUser } from '@/admin/services/api/AdminService.dtos';
import { ADMIN_PERMISSIONS, checkPermission } from '@/admin/services/PermissionService';
import AdminAccountModal from '@/admin/components/AdminAccountModal.vue';

@Component({})
export default class Navbar extends Vue {
    @Getter(COMMON_GETTERS.AUTH_PROFILE) authProfile!: AuthProfile | null;
    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;

    ADMIN_ROUTES = ADMIN_ROUTES;
    ADMIN_PERMISSIONS = ADMIN_PERMISSIONS;
    checkPermission = checkPermission;

    async showAdminAccountModal() {
        this.$buefy.modal.open({
            component: AdminAccountModal,
            props: {
                profile: this.profile,
            },
            hasModalCard: true,
            parent: this,
            trapFocus: true,
        });
    }

    async logout() {
        const clientId = process.env.VUE_APP_AUTH_ADMIN_CLIENT_ID as string;
        const domain = process.env.VUE_APP_AUTH_ADMIN_CLIENT_DOMAIN as string;

        const authService = new AuthService(clientId, domain);
        authService.logout();
    }
}
