













































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { QUIZ_STATE } from '@/shared/data/constants';
import { IApiQuiz } from '@/admin/services/api/AdminQuizService.dtos';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';
import { Codetables, CodetableService, DeveloperRole, Skill } from '@/shared/service/CodetableService';
import { ArrayHelpers } from '@/shared/utils/ArrayHelpers';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

@Component({
    components: {
        RichTextEditor,
    }
})
export default class CreateNewQuizModal extends Vue {
    buttonLoading = false;

    @Prop({
        required: false,
        default: () => null,
    })
    readonly quiz!: IApiQuiz | null;

    imageUrl: string | null = null;
    name: string | null = null;
    shortDescription: string | null = null;
    longDescription: string | null = null;
    sortOrder: number | null = null;
    developerRoles: string[] = [];
    skills: string[] = [];
    passPercentage = 60;
    certificatePercentage = 80;
    skipInterviewPercentage = 95;

    filteredRoles: string[] = [];
    filteredSkills: string[] = [];

    allRoles: DeveloperRole[] = [];
    allSkills: string[] = [];

    async mounted() {
        const codetables = await CodetableService.getCodetables(Codetables.DEVELOPER_ROLES, Codetables.SKILLS);
        this.allRoles = codetables.developer_roles as DeveloperRole[];
        this.allSkills = (codetables.skills as Skill[]).map(s => s.name);

        if (this.quiz) {
            this.imageUrl = this.quiz.imageUrl;
            this.name = this.quiz.name;
            this.shortDescription = this.quiz.shortDescription;
            this.longDescription = this.quiz.longDescription;
            this.sortOrder = this.quiz.sortOrder;
            this.passPercentage = this.quiz.passPercentage;
            this.certificatePercentage = this.quiz.certificatePercentage;
            this.skipInterviewPercentage = this.quiz.skipInterviewPercentage;
            this.developerRoles = this.quiz.roles.map(r => ArrayHelpers.single(this.allRoles, dr => dr.name == r).displayName);
            this.skills = this.quiz.skills;
        }
    }

    closeModal(refresh: boolean) {
        this.$emit('close', refresh);
    }

    developerRolesFilter(text: string) {
        this.filteredRoles = this.allRoles.filter((role) => {
            return role.displayName.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        }).map(role => role.displayName);
    }

    skillsFilter(text: string) {
        this.filteredSkills = this.allSkills.filter((skill) => {
            return skill.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });
    }

    async save() {
        this.buttonLoading = true;
        try {
            if (this.quiz) {
                await ADMIN_QUIZ_SERVICE.updateQuiz(this.quiz.id, {
                    imageUrl: this.imageUrl as string,
                    name: this.name as string,
                    roles: this.developerRoles.map(r => ArrayHelpers.single(this.allRoles, dr => dr.displayName == r).name),
                    skills: this.skills,
                    shortDescription: this.shortDescription as string,
                    longDescription: this.longDescription as string,
                    sortOrder: this.sortOrder as number,
                    passPercentage: this.passPercentage as number,
                    certificatePercentage: this.certificatePercentage as number,
                    skipInterviewPercentage: this.skipInterviewPercentage as number,
                    state: this.quiz.state,
                });
            } else {
                await ADMIN_QUIZ_SERVICE.createQuiz({
                    imageUrl: this.imageUrl as string,
                    name: this.name as string,
                    roles: this.developerRoles.map(r => ArrayHelpers.single(this.allRoles, dr => dr.displayName == r).name),
                    skills: this.skills,
                    shortDescription: this.shortDescription as string,
                    longDescription: this.longDescription as string,
                    sortOrder: this.sortOrder as number,
                    passPercentage: this.passPercentage as number,
                    certificatePercentage: this.certificatePercentage as number,
                    skipInterviewPercentage: this.skipInterviewPercentage as number,
                    state: QUIZ_STATE.DRAFT,
                });
            }

            this.closeModal(true);
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while creating quiz! ${e.message}`,
                type: 'is-danger',
            });
        }
        this.buttonLoading = false;
    }
}
