import { DEVELOPER_ROLE, JOB_STATE, UUID } from '@/shared/data/constants';

export interface IApiJob {
    id: UUID;
    data: IApiJobData;
    dataVersion: number;
    state: JOB_STATE;
    approved: boolean;
    featured: boolean;
    sortOrder: number;
    candidates: number;
    createdAt: string;
    company: IJobCOmpanyData;
    companyUserId?: UUID | null;
}

export interface IApiJobSectionData {
    order: number;
    name: string;
    htmlText: string;
}

export interface IApiJobSkill {
    name: string;
    level: string;
}

export enum API_JOB_STATE_FILTER {
    ACTIVE_ONLY = 'ACTIVE_ONLY',
    DISABLED_ONLY = 'DISABLED_ONLY',
    CLOSED_ONLY = 'CLOSED_ONLY',
    DRAFT_ONLY = 'DRAFT_ONLY',
    FEATURED_ONLY = 'FEATURED_ONLY'
}

export interface IApiJobCriteria {
    companyId?: UUID | null;
    companyUserId?: UUID | null;
    companyName?: string | null;
    stateFilter?: API_JOB_STATE_FILTER | null;
    page: number;
    perPage: number;
}

export interface IApiJobData {
    positionTitle: string;
    skills: IApiJobSkill[];
    role?: DEVELOPER_ROLE | null;
    tech: string[];
    nrEngineers?: number | null;
    quantity: number;
    countries?: string[];
    salaryCurrency?: string | null;
    salaryFrom: number | null;
    salaryTo: number | null;
    salaryText: string | null;
    salaryAsText: boolean | null;
    salaryEquity: boolean;
    salaryEquityUnknown: boolean;
    salaryEquityFrom?: number | null;
    salaryEquityTo?: number | null;
    timezoneFrom: string | null;
    timezoneTo: string | null;
    worldwide: boolean | null;
    textSections: IApiJobSectionData[];
    conditionsHtml: string | null;
    goodToHave: string | null;
    benefits: string[];
    needsOptimizations: boolean;
    contractType: string;
}

export interface IJobCOmpanyData {
    userId: UUID;
    data: ICompanyData;
    createdAt: string;
    jobs: number;
    applicants: number;
}

export interface ICompanyData {
    logoUrl: string;
    name: string;
    summaryHtml: string;
    headquarters: string;
    headcount: string;
    techStack: string[];
}

export interface IApiJobModifyRequest {
    data: IApiJobData;
    dataVersion: number;
    state: JOB_STATE;
    featured: boolean;
    sortOrder: number;
    companyUserId: string;
}

export type CompanyLinkType = 'linkedin' | 'website' | 'facebook' | 'twitter' | 'other';

export interface ICompanyLink {
    type: CompanyLinkType;
    link: string;
}

export type CompanyOperationStyle = 'same' | 'overlap' | 'async';
export type SeniorityLevel = 'intern' | 'junior' | 'mid' | 'senior' | 'expert';
export type ManagerialRoleType = 'yes' | 'no' | 'hybrid';
