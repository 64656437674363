
































































































































































































































































import {Component, Prop} from 'vue-property-decorator';
import {IApiAdminUser, IApiBasicTalentData, IApiUserVettingRecord} from '@/admin/services/api/AdminService.dtos';
import { AVAILABILITY, SENIORITY_LEVEL, TECH_EXPERIENCE, USER_STATE, USER_VETTING_RECORD_TYPE, UUID } from '@/shared/data/constants';
import { AVAILABILITY_LABEL, DEVELOPER_ROLE_LABEL, INTEREST_STATUS_LABEL, MANAGEMENT_TEAM_SIZE_LABEL, SENIORITY_LEVEL_LABEL, SKILL_EXPERIENCE_LEVEL_LABEL, TECH_EXPERIENCE_LABEL, USER_STATE_LABEL } from '@/shared/data/default-labels';
import Quiz from '@/admin/views/dashboard/views/user-search/Quiz.vue';
import TechnicalInterview from '@/admin/views/dashboard/views/user-search/TechnicalInterview.vue';
import FinalInterview from '@/admin/views/dashboard/views/user-search/FinalInterview.vue';
import SocialLink from '@/admin/views/dashboard/views/user-search/SocialLink.vue';
import CustomerIOEvent from '@/admin/views/dashboard/views/user-search/CustomerIOEvent.vue';
import {ADMIN_PERMISSIONS, checkPermission} from '@/admin/services/PermissionService';
import {Getter} from 'vuex-class';
import {ADMIN_GETTERS, adminNamespace} from '@/admin/store';
import JobApplication from '@/admin/views/dashboard/views/user-search/JobApplication.vue';
import {ADMIN_SERVICE} from '@/admin/services/api/AdminService';
import AdminTag from '@/admin/views/dashboard/views/user-search/AdminTag.vue';
import ComponentBase from '@/admin/components/ComponentBase';
import AddAdminTag from '@/admin/views/dashboard/views/modals/AddAdminTag.vue';
import AdminNote from '@/admin/views/dashboard/views/user-search/AdminNote.vue';
import AdminNoteEditor from '@/admin/views/dashboard/views/modals/AdminNoteEditor.vue';
import UpdateTalentSummary from '@/admin/views/dashboard/views/modals/UpdateTalentSummary.vue';
import SendTalentAvailabilityCustomerIOEvent from '@/admin/views/dashboard/views/modals/SendTalentAvailabilityCustomerIOEvent.vue';
import { ISelectableItem } from '@/shared/data/types';
import { formatDateTime } from '@/tests/utils/general.utils';
import UserTalentEdit from '@/admin/views/dashboard/views/user-search/UserTalentEdit.vue';
import TalentSummary from '@/admin/components/TalentSummary.vue';

@Component({
    components: {
        AdminNote,
        AdminTag,
        Quiz,
        TechnicalInterview,
        FinalInterview,
        SocialLink,
        CustomerIOEvent,
        JobApplication,
        TalentSummary,
    },
})
export default class TalentCard extends ComponentBase {
    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;

    @Prop({})
    talentData!: ISelectableItem<IApiBasicTalentData>;

    @Prop({default: null})
    jobId!: UUID | null;

    @Prop({default: true})
    showCheckbox!: boolean;

    data: ISelectableItem<IApiBasicTalentData> | null = null;

    async mounted() {
        this.data = this.talentData;
    }

    SKILL_EXPERIENCE_LEVEL_LABEL = SKILL_EXPERIENCE_LEVEL_LABEL;
    DEVELOPER_ROLE_LABEL = DEVELOPER_ROLE_LABEL;
    INTEREST_STATUS_LABEL = INTEREST_STATUS_LABEL;
    AVAILABILITY = AVAILABILITY;
    AVAILABILITY_LABEL = AVAILABILITY_LABEL;
    MANAGEMENT_TEAM_SIZE_LABEL = MANAGEMENT_TEAM_SIZE_LABEL;

    checkPermission = checkPermission;
    ADMIN_PERMISSIONS = ADMIN_PERMISSIONS;

    activeTab = null;

    get hasActions(): boolean {
        return this.canDelete ||
                this.canSendCustomerIOEvent ||
                this.canEditAdminNotes;
    }

    get talent(): IApiBasicTalentData {
        if (this.data) {
            return this.data.item;
        }

        throw new Error('Something went wrong here!');
    }

    get jobApplicationDate(): string | null {
        if (this.jobId) {
            const application = this.talent.appliedPositions.find(ap => ap.jobId === this.jobId);
            if (application) {
                return formatDateTime(new Date(application.appliedAt));
            }
        }

        return null;
    }

    get notesAndTagsLabel(): string {
        return `Notes (${this.talent.adminNotes.length}) & Tags (${this.talent.adminTags.length})`;
    }

    get canDelete(): boolean {
        return this.talent.state !== USER_STATE.DELETED && checkPermission(this.profile, ADMIN_PERMISSIONS.TALENT_DELETE);
    }

    get canEdit(): boolean {
        return checkPermission(this.profile, ADMIN_PERMISSIONS.TALENT_EDIT);
    }

    get canSendCustomerIOEvent(): boolean {
        return checkPermission(this.profile, ADMIN_PERMISSIONS.SEND_CUSTOMER_IO_EVENTS);
    }

    get canEditAdminNotes(): boolean {
        return checkPermission(this.profile, ADMIN_PERMISSIONS.USER_NOTE_EDIT);
    }

    get passedQuiz(): boolean {
        return this.talent.vettingRecords.find(r => r.type === USER_VETTING_RECORD_TYPE.QUIZ && r.success === true) !== undefined;
    }

    get passedTechnicalInterview(): boolean {
        return this.talent.vettingRecords.find(r => r.type === USER_VETTING_RECORD_TYPE.TECHNICAL_INTERVIEW && r.success === true) !== undefined;
    }

    get passedFinalInterview(): boolean {
        return this.talent.vettingRecords.find(r => r.type === USER_VETTING_RECORD_TYPE.FINAL_INTERVIEW && r.success === true) !== undefined;
    }

    get hasQuizzes(): boolean {
        return this.talent.vettingRecords.find(r => r.type === USER_VETTING_RECORD_TYPE.QUIZ) !== undefined;
    }

    get quizRecords(): IApiUserVettingRecord[] {
        return this.talent.vettingRecords.filter(r => r.type === USER_VETTING_RECORD_TYPE.QUIZ);
    }

    get hasTechnicalInterviews(): boolean {
        return this.talent.vettingRecords.find(r => r.type === USER_VETTING_RECORD_TYPE.TECHNICAL_INTERVIEW) !== undefined;
    }

    get technicalInterviewRecords(): IApiUserVettingRecord[] {
        return this.talent.vettingRecords.filter(r => r.type === USER_VETTING_RECORD_TYPE.TECHNICAL_INTERVIEW);
    }

    get hasFinalInterviews(): boolean {
        return this.talent.vettingRecords.find(r => r.type === USER_VETTING_RECORD_TYPE.FINAL_INTERVIEW) !== undefined;
    }

    get finalInterviewRecords(): IApiUserVettingRecord[] {
        return this.talent.vettingRecords.filter(r => r.type === USER_VETTING_RECORD_TYPE.FINAL_INTERVIEW);
    }

    get hasCustomerIoHistory(): boolean {
        return this.talent.customerIoHistory.length > 0 && this.canSendCustomerIOEvent;
    }

    get latestSeniorityLevel(): string | null {
        const sorted = this.talent.vettingRecords.filter(r => r.type === USER_VETTING_RECORD_TYPE.TECHNICAL_INTERVIEW && r.results !== null)
                .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

        if (sorted.length > 0) {
            const first = sorted[0];

            return SENIORITY_LEVEL_LABEL[first.results.seniorityLevel as SENIORITY_LEVEL];
        }

        return null;
    }

    get hasJobApplications(): boolean {
        return this.talent.appliedPositions.length > 0 && checkPermission(this.profile, ADMIN_PERMISSIONS.JOB_EDITOR);
    }

    USER_STATE = USER_STATE;
    USER_STATE_LABEL = USER_STATE_LABEL;

    techExperienceLabel(exp: string) {
        if (TECH_EXPERIENCE_LABEL[exp as TECH_EXPERIENCE]) {
            return TECH_EXPERIENCE_LABEL[exp as TECH_EXPERIENCE];
        }

        return exp;
    }

    async editTalent() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: UserTalentEdit,
                props: {
                    user: this.talent,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                fullScreen: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });

        await this.refreshUser();
    }

    async deleteUser() {
        await this.safeExecuteWithConfirmation(async () => {
                    await ADMIN_SERVICE.deleteTalent(this.talent.userId);
                    await this.$emit('deleted');
                }, 'Deleting User',
                `Are you sure you want to <b>delete</b> talent user ${this.talent.properties.TALENT_NAME} ${this.talent.properties.TALENT_LAST_NAME}?`,
                'Failed to delete user! Please check logs/refresh page!',
                'is-danger');
    }

    async refreshUser() {
        const selected = this.data?.selected;
        const item = await ADMIN_SERVICE.getTalentUserData(this.talent.userId);

        this.data = {
            item,
            selected: selected ?? false,
        };
    }

    async addNewTag() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: AddAdminTag,
                props: {
                    user: this.talent,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });

        await this.refreshUser();
    }

    async addNewNote() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: AdminNoteEditor,
                props: {
                    userId: this.talent.userId,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });

        await this.refreshUser();
    }

    async editSummary() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: UpdateTalentSummary,
                props: {
                    userId: this.talent.userId,
                    currentSummary: this.talent.summary,
                },
                hasModalCard: true,
                parent: this,
                canCancel: false,
                trapFocus: true,
                fullScreen: true,
                events: {
                    close: () => {
                        resolve()
                    },
                },
            });
        });

        await this.refreshUser();
    }

    async sendCustomerIoEventModal() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: SendTalentAvailabilityCustomerIOEvent,
                props: {
                    users: [{
                        userId: this.talent.userId,
                        name: this.talent.properties.TALENT_NAME,
                        surname: this.talent.properties.TALENT_LAST_NAME,
                    }],
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });

        await this.refreshUser();
    }
}
