


































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';

@Component({
    components: {
        EditorContent,
    },
})
export default class RichTextEditor extends Vue {
    @Prop({ required: true }) value!: string;
    @Prop({
        required: false,
        default: '',
    }) placeholder!: string;
    @Prop({ required: false }) minHeight?: string;
    editor: Editor | null = null;

    @Prop({ required: false, default: false })
    showMenu!: boolean;

    @Prop({ required: false, default: false })
    resizable!: boolean;

    valueProxy = '';

    @Watch('value')
    onValueChange(val: string) {
        if (val === this.valueProxy) {
            return;
        }
        if (this.editor) {
            this.editor.commands.setContent(val, false);
        }
    }

    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    placeholder: this.placeholder,
                }),
                Underline.configure({}),
            ],
            onUpdate: () => {
                if (this.editor) {
                    this.valueProxy = this.editor.getHTML();
                    this.$emit('input', this.valueProxy);
                }
            },
            onFocus: () => {
                this.$emit('focus');
            },
            onBlur: () => {
                this.$emit('blur');
            },
        });
    }

    beforeDestroy() {
        if (this.editor) {
            this.editor.destroy();
        }
    }
}
