


























































































































import { Component, Prop } from 'vue-property-decorator';
import { IApiJob, IApiJobSectionData } from '@/admin/services/api/AdminJobService.dtos';
import { ADMIN_ROUTES } from '@/admin/router';
import ComponentBase from '@/admin/components/ComponentBase';
import { ADMIN_JOB_SERVICE } from '@/admin/services/api/AdminJobService';
import { JOB_STATE } from '@/shared/data/constants';

@Component({})
export default class Job extends ComponentBase {
    @Prop()
    readonly job!: IApiJob;

    JOB_STATE = JOB_STATE;
    ADMIN_ROUTES = ADMIN_ROUTES;

    get previewLink(): string {
        return `${process.env.VUE_APP_TALENT_URL as string}/jobs/${this.job.id}`;
    }

    get orderedSections(): IApiJobSectionData[] {
        if (this.job && this.job.data.textSections) {
            return this.job.data.textSections.sort((a, b) => a.order - b.order);
        }

        return [];
    }

    get countries(): string {
        if (this.job && this.job.data.countries) {
            return this.job.data.countries.join(', ');
        }

        return '';
    }

    async approveJob() {
        await this.safeExecuteWithConfirmation(async () => {
                await ADMIN_JOB_SERVICE.approveJob(this.job.id);
                await this.$emit('refresh');
            }, 'Approve Job Listing',
            'Are you sure you want to <b>approve</b> this job listing?',
            'Failed to approve job listing! Please check logs/refresh page!',
            'is-warning');
    }
}
