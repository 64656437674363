import {
    AVAILABILITY,
    CALENDLY_LINK_TYPE,
    DEVELOPER_ROLE,
    INTEREST_STATUS,
    JOB_STATE,
    MANAGEMENT_TEAM_SIZE,
    SENIORITY_LEVEL,
    SKILL_EXPERIENCE_LEVEL,
    TECH_EXPERIENCE,
    USER_STATE,
} from '@/shared/data/constants';

export const USER_STATE_LABEL = {
    [USER_STATE.ACTIVE]: 'Active',
    [USER_STATE.DELETED]: 'Deleted',
    [USER_STATE.TEMP]: 'Temporary',
    [USER_STATE.PENDING_DEMO]: 'Pending Demo',
    [USER_STATE.IN_APPROVAL]: 'Pending Approval',
};

export const AVAILABILITY_LABEL = {
    [AVAILABILITY.NOT_AVAILABLE]: 'Not available',
    [AVAILABILITY.OPEN]: 'Open to opportunities',
    [AVAILABILITY.ACTIVELY_LOOKING]: 'Actively looking for a job',
};

export const INTEREST_STATUS_LABEL = {
    [INTEREST_STATUS.PART_TIME]: 'Part Time',
    [INTEREST_STATUS.FULL_TIME]: 'Full Time',
    [INTEREST_STATUS.BOTH]: 'Part & Full Time',
};

export const CALENDLY_LINK_TYPE_LABEL = {
    [CALENDLY_LINK_TYPE.COMPANY_CALL]: 'Company Demo',
    [CALENDLY_LINK_TYPE.FINAL_INTERVIEW]: 'Final Interview',
    [CALENDLY_LINK_TYPE.TECHNICAL_INTERVIEW]: 'Technical Interview',
    [CALENDLY_LINK_TYPE.BACKEND_TECHNICAL_INTERVIEW]: 'Backend Technical Interview',
    [CALENDLY_LINK_TYPE.FRONTEND_TECHNICAL_INTERVIEW]: 'Frontend Technical Interview',
    [CALENDLY_LINK_TYPE.DATA_ENGINEER_TECHNICAL_INTERVIEW]: 'Data Engineer Technical Interview',
    [CALENDLY_LINK_TYPE.BLOCKCHAIN_ETH_ENGINEER_TECHNICAL_INTERVIEW]: 'Solidity Developer Technical Interview',
    [CALENDLY_LINK_TYPE.FLUTTER_TECHNICAL_INTERVIEW]: 'Flutter Engineer Technical Interview',
    [CALENDLY_LINK_TYPE.DEVOPS_KUBERNETES_TECHNICAL_INTERVIEW]: 'DevOps (Kubernetes) Engineer Technical Interview',
};

export const DEVELOPER_ROLE_LABEL = {
    [DEVELOPER_ROLE.BACKEND]: 'Backend',
    [DEVELOPER_ROLE.FRONTEND]: 'Frontend',
    [DEVELOPER_ROLE.FULLSTACK]: 'Fullstack',
    [DEVELOPER_ROLE.DEVOPS]: 'DevOps',
    [DEVELOPER_ROLE.MOBILE]: 'Mobile',
    [DEVELOPER_ROLE.DATA_ENGINEER]: 'Data Engineer',
    [DEVELOPER_ROLE.DATA_SCIENCE]: 'Data Science',
    [DEVELOPER_ROLE.MACHINE_LEARNING]: 'Machine Learning',
    [DEVELOPER_ROLE.BLOCKCHAIN_ETH]: 'Blockchain',
    [DEVELOPER_ROLE.QA_ENGINEER]: 'QA engineer',
    [DEVELOPER_ROLE.PROJECT_MANAGER]: 'Project Manager',
    [DEVELOPER_ROLE.PRODUCT_MANAGER]: 'Product manager',
    [DEVELOPER_ROLE.UI_UX_DESIGNER]: 'UI/UX Designer',
    [DEVELOPER_ROLE.DATA_ANALYST]: 'Data analyst',
};

export const SENIORITY_LEVEL_LABEL = {
    [SENIORITY_LEVEL.BELLOW_JUNIOR]: 'Bellow Junior',
    [SENIORITY_LEVEL.JUNIOR]: 'Junior',
    [SENIORITY_LEVEL.UPPER_JUNIOR]: 'High Level Junior',
    [SENIORITY_LEVEL.LOW_MID]: 'Low Level Mid',
    [SENIORITY_LEVEL.MID]: 'Mid Level',
    [SENIORITY_LEVEL.UPPER_MID]: 'High Level Mid',
    [SENIORITY_LEVEL.LOW_SENIOR]: 'Low Level Senior',
    [SENIORITY_LEVEL.SENIOR]: 'Senior',
    [SENIORITY_LEVEL.UPPER_SENIOR]: 'High Level Senior',
};

export const SKILL_EXPERIENCE_LEVEL_LABEL = {
    [SKILL_EXPERIENCE_LEVEL.PERSONAL_PROJECTS]: 'Personal Projects',
    [SKILL_EXPERIENCE_LEVEL.LESS_THAN_2_YEARS_PROFESSIONALLY]: '< 2 Years professionally',
    [SKILL_EXPERIENCE_LEVEL.MORE_THAN_2_YEARS_PROFESSIONALLY]: '> 2 Years professionally',
};

export const TECH_EXPERIENCE_LABEL = {
    [TECH_EXPERIENCE.SYSTEM_DESIGN]: 'System Design',
    [TECH_EXPERIENCE.ANDROID_DEVELOPMENT]: 'Android Development',
    [TECH_EXPERIENCE.LOW_LEVEL_PROGRAMMING]: 'Low Level Programming',
    [TECH_EXPERIENCE.SECURITY_SYSTEMS]: 'Security Systems',
    [TECH_EXPERIENCE.IOS_DEVELOPMENT]: 'iOS Development',
    [TECH_EXPERIENCE.ARCHITECTURE]: 'Architecture',
    [TECH_EXPERIENCE.DEEP_LEARNING]: 'Deep Learning',
    [TECH_EXPERIENCE.PAYMENT_SYSTEM]: 'Payment Systems',
    [TECH_EXPERIENCE.DATA_PROCESSING]: 'Data Processing',
    [TECH_EXPERIENCE.DEVOPS]: 'DevOps',
    [TECH_EXPERIENCE.DATA_SCIENCE]: 'Data Science',
    [TECH_EXPERIENCE.API_DEVELOPMENT]: 'API Development',
    [TECH_EXPERIENCE.MICROSERVICES]: 'Microservices',
    [TECH_EXPERIENCE.MACHINE_LEARNING]: 'Machine Learning',
    [TECH_EXPERIENCE.ENGINEERING_MANAGEMENT]: 'Engineering Management',
    [TECH_EXPERIENCE.VIDEO_STREAMING]: 'Video Streaming',
};

export const JOB_STATE_LABEL = {
    [JOB_STATE.DRAFT]: 'Draft',
    [JOB_STATE.CLOSED]: 'Closed',
    [JOB_STATE.DISABLED]: 'Disabled',
    [JOB_STATE.ACTIVE]: 'Active',
};

export const MANAGEMENT_TEAM_SIZE_LABEL = {
    [MANAGEMENT_TEAM_SIZE.TS_1_4]: '1 - 4',
    [MANAGEMENT_TEAM_SIZE.TS_5_10]: '5 - 10',
    [MANAGEMENT_TEAM_SIZE.TS_11_20]: '11 - 20',
    [MANAGEMENT_TEAM_SIZE.TS_21_50]: '21 - 50',
    [MANAGEMENT_TEAM_SIZE.TS_51_100]: '51 - 100',
    [MANAGEMENT_TEAM_SIZE.TS_100]: '100+',
};
