


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { UUID } from '@/shared/data/constants';
import { ArrayHelpers } from '@/shared/utils/ArrayHelpers';
import { IApiSendCustomCustomerIOEventRequest } from '@/admin/services/api/AdminService.dtos';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';

export interface ICustomerIOTalentData {
    userId: UUID;
    name: string | null;
    surname: string | null;
}

@Component({})
export default class SendTalentAvailabilityCustomerIOEvent extends Vue {
    @Prop()
    users!: ICustomerIOTalentData[];

    processed = 0;

    processing = false;

    buttonAvailable = true;

    companyName: string | null = null;

    existingCompanies: string[] = [];

    get filteredCompanies(): string[] {
        if (this.companyName !== null) {
            const filterCompany = this.companyName;
            return this.existingCompanies.filter(c => {
                return c.toLowerCase().indexOf(filterCompany.toLowerCase()) >= 0;
            });
        }

        return [];
    }

    async mounted() {
        this.existingCompanies = await ADMIN_SERVICE.getCustomerIoAvailabilityEventCompanies();
    }

    closeModal() {
        this.$emit('close');
    }

    async save() {
        try {
            this.processing = true;
            this.buttonAvailable = false;
            const chunks = ArrayHelpers.chunked(this.users, 20);

            for (const chunk of chunks) {
                const events: IApiSendCustomCustomerIOEventRequest[] = chunk.map(u => {
                    return {
                        userId: u.userId,
                        event: 'send_talent_availability_email',
                        data: {
                            companyName: this.companyName as string,
                        },
                    };
                });

                await new Promise<void>(resolve => {
                    setTimeout(resolve, 500);
                });

                await ADMIN_SERVICE.sendCustomerIOEvent(events);
                this.processed += chunk.length;
            }
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while sending CustomerIO event! ${err.message}`,
                type: 'is-danger',
            });
        }
    }
}
