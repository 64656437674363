
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiAdminUser } from '@/admin/services/api/AdminService.dtos';
import { Getter } from 'vuex-class';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { isSuperAdmin } from '@/admin/services/PermissionService';

@Component({})
export default class AdminAccountCard extends Vue {
    @Prop({})
    profile!: IApiAdminUser;

    isSuperAdmin = isSuperAdmin;

    get fullName(): string | null {
        if (this.profile.lastName && this.profile.name) {
            return `${this.profile.name} ${this.profile.lastName}`;
        } else if (this.profile.name) {
            return `${this.profile.name}`;
        }
        return null;
    }

    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) currentProfile!: IApiAdminUser;
}
