






























































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { API_JOB_STATE_FILTER, IApiJob } from '@/admin/services/api/AdminJobService.dtos';
import { ADMIN_JOB_SERVICE } from '@/admin/services/api/AdminJobService';
import Job from '@/admin/components/Job.vue';
import { UUID } from '@/shared/data/constants';
import { PageData } from '@/shared/data/types';
import CompanySearch from '@/admin/views/dashboard/views/CompanySearch.vue';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { IApiBasicCompanyData } from '@/admin/services/api/AdminService.dtos';
import debounce from 'lodash.debounce';

interface JobsUrlQuery {
    companyUserId: UUID | null;
    companyName: string | null;
    stateFilter: API_JOB_STATE_FILTER | null;
}

@Component({
    components: {
        Job,
    },
})
export default class Jobs extends Vue {
    buttonLoading = false;
    nrItemsPerPage = 20;
    currentPageNumber = 1;
    dataLoading = false;

    debounce = debounce;

    jobs: IApiJob[] = [];
    totalItems = 0;
    pageData: PageData<IApiJob> | null = null;
    searchResultsPerPage = this.nrItemsPerPage;

    userCompany: IApiBasicCompanyData | null = null;

    query: JobsUrlQuery = {
        companyUserId: null,
        companyName: null,
        stateFilter: API_JOB_STATE_FILTER.ACTIVE_ONLY,
    };

    API_JOB_STATE_FILTER = API_JOB_STATE_FILTER;

    @Watch('$route.query')
    async queryChange() {
        if (this.$route.query.companyUserId) {
            this.query.companyUserId = this.$route.query.companyUserId as UUID;
            this.userCompany = await ADMIN_SERVICE.getCompanyUserData(this.query.companyUserId as UUID);
        } else {
            this.query.companyUserId = null;
        }

        if (this.$route.query.stateFilter) {
            this.query.stateFilter = this.$route.query.stateFilter as API_JOB_STATE_FILTER;
        }

        if (this.$route.query.companyName) {
            this.query.companyName = this.$route.query.companyName as string;
        }

        await this.refresh();
    }

    async mounted() {
        await this.queryChange();
    }

    async onPageChange(page: number) {
        this.currentPageNumber = page;
        await this.refresh();
    }

    async refresh() {
        this.buttonLoading = true;

        try {
            this.pageData = {
                totalItems: 0,
                data: [],
            };

            this.dataLoading = true;
            this.pageData = await ADMIN_JOB_SERVICE.getAllJobs({
                companyUserId: this.query.companyUserId,
                companyName: this.query.companyName,
                stateFilter: this.query.stateFilter,
                page: this.currentPageNumber,
                perPage: this.nrItemsPerPage,
            });

            this.searchResultsPerPage = this.nrItemsPerPage;
            this.dataLoading = false;
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while fetching jobs! ${err.message}`,
                type: 'is-danger',
            });
        } finally {
            this.buttonLoading = false;
            this.dataLoading = false;
        }
    }

    async pickUserCompany() {
        const company = await new Promise<IApiBasicCompanyData | null>(resolve => {
            this.$buefy.modal.open({
                component: CompanySearch,
                props: {
                    pickOne: true,
                },
                parent: this,
                trapFocus: true,
                fullScreen: true,
                events: {
                    close: (company: IApiBasicCompanyData | null) => {
                        resolve(company);
                    },
                },
            });
        });

        if (company) {
            this.userCompany = company;
            this.query.companyUserId = this.userCompany.userId;
            await this.filterChanged();
        }
    }

    async deselectUserCompany() {
        this.userCompany = null;
        this.query.companyUserId = null;
        await this.filterChanged();
    }

    debouncedFilterChanged = debounce(this.filterChanged, 500);

    async filterChanged() {
        const newQuery: any = {};
        for (const key in this.query) {
            if ((this.query as any)[key] !== null) {
                let val = (this.query as any)[key];
                if (typeof val === 'string') {
                    val = val.trim();

                    if (val.length === 0) {
                        continue;
                    }
                }
                newQuery[key] = val;
            }
        }
        await this.$router.replace({
            query: newQuery,
        });
    }
}
