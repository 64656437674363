var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[(_vm.quiz)?[_vm._v(" Editing ")]:[_vm._v(" Create New ")],_vm._v(" Quiz ")],2)]),_c('section',{staticClass:"modal-card-body"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"content"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","type":{ 'is-danger': errors.length > 0 },"message":{ 'Name is required': errors.length > 0 }}},[_c('b-input',{attrs:{"placeholder":"Enter name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,true)}),_c('b-field',{attrs:{"label":"Developer Roles"}},[_c('b-taginput',{attrs:{"size":"is-default","type":"is-primary","data":_vm.filteredRoles,"allow-new":false,"autocomplete":"","open-on-focus":"","placeholder":"Add a role","ellipsis":""},on:{"typing":_vm.developerRolesFilter},model:{value:(_vm.developerRoles),callback:function ($$v) {_vm.developerRoles=$$v},expression:"developerRoles"}})],1),_c('b-field',{attrs:{"label":"Skills"}},[_c('b-taginput',{attrs:{"size":"is-default","type":"is-primary","data":_vm.filteredSkills,"allow-new":false,"autocomplete":"","open-on-focus":"","placeholder":"Add a skill","ellipsis":""},on:{"typing":_vm.skillsFilter},model:{value:(_vm.skills),callback:function ($$v) {_vm.skills=$$v},expression:"skills"}})],1),_c('validation-provider',{attrs:{"name":"ImageURL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Image URL","type":{ 'is-danger': errors.length > 0 },"message":{ 'Image URL is required': errors.length > 0 }}},[_c('b-input',{attrs:{"placeholder":"Enter quiz image URL"},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ShortDescription","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Short Description","type":{ 'is-danger': errors.length > 0 },"message":{ 'Short Description is required': errors.length > 0 }}},[_c('rich-text-editor',{attrs:{"show-menu":"true","resizable":"true"},model:{value:(_vm.shortDescription),callback:function ($$v) {_vm.shortDescription=$$v},expression:"shortDescription"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"LongDescription","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Long Description","type":{ 'is-danger': errors.length > 0 },"message":{ 'Long Description is required': errors.length > 0 }}},[_c('rich-text-editor',{attrs:{"show-menu":"true","resizable":"true"},model:{value:(_vm.longDescription),callback:function ($$v) {_vm.longDescription=$$v},expression:"longDescription"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"SortOrder","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Sort Order","type":{ 'is-danger': errors.length > 0 },"message":{ 'Sort Order is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","min":"0","placeholder":"Enter quiz sort order"},model:{value:(_vm.sortOrder),callback:function ($$v) {_vm.sortOrder=$$v},expression:"sortOrder"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"PassPercentage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Pass Percentage","type":{ 'is-danger': errors.length > 0 },"message":{ 'Pass Percentage is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","min":"0","placeholder":"Enter quiz pass percentage"},model:{value:(_vm.passPercentage),callback:function ($$v) {_vm.passPercentage=$$v},expression:"passPercentage"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"CertificatePercentage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Certificate Percentage","type":{ 'is-danger': errors.length > 0 },"message":{ 'Certificate Percentage is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","min":"0","placeholder":"Enter quiz certificate percentage"},model:{value:(_vm.certificatePercentage),callback:function ($$v) {_vm.certificatePercentage=$$v},expression:"certificatePercentage"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"SkipInterviewPercentage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Skip Interview Percentage","type":{ 'is-danger': errors.length > 0 },"message":{ 'Skip Interview Percentage is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","min":"0","placeholder":"Enter quiz skip interview percentage"},model:{value:(_vm.skipInterviewPercentage),callback:function ($$v) {_vm.skipInterviewPercentage=$$v},expression:"skipInterviewPercentage"}})],1)]}}],null,true)}),_c('div',{staticClass:"has-text-centered"},[_c('b-button',{staticStyle:{"margin":"10px 10px 10px 10px"},attrs:{"type":"is-warning"},on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v("Cancel ")]),_c('b-button',{staticStyle:{"margin":"10px 10px 10px 10px"},attrs:{"native-type":"submit","type":"is-success","loading":_vm.buttonLoading}},[_vm._v(" Save ")])],1)],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }