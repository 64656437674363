import { AuthService } from '@/shared/service/AuthService';
import './hooks';
import Vue from 'vue';
import VueHead from 'vue-head';
import App from './App.vue';
import router, { ADMIN_ROUTES } from './router';
import { ADMIN_ACTIONS, ADMIN_NAMESPACE, adminModule, adminNamespace } from './store';
import Buefy from 'buefy';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import createStore from '../shared/store';
import VueLazyload from 'vue-lazyload';
import LoadScript from 'vue-plugin-load-script';
import { addCustomFilters } from '@/admin/filters';
import Vuelidate from 'vuelidate';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { image, max, min, numeric, oneOf, required, size } from 'vee-validate/dist/rules';
import validator from 'validator';

Vue.config.productionTip = false;

addCustomFilters();

library.add(fas as any);
library.add(fab as any);
dom.watch();

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-layer', FontAwesomeLayers);
Vue.component('fa-layer-text', FontAwesomeLayersText);

Vue.use(LoadScript);
Vue.use(VueHead);
Vue.use(VueLazyload);
Vue.use(Vuelidate);

Vue.use(Buefy, {
    defaultIconComponent: 'fa-icon',
    defaultIconPack: 'fas',
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
extend('required', required);
extend('image', image);
extend('size', size);
extend('oneOf', oneOf);
extend('numeric', numeric);
extend('max', max);
extend('min', min);
extend('url', (value) => {
    return validator.isURL(value);
});

const store = createStore({
    [ADMIN_NAMESPACE]: adminModule,
});

setImmediate(async () => {
    const authData = AuthService.authData();

    if (authData) {
        try {
            await store.dispatch(adminNamespace(ADMIN_ACTIONS.PROCESS_LOGIN), AuthService.authData());
        } catch (error) {
            AuthService.localLogout();
            await router.push({ name: ADMIN_ROUTES.AUTH });
        }
    }

    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
});
