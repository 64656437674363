import Vue from 'vue';
import { formatDateTime } from '@/tests/utils/general.utils';

const numberFormatter = new Intl.NumberFormat();

export const addCustomFilters = () => {
  Vue.filter('datetime', (value: Date | string) => {
    let date: Date;
    if (value instanceof Date) {
      date = value;
    } else {
      date = new Date(value);
    }

    return formatDateTime(date);
  });

  Vue.filter('currency', (value: number, currency: string) => {
    return `${currency}${numberFormatter.format(value)}`;
  });

  Vue.filter('capitalize', (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
  });
};

