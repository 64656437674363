







import { AuthService } from '@/shared/service/AuthService';
import { Component, Vue } from 'vue-property-decorator';
import { ADMIN_ACTIONS, adminNamespace } from '../../store';
import { ADMIN_ROUTES } from '@/admin/router';

@Component({})
export default class Callback extends Vue {
    public async created() {
        const clientId = process.env.VUE_APP_AUTH_ADMIN_CLIENT_ID as string;
        const domain = process.env.VUE_APP_AUTH_ADMIN_CLIENT_DOMAIN as string;

        const authService = new AuthService(clientId, domain);
        await authService.handleAuth();
        try {
            await this.$store.dispatch(adminNamespace(ADMIN_ACTIONS.PROCESS_LOGIN), AuthService.authData());
            await this.$router.push({ name: ADMIN_ROUTES.TALENT_SEARCH });
        } catch(error) {
            AuthService.localLogout();
            this.$router.push({name: ADMIN_ROUTES.AUTH});
        }

    }
}
