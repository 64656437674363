







































































































import { Component, Vue } from 'vue-property-decorator';
import { QUIZ_STATE, UUID } from '@/shared/data/constants';
import CreateNewQuizModal from '@/admin/views/dashboard/views/quizzes/modals/CreateNewQuizModal.vue';
import { ADMIN_ROUTES } from '@/admin/router';
import { IApiQuiz } from '@/admin/services/api/AdminQuizService.dtos';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';
import { Codetables, CodetableService, DeveloperRole } from '@/shared/service/CodetableService';
import { ArrayHelpers } from '@/shared/utils/ArrayHelpers';

@Component({})
export default class Quizzes extends Vue {
    dataLoading = false;
    data: IApiQuiz[] = [];
    ids: UUID[] = [];

    QUIZ_STATE = QUIZ_STATE;

    allRoles: DeveloperRole[] = [];

    getRoleName(role: string): string {
        return ArrayHelpers.single(this.allRoles, dr => dr.name === role).displayName;
    }

    async refresh() {
        try {
            this.dataLoading = true;
            this.data = await ADMIN_QUIZ_SERVICE.getAllQuizzes();
            this.ids = this.data.map((d) => d.id);
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while fetching quizzes! ${e.message}`,
                type: 'is-danger',
            });
        } finally {
            this.dataLoading = false;
        }
    }

    async mounted() {
        const codetables = await CodetableService.getCodetables(Codetables.DEVELOPER_ROLES);
        this.allRoles = codetables.developer_roles as DeveloperRole[];

        await this.refresh();
    }

    async editCategories(id: UUID) {
        await this.$router.push({ name: ADMIN_ROUTES.QUIZ_CATEGORIES, params: { quizId: id } });
    }

    async editQuiz(quiz: IApiQuiz) {
        const refresh = await new Promise<boolean>((resolve) => {
            this.$buefy.modal.open({
                component: CreateNewQuizModal,
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                props: {
                    quiz: quiz,
                },
                events: {
                    close: (refreshParent: boolean) => {
                        resolve(refreshParent);
                    },
                },
            });
        });

        if (refresh) {
            await this.refresh();
        }
    }

    async createNewQuiz() {
        const refresh = await new Promise<boolean>((resolve) => {
            this.$buefy.modal.open({
                component: CreateNewQuizModal,
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: (refreshParent: boolean) => {
                        resolve(refreshParent);
                    },
                },
            });
        });

        if (refresh) {
            await this.refresh();
        }
    }

    async activateQuiz(quiz: IApiQuiz) {
        try {
            await ADMIN_QUIZ_SERVICE.updateQuizState(quiz, QUIZ_STATE.ACTIVE);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: e.message,
                type: 'is-danger',
            });
        }
    }

    async putQuizToDraft(quiz: IApiQuiz) {
        try {
            await ADMIN_QUIZ_SERVICE.updateQuizState(quiz, QUIZ_STATE.DRAFT);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: e.message,
                type: 'is-danger',
            });
        }
    }

    async putQuizToComingSoon(quiz: IApiQuiz) {
        try {
            await ADMIN_QUIZ_SERVICE.updateQuizState(quiz, QUIZ_STATE.COMING_SOON);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: e.message,
                type: 'is-danger',
            });
        }
    }
}
