









import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Codetables, CodetableService, Skill } from '@/shared/service/CodetableService';
import TagInput from '@/admin/components/inputs/TagInput.vue';

@Component({
    components: {
        TagInput,
    },
})
export default class SkillSelector extends Vue {
    @Prop()
    value!: string[];

    @Watch('value')
    onValueChange(val: string[]) {
        if (val === this.newValue) {
            return;
        }

        this.newValue = val;
    }

    @Prop({ default: () => 'Select skills' })
    placeholder!: string;

    allSkills: string[] = [];

    newValue: string[] = [];

    async mounted() {
        const result = await CodetableService.getCodetables(Codetables.SKILLS);
        this.allSkills = (result.skills as Skill[]).map(s => s.name);
        this.newValue = this.value;
    }

    async emitInput(data: string[]) {
        this.$emit('input', data);
    }
}
