import { ApiServiceBase } from '@/shared/service/ServiceBase';
import { IApiAvailableTag, IApiUserTagRequest } from '@/admin/services/api/AdminUserTagService.dtos';

export class AdminUserTagService extends ApiServiceBase {
  public constructor(baseApiUrl?: string) {
    super(baseApiUrl || '/api/user-service/admin/usertags');
  }

  private cachedAvailableTags: IApiAvailableTag[] | null = null;

  public async getAvailableTags(): Promise<IApiAvailableTag[]> {
    if (this.cachedAvailableTags !== null) {
      return this.cachedAvailableTags;
    }

    this.cachedAvailableTags = await this.safeGetJson('/');

    return this.cachedAvailableTags as IApiAvailableTag[];
  }

  public async createTag(req: IApiUserTagRequest): Promise<void> {
    await this.safePostResponse('/', req);
  }

  public async removeTag(req: IApiUserTagRequest): Promise<void> {
    await this.safeDeleteResponse('/', req);
  }
}

export const ADMIN_USER_TAG_SERVICE = new AdminUserTagService();
