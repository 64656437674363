
















































































































import { Component, Prop } from 'vue-property-decorator';
import ComponentBase from '@/admin/components/ComponentBase';
import {
    IApiBasicTalentData,
    IApiTechnicalInterviewEvaluationResults,
    IApiTechnicalInterviewEvaluationResultsV2,
    IApiTechnicalInterviewTaskCategoryResult,
    IApiUserVettingRecord,
} from '@/admin/services/api/AdminService.dtos';
import { ArrayHelpers } from '@/shared/utils/ArrayHelpers';
import { DEVELOPER_ROLE_LABEL, MANAGEMENT_TEAM_SIZE_LABEL } from '@/shared/data/default-labels';
import { DEVELOPER_ROLE, MANAGEMENT_TEAM_SIZE, USER_VETTING_RECORD_TYPE } from '@/shared/data/constants';

@Component({})
export default class TalentSummary extends ComponentBase {
    @Prop()
    readonly user!: IApiBasicTalentData;

    DEVELOPER_ROLE_LABEL = DEVELOPER_ROLE_LABEL;

    scoreText(score: number): string {
        switch (score) {
            case 1:
                return 'Very Poor';
            case 2:
                return 'Poor';
            case 3:
                return 'Basic';
            case 4:
                return 'Good';
            case 5:
                return 'Exceptional';
            default:
                return '';
        }
    }

    get finalInterviewResults(): string | undefined {
        const sorted = this.user.vettingRecords
            .filter(r => r.type === USER_VETTING_RECORD_TYPE.FINAL_INTERVIEW && r.success === true)
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

        if (sorted.length > 0) {
            return sorted[0].results.summary;
        }

        return undefined;
    }

    get evaluationResultsV3(): IApiTechnicalInterviewEvaluationResults | undefined {
        const interview = this.latestSuccessfulTechnicalInterview;
        if (interview && interview.results.version === 3) {
            return interview.results as IApiTechnicalInterviewEvaluationResults;
        }

        return undefined;
    }

    get communicationCategory(): IApiTechnicalInterviewTaskCategoryResult | undefined {
        const res = this.evaluationResultsV2;

        if (res) {
            return res.tasks[0].categoryResults.find(c => c.category === 'Communication');
        }

        return undefined;
    }

    get experienceCategory(): IApiTechnicalInterviewTaskCategoryResult | undefined {
        const res = this.evaluationResultsV2;

        if (res) {
            return res.tasks[0].categoryResults.find(c => c.category === 'Experience');
        }

        return undefined;
    }

    get productivityCategory(): IApiTechnicalInterviewTaskCategoryResult | undefined {
        const res = this.evaluationResultsV2;

        if (res) {
            return res.tasks[0].categoryResults.find(c => c.category === 'Coding Productivity');
        }

        return undefined;
    }

    get taskResultCategory(): IApiTechnicalInterviewTaskCategoryResult | undefined {
        const res = this.evaluationResultsV2;

        if (res) {
            return res.tasks[0].categoryResults.find(c => c.category === 'Task Results');
        }

        return undefined;
    }

    get evaluationResultsV2(): IApiTechnicalInterviewEvaluationResultsV2 | undefined {
        const interview = this.latestSuccessfulTechnicalInterview;
        if (interview && interview.results.version === 2) {
            return interview.results as IApiTechnicalInterviewEvaluationResultsV2;
        }

        return undefined;
    }


    get englishLevel(): string | undefined {
        const results = this.evaluationResultsV3;

        if (results) {
            return results.englishLevel.displayName;
        }

        return undefined;
    }

    get latestSuccessfulTechnicalInterview(): IApiUserVettingRecord | undefined {
        const sorted = this.user.vettingRecords
            .filter(r => r.type === USER_VETTING_RECORD_TYPE.TECHNICAL_INTERVIEW && r.success === true)
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

        if (sorted.length > 0) {
            return sorted[0];
        }

        return undefined;
    }

    get skillsText(): string {
        if (this.user.properties.TALENT_MAIN_SKILLS) {
            let text = this.user.properties.TALENT_MAIN_SKILLS.map(s => s.TALENT_SKILL_NAME).join(', ');

            if (this.user.properties.TALENT_SUPPORT_SKILLS && this.user.properties.TALENT_SUPPORT_SKILLS.length > 0) {
                text += ', ' + this.user.properties.TALENT_SUPPORT_SKILLS.map(s => s.TALENT_SKILL_NAME).join(', ');
            }

            return text;
        }

        return '';
    }

    get managementText(): string {
        if (this.user.properties.MANAGEMENT) {
            return `Contributor/Manager with ${this.user.properties.MANAGEMENT.MANAGEMENT_YEARS} years of experience leading a team of ${MANAGEMENT_TEAM_SIZE_LABEL[this.user.properties.MANAGEMENT.MANAGEMENT_TEAM_SIZE as MANAGEMENT_TEAM_SIZE]} people.`;
        }

        return '';
    }

    get roleText(): string {
        if (this.user.properties.TALENT_ROLE_EXPERIENCES) {
            return this.user.properties.TALENT_ROLE_EXPERIENCES.map(r => `${DEVELOPER_ROLE_LABEL[r.TALENT_ROLE as DEVELOPER_ROLE]} - ${r.TALENT_ROLE_YEARS} years`).join(', ');
        }

        return '';
    }

    hasSocial(type: string): boolean {
        return this.user.socials.find(s => s.social === type) !== undefined;
    }

    getSocial(type: string): string {
        return ArrayHelpers.single(this.user.socials, s => s.social === type).link;
    }
}
