import { ApiServiceBase } from '@/shared/service/ServiceBase';
import { IApiJob, IApiJobCriteria, IApiJobModifyRequest } from '@/admin/services/api/AdminJobService.dtos';
import { UUID } from '@/shared/data/constants';
import { PageData } from '@/shared/data/types';

export class AdminJobService extends ApiServiceBase {
    public constructor(baseApiUrl?: string) {
        super(baseApiUrl || '/api/user-service/admin/jobs');
    }

    public async getAllJobs(criteria: IApiJobCriteria): Promise<PageData<IApiJob>> {
        return await this.safePostJson('/list', criteria);
    }

    public async getJob(id: UUID): Promise<IApiJob> {
        return await this.safeGetJson(`/${id}`);
    }

    public async createJob(req: IApiJobModifyRequest): Promise<void> {
        await this.safePostResponse('', req);
    }

    public async updateJob(id: UUID, req: IApiJobModifyRequest): Promise<void> {
        await this.safePatchResponse(`/${id}`, req);
    }

    public async approveJob(id: UUID): Promise<void> {
        await this.safePostResponse((`/${id}/approve`));
    }

}

export const ADMIN_JOB_SERVICE = new AdminJobService();
