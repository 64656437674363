export const LOCAL_STORAGE_PREFIX = (process.env.BASE_URL as string).replace(/\//g, '');

function toKey(key: string): string {
  return `${LOCAL_STORAGE_PREFIX}_${key}`;
}

export const localStorageSet = (key: string, value: string) => {
  localStorage.setItem(toKey(key), value);
};

export const localStorageLoad = (key: string): string | null => {
  return localStorage.getItem(toKey(key));
};

export const localStorageRemove = (key: string) => {
  localStorage.removeItem(toKey(key));
};
