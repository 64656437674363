import { NavigationGuard } from 'vue-router';
import { COMMON_GETTERS, store } from '../store';

export const noAuthGuard: NavigationGuard = (to, from, next) => {
  if (store.getters[COMMON_GETTERS.ID_TOKEN]) {
    window.location.href = process.env.VUE_APP_LANDER_PAGE_URL as string;
  } else {
    next();
  }
};

export const authGuard = (noAuthRouteName: string): NavigationGuard => {
  return (to, from, next) => {
    if (store.getters[COMMON_GETTERS.ID_TOKEN]) {
      next();
    } else {
      next({ name: noAuthRouteName });
    }
  };
};

export const AUTH_CALLBACK_URL = `${window.location.protocol}//${window.location.host}${process.env.BASE_URL}auth/callback`;
