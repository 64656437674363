
































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiBasicTalentData, IApiUserVettingRecord } from '@/admin/services/api/AdminService.dtos';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

@Component({
    components: {
        RichTextEditor,
    }
})
export default class SetOrEditFinalInterviewResults extends Vue {
    @Prop()
    user!: IApiBasicTalentData;

    @Prop({ default: null })
    existingRecord!: IApiUserVettingRecord | null;

    @Prop()
    eventId!: string;

    @Prop()
    scheduledOn!: string;

    buttonLoading = false;

    success: boolean | null = null;
    summary: string | null = null;

    async mounted() {
        if (this.existingRecord) {
            this.success = this.existingRecord.success;
            this.summary = this.existingRecord.results.summary;
        }
    }

    closeModal(refreshList: boolean) {
        this.$emit('close', refreshList);
    }

    async setResults() {
        this.buttonLoading = true;
        try {
            if (this.existingRecord) {
                // updating
                const request = {
                    success: this.success as boolean,
                    summary: this.summary as string,
                };

                await ADMIN_SERVICE.updateFinalInterviewResults(this.existingRecord.id, request);
            } else {
                // setting
                const request = {
                    success: this.success as boolean,
                    summary: this.summary as string,
                    eventId: this.eventId,
                    scheduledOn: this.scheduledOn,
                };

                await ADMIN_SERVICE.setFinalInterviewResults(this.user.userId, request);
            }
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while setting final interview results! ${err.message}`,
                type: 'is-danger',
            });
        }

        this.buttonLoading = false;
        this.closeModal(true);
    }
}
