
















































import { Component } from 'vue-property-decorator';
import ComponentBase from '@/admin/components/ComponentBase';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { RESTRICTED_TOKEN_TYPE } from '@/shared/data/constants';

@Component({})
export default class RestrictedTokenGenerator extends ComponentBase {
    usableUntil: Date | null = null;

    lastGeneratedCompanyAutoConfirmLink: string | null = null;

    formatter(d: Date): string {
        return d.toLocaleDateString();
    }

    async generateCompanyAutoConfirmLink() {
        try {
            const result = await ADMIN_SERVICE.generateRestrictedToken(
                RESTRICTED_TOKEN_TYPE.COMPANY_REGISTRATION_AUTO_CONFIRM,
                undefined,
                this.usableUntil,
            );
            this.lastGeneratedCompanyAutoConfirmLink = `${process.env.VUE_APP_COMPANY_URL}?u=${result}`;
        } catch (e) {
            console.error('Error generating company auto confirm link!', e);
        }
    }
}
