
























































































































































































































































































































































































































































































































































import { Component } from 'vue-property-decorator';
import { ISelectableItem, PageData } from '@/shared/data/types';
import {
    ADMIN_DATA_TYPE,
    ADMIN_USER_TAG,
    ALL_AVAILABILITIES,
    ALL_DEVELOPER_ROLES,
    ALL_USER_STATES,
    AVAILABILITY,
    DEVELOPER_ROLE,
    USER_STATE,
    USER_TYPE,
    UUID,
} from '@/shared/data/constants';
import {
    Codetables,
    CodetableService,
    Country,
    Region,
    Skill,
    TalentEngineeringExperience,
    TimeZone,
} from '@/shared/service/CodetableService';
import {
    IApiAdminData,
    IApiAdminUser,
    IApiBasicCompanyData,
    IApiBasicTalentData,
    IApiDeveloperRoleCriteria,
    ICustomerIoCampaignFilter,
} from '@/admin/services/api/AdminService.dtos';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { Getter } from 'vuex-class';
import { COMMON_GETTERS } from '@/shared/store';
import { AuthProfile } from '@/shared/service/AuthService';
import SendTalentAvailabilityCustomerIOEvent, {
    ICustomerIOTalentData,
} from '@/admin/views/dashboard/views/modals/SendTalentAvailabilityCustomerIOEvent.vue';
import { AVAILABILITY_LABEL, DEVELOPER_ROLE_LABEL, USER_STATE_LABEL } from '@/shared/data/default-labels';
import TechnicalInterview from '@/admin/views/dashboard/views/user-search/TechnicalInterview.vue';
import FinalInterview from '@/admin/views/dashboard/views/user-search/FinalInterview.vue';
import Quiz from '@/admin/views/dashboard/views/user-search/Quiz.vue';
import HackerRank from '@/admin/views/dashboard/views/user-search/HackerRank.vue';
import SocialLink from '@/admin/views/dashboard/views/user-search/SocialLink.vue';
import { ArrayHelpers } from '@/shared/utils/ArrayHelpers';
import { formatDate, getUTCDate } from '@/tests/utils/general.utils';
import JobApplication from '@/admin/views/dashboard/views/user-search/JobApplication.vue';
import TagInput from '@/admin/components/inputs/TagInput.vue';
import SkillSelector from '@/admin/components/SkillSelector.vue';
import TalentCard from '@/admin/components/TalentCard.vue';
import { ADMIN_PERMISSIONS, checkPermission } from '@/admin/services/PermissionService';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { ADMIN_USER_TAG_SERVICE } from '@/admin/services/api/AdminUserTagService';
import { IApiAvailableTag } from '@/admin/services/api/AdminUserTagService.dtos';
import { IApiJob } from '@/admin/services/api/AdminJobService.dtos';
import { ADMIN_JOB_SERVICE } from '@/admin/services/api/AdminJobService';
import ComponentBase from '@/admin/components/ComponentBase';
import GenerateTalentSharedLink from '@/admin/views/dashboard/views/modals/GenerateTalentSharedLink.vue';

@Component({
    components: {
        SkillSelector,
        TechnicalInterview,
        FinalInterview,
        Quiz,
        HackerRank,
        SocialLink,
        JobApplication,
        TagInput,
        TalentCard,
    },
})
export default class TalentSearch extends ComponentBase {
    @Getter(COMMON_GETTERS.AUTH_PROFILE) authProfile!: AuthProfile | null;
    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;

    searchName: string | null = null;
    savePrivateSearch = true;
    loadPrivateSearch = true;
    selectedFilterId: UUID | null = null;

    globalSearches: IApiAdminData[] = [];
    adminSearches: IApiAdminData[] = [];

    buttonLoading = false;
    dataLoading = false;
    nrItemsPerPage = 60;
    currentPageNumber = 1;

    allSkills: string[] = [];
    existingCompanies: string[] = [];
    availableAdminTags: IApiAvailableTag[] = [];
    engineeringExperiences: TalentEngineeringExperience[] = [];
    countries: string[] = [];
    regions: Region[] = [];
    timezones: TimeZone[] = [];
    jobId: UUID | null = null;
    jobData: IApiJob | null = null;
    jobCompanyUser: IApiBasicCompanyData | null = null;
    states: string[] = [USER_STATE_LABEL[USER_STATE.ACTIVE]];
    defaultAdminTags: ADMIN_USER_TAG[] = [ADMIN_USER_TAG.NO_SHOW, ADMIN_USER_TAG.UNPROFESSIONAL, ADMIN_USER_TAG.POOR_ENGLISH];
    adminTags: string[] = [];
    filteredSkills: string[] = [];

    pageData: PageData<ISelectableItem<IApiBasicTalentData>> | null = null;
    searchResultsPerPage = this.nrItemsPerPage;
    userType: USER_TYPE = USER_TYPE.talent;
    userId: UUID | null = null;
    email: string | null = null;
    developerRole: DEVELOPER_ROLE | null = null;
    minDevRoleYears: number | null = null;
    developerRolesIncludeAll = true;
    developerRoleFilter: IApiDeveloperRoleCriteria[] = [];
    selectedRegion: string | null = null;
    includeCountries: string[] = [];
    excludeCountries: string[] = [];
    fromTimezone: string | null = null;
    toTimezone: string | null = null;
    commitment: string | null = null;
    name: string | null = null;
    surname: string | null = null;
    mainSkills: string[] = [];
    mainSkillsIncludeAll = false;
    supportSkills: string[] = [];
    supportSkillsIncludeAll = false;
    anySkills: string[] = [];
    techExperienceIncludeAll = true;
    techExperiences: string[] = [];
    salaryFrom: number | null = null;
    salaryTo: number | null = null;
    hasJobAppliedForSet: boolean | null = null;
    availabilities: string[] = [AVAILABILITY_LABEL[AVAILABILITY.ACTIVELY_LOOKING], AVAILABILITY_LABEL[AVAILABILITY.OPEN]];
    passedQuiz: boolean | null = false;
    hasManagementExperience: boolean | null = false;
    passedTechnicalInterview: boolean | null = false;
    passedFinalInterview: boolean | null = false;
    minExperienceYears: number | null = null;
    registrationFromDate: Date | null = null;
    registrationToDate: Date | null = null;
    applicationFromDate: Date | null = null;
    applicationToDate: Date | null = null;
    excludeCustomerIoCampaigns: ICustomerIoCampaignFilter[] = [];
    companyName: string | null = null;
    minDate: Date | null = null;

    formatDate = formatDate;
    ALL_DEVELOPER_ROLES = ALL_DEVELOPER_ROLES;
    DEVELOPER_ROLE_LABEL = DEVELOPER_ROLE_LABEL;

    get canSendCustomerIOEvents(): boolean {
        return checkPermission(this.profile, ADMIN_PERMISSIONS.SEND_CUSTOMER_IO_EVENTS);
    }

    get fromTimezones(): TimeZone[] {
        if (this.toTimezone) {
            const number = parseFloat(this.toTimezone.replace('UTC', ''));
            return this.timezones.filter(t => parseFloat(t.code.replace('UTC', '')) < number);
        } else {
            return this.timezones;
        }
    }

    get toTimezones(): TimeZone[] {
        if (this.fromTimezone) {
            const number = parseFloat(this.fromTimezone.replace('UTC', ''));
            return this.timezones.filter(t => parseFloat(t.code.replace('UTC', '')) > number);
        } else {
            return this.timezones;
        }
    }

    formatter(d: Date): string {
        return d.toLocaleDateString();
    }

    get allTechnologyExperiences(): string[] {
        return this.engineeringExperiences.map(t => t.displayName);
    }

    get allAdminTags(): string[] {
        return this.availableAdminTags.map(t => t.displayName);
    }

    get filteredCompanies(): string[] {
        if (this.companyName !== null) {
            const filterCompany = this.companyName;
            return this.existingCompanies.filter(c => {
                return c.toLowerCase().indexOf(filterCompany.toLowerCase()) >= 0;
            });
        }

        return [];
    }

    get selectedUserIds(): UUID[] {
        if (this.pageData) {
            return this.pageData.data.filter(d => d.selected).map(d => d.item.userId);
        }

        return [];
    }


    get allUserStates() {
        return ALL_USER_STATES.map(s => USER_STATE_LABEL[s]);
    }

    get allAvailabilities() {
        return ALL_AVAILABILITIES.map(a => AVAILABILITY_LABEL[a]);
    }

    get availableDeveloperRoles(): DEVELOPER_ROLE[] {
        const allSelectedRoles = this.developerRoleFilter.map(r => r.role);
        return ALL_DEVELOPER_ROLES.filter(r => !allSelectedRoles.includes(r));
    }

    regionChanged(region: string) {
        if (region) {
            const regionCountries = ArrayHelpers.single(this.regions, r => r.name == region).countries.map(c => c.name);
            this.includeCountries = ArrayHelpers.unique([...this.includeCountries, ...regionCountries]);
            this.$nextTick(() => {
                this.selectedRegion = null;
            });
        }
    }

    removeRoleFilter(role: DEVELOPER_ROLE) {
        this.developerRoleFilter = this.developerRoleFilter.filter(r => r.role !== role);
    }

    developerRoleSelected(val: DEVELOPER_ROLE) {
        if (this.minDevRoleYears !== null && this.minDevRoleYears !== undefined) {
            if (this.developerRoleFilter.find(drf => drf.role === this.developerRole)) {
                this.$buefy.dialog.alert({
                    title: 'Warning',
                    message: `Role ${DEVELOPER_ROLE_LABEL[val]} is already added! Please remove and re-add!`,
                    type: 'is-warning',
                });
            } else {
                this.developerRoleFilter.push({
                    role: val,
                    years: this.minDevRoleYears,
                });
                this.$nextTick(() => {
                    this.developerRole = null;
                    this.minDevRoleYears = null;
                });
            }
        }
    }

    developerRoleYearsSelected(years: number) {
        if (this.developerRole !== null && this.developerRole !== undefined) {
            if (this.developerRoleFilter.find(drf => drf.role === this.developerRole)) {
                this.$buefy.dialog.alert({
                    title: 'Warning',
                    message: `Role ${DEVELOPER_ROLE_LABEL[this.developerRole]} is already added! Please remove and re-add!`,
                    type: 'is-warning',
                });
            } else {
                this.developerRoleFilter.push({
                    role: this.developerRole,
                    years,
                });
                this.$nextTick(() => {
                    this.developerRole = null;
                    this.minDevRoleYears = null;
                });
            }
        }
    }

    addCustomerIoCampaignFilter() {
        if (this.companyName) {
            if (this.excludeCustomerIoCampaigns.find(c => c.companyName === this.companyName?.trim()) === undefined) {
                this.excludeCustomerIoCampaigns.push({
                    companyName: (this.companyName as string).trim(),
                    minDate: this.minDate ? getUTCDate(this.minDate).toISOString() : null,
                });

                this.companyName = null;
                this.minDate = null;
            }
        }
    }

    removeCustomerIoCampaignFilter(companyName: string) {
        this.excludeCustomerIoCampaigns = this.excludeCustomerIoCampaigns.filter(c => c.companyName !== companyName);
    }

    async selectCurrentPage() {
        this.pageData?.data.forEach(d => d.selected = true);

    }

    async deselectAll() {
        this.pageData?.data.forEach(d => d.selected = false);
    }

    async generateSharableLinks() {
        if (this.pageData && this.selectedUserIds.length > 0) {
            const users = this.pageData.data
                .filter(d => this.selectedUserIds.includes(d.item.userId))
                .map(d => d.item);

            await new Promise<void>((resolve) => {
                this.$buefy.modal.open({
                    component: GenerateTalentSharedLink,
                    props: {
                        users,
                    },
                    hasModalCard: true,
                    parent: this,
                    trapFocus: true,
                    events: {
                        close: () => {
                            resolve();
                        },
                    },
                });
            });
        }
    }

    async removeJobFilter() {
        this.jobId = null;
        this.jobData = null;
        this.jobCompanyUser = null;
        const query = Object.assign({}, this.$route.query);
        delete query.jobId;
        await this.$router.replace({ query });
        await this.refresh();
    }

    async mounted() {
        const results = await CodetableService.getCodetables(Codetables.COUNTRIES, Codetables.TIMEZONES, Codetables.SKILLS, Codetables.TALENT_ENGINEERING_EXPERIENCES, Codetables.REGIONS);
        this.countries = (results.countries as Country[]).map(c => c.name);
        this.regions = results.regions as Region[];
        this.timezones = results.timezones as TimeZone[];
        this.allSkills = (results.skills as Skill[]).map((s) => s.name);
        this.engineeringExperiences = results.talent_engineering_experiences as TalentEngineeringExperience[];
        this.existingCompanies = await ADMIN_SERVICE.getCustomerIoAvailabilityEventCompanies();
        this.availableAdminTags = await ADMIN_USER_TAG_SERVICE.getAvailableTags();

        this.adminTags = this.defaultAdminTags.map(t => {
            const res = this.availableAdminTags.find(at => at.name === t);
            if (res) {
                return res.displayName;
            }

            throw new Error(`Could not map admin tag ${t}`);
        });

        let searchNow = false;
        if (this.$route.query.userId) {
            this.userId = this.$route.query.userId as string;
            searchNow = true;
        }

        if (this.$route.query.jobId) {
            this.jobId = this.$route.query.jobId as UUID;

            this.jobData = await ADMIN_JOB_SERVICE.getJob(this.jobId);
            if (this.jobData.companyUserId) {
                this.jobCompanyUser = await ADMIN_SERVICE.getCompanyUserData(this.jobData.companyUserId);
            }

            searchNow = true;
        }

        if (this.$route.query.state) {
            this.states = [USER_STATE_LABEL[this.$route.query.state as USER_STATE]];
            searchNow = true;
        }

        if (searchNow) {
            await this.search();
        }

        this.adminSearches = await ADMIN_SERVICE.getAdminData(ADMIN_DATA_TYPE.TALENT_SEARCH_FILTER);
        this.globalSearches = await ADMIN_SERVICE.getGlobalData(ADMIN_DATA_TYPE.TALENT_SEARCH_FILTER);
    }

    reset() {
        this.pageData = null;
        this.currentPageNumber = 1;

        this.userId = null;
        this.jobId = null;
        this.jobData = null;
        this.jobCompanyUser = null;
        this.email = null;
        this.states = [USER_STATE_LABEL[USER_STATE.ACTIVE]];
        this.commitment = null;
        this.name = null;
        this.surname = null;
        this.includeCountries = [];
        this.excludeCountries = [];
        this.fromTimezone = null;
        this.toTimezone = null;
        this.mainSkills = [];
        this.mainSkillsIncludeAll = false;
        this.supportSkills = [];
        this.supportSkillsIncludeAll = false;
        this.anySkills = [];
        this.salaryFrom = null;
        this.salaryTo = null;
        this.availabilities = [AVAILABILITY_LABEL[AVAILABILITY.ACTIVELY_LOOKING], AVAILABILITY_LABEL[AVAILABILITY.OPEN]];
        this.passedQuiz = null;
        this.passedTechnicalInterview = null;
        this.passedFinalInterview = null;
        this.excludeCustomerIoCampaigns = [];
        this.minExperienceYears = null;
        this.developerRolesIncludeAll = false;
        this.developerRole = null;
        this.minDevRoleYears = null;
        this.developerRoleFilter = [];
        this.minExperienceYears = null;
        this.registrationFromDate = null;
        this.registrationToDate = null;
        this.applicationFromDate = null;
        this.applicationToDate = null;
        this.hasManagementExperience = false;
        this.techExperiences = [];
        this.techExperienceIncludeAll = false;
        this.adminTags = this.defaultAdminTags.map(t => {
            const res = this.availableAdminTags.find(at => at.name === t);
            if (res) {
                return res.displayName;
            }

            throw new Error(`Could not map admin tag ${t}`);
        });
    }

    async search() {
        this.buttonLoading = true;
        try {
            await this.refresh(1);
        } catch (err) {
            console.error('Error during search!', err);
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error during search! ${err.message}`,
                type: 'is-danger',
            });
        }
        this.buttonLoading = false;
    }

    async refresh(forcePage?: number) {
        this.dataLoading = true;

        try {
            this.pageData = {
                totalItems: 0,
                data: [],
            };

            const data = (await ADMIN_SERVICE.findTalent(
                {
                    userIds: this.userId ? [this.userId.trim()] : null,
                    states: this.states.map(s => ArrayHelpers.single(ALL_USER_STATES, (us) => USER_STATE_LABEL[us] === s) as USER_STATE),
                    name: this.name ? this.name.trim() : undefined,
                    surname: this.surname ? this.surname.trim() : undefined,
                    email: this.email ? this.email.trim() : undefined,
                    contractType: this.commitment,
                    includeCountries: this.includeCountries,
                    excludeCountries: this.excludeCountries,
                    fromTimezone: this.fromTimezone,
                    toTimezone: this.toTimezone,
                    mainSkillsIncludeAll: this.mainSkillsIncludeAll,
                    mainSkills: this.mainSkills,
                    supportSkillsIncludeAll: this.supportSkillsIncludeAll,
                    supportSkills: this.supportSkills,
                    anySkills: this.anySkills,
                    salaryFrom: this.salaryFrom,
                    salaryTo: this.salaryTo,
                    hasJobAppliedForSet: this.hasJobAppliedForSet,
                    availabilities: this.availabilities.map(this.getAvailabilityFromLabel),
                    passedQuiz: this.passedQuiz,
                    passedTechnicalInterview: this.passedTechnicalInterview,
                    passedFinalInterview: this.passedFinalInterview,
                    minExperienceYears: this.minExperienceYears,
                    developerRolesIncludeAll: this.developerRolesIncludeAll,
                    developerRoles: this.developerRoleFilter,
                    registrationFromDate: this.registrationFromDate ? getUTCDate(this.registrationFromDate).toISOString() : undefined,
                    registrationToDate: this.registrationToDate ? getUTCDate(this.registrationToDate).toISOString() : undefined,
                    appliedForJobId: this.jobId ? this.jobId : undefined,
                    applicationFromDate: this.applicationFromDate ? getUTCDate(this.applicationFromDate).toISOString() : undefined,
                    applicationToDate: this.applicationToDate ? getUTCDate(this.applicationToDate).toISOString() : undefined,
                    excludeCustomerIoCampaigns: this.excludeCustomerIoCampaigns,
                    hasManagementExperience: this.hasManagementExperience,
                    techExperiences: this.techExperiences.map(te => {
                        const res = this.engineeringExperiences.find(e => e.displayName === te);
                        if (res) {
                            return res.name;
                        }
                        return te;
                    }),
                    techExperienceIncludeAll: this.techExperienceIncludeAll,
                    excludeAdminTags: this.adminTags.map(at => {
                        const res = this.availableAdminTags.find(a => a.displayName === at);
                        if (res) {
                            return res.name;
                        }
                        throw Error(`Should have found and existing admin tag for ${at}!`);
                    }),
                },
                forcePage ? forcePage : this.currentPageNumber,
                this.nrItemsPerPage,
            )) as PageData<IApiBasicTalentData>;
            this.pageData = {
                totalItems: data.totalItems,
                data: data.data.map(d => {
                    return {
                        item: d,
                        selected: false,
                    };
                }),
            };

            this.searchResultsPerPage = this.nrItemsPerPage;
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while refreshing data! ${err.message}`,
                type: 'is-danger',
            });
        }
        this.dataLoading = false;
    }

    getAvailabilityFromLabel(label: string): AVAILABILITY {
        const keys = Object.keys(AVAILABILITY_LABEL);

        for (const key of keys) {
            if (AVAILABILITY_LABEL[key as AVAILABILITY] === label) {
                return key as AVAILABILITY;
            }
        }

        throw new Error(`Unknown availability label ${label}!`);
    }

    async onPageChange(page: number) {
        this.currentPageNumber = page;
        await this.refresh();
    }

    async openCustomerIoEventModal() {
        if (this.pageData) {
            const allUsers = this.pageData.data;
            const users: ICustomerIOTalentData[] =
                this.selectedUserIds.map(userId => {
                    const user = allUsers.find(u => u.item.userId === userId);
                    if (!user) {
                        throw Error('This should not happen!');
                    }

                    return {
                        userId,
                        name: user.item.properties.TALENT_NAME || '',
                        surname: user.item.properties.TALENT_LAST_NAME || '',
                    };
                });

            await new Promise<void>((resolve) => {
                this.$buefy.modal.open({
                    component: SendTalentAvailabilityCustomerIOEvent,
                    props: {
                        users,
                    },
                    hasModalCard: true,
                    parent: this,
                    trapFocus: true,
                    events: {
                        close: () => {
                            resolve();
                        },
                    },
                });
            });

            await this.refresh();
        }
    }

    async saveSearch() {
        try {
            if (!this.searchName) {
                this.$buefy.dialog.alert('No search name set!');
                return;
            }

            const name = this.searchName.trim();
            const filters = {
                userId: this.userId,
                states: this.states,
                name: this.name,
                surname: this.surname,
                email: this.email,
                contractType: this.commitment,
                includeCountries: this.includeCountries,
                excludeCountries: this.excludeCountries,
                fromTimezone: this.fromTimezone,
                toTimezone: this.toTimezone,
                mainSkillsIncludeAll: this.mainSkillsIncludeAll,
                mainSkills: this.mainSkills,
                supportSkillsIncludeAll: this.supportSkillsIncludeAll,
                supportSkills: this.supportSkills,
                anySkills: this.anySkills,
                salaryFrom: this.salaryFrom,
                salaryTo: this.salaryTo,
                hasJobAppliedForSet: this.hasJobAppliedForSet,
                availabilities: this.availabilities,
                passedQuiz: this.passedQuiz,
                passedTechnicalInterview: this.passedTechnicalInterview,
                passedFinalInterview: this.passedFinalInterview,
                minExperienceYears: this.minExperienceYears,
                developerRolesIncludeAll: this.developerRolesIncludeAll,
                developerRoles: this.developerRoleFilter,
                registrationFromDate: this.registrationFromDate,
                registrationToDate: this.registrationToDate,
                appliedForJobId: this.jobId,
                applicationFromDate: this.applicationFromDate,
                applicationToDate: this.applicationToDate,
                excludeCustomerIoCampaigns: this.excludeCustomerIoCampaigns,
                hasManagementExperience: this.hasManagementExperience,
                techExperiences: this.techExperiences,
                techExperienceIncludeAll: this.techExperienceIncludeAll,
                excludeAdminTags: this.adminTags,
            };

            if (this.savePrivateSearch) {
                if (this.adminSearches.find(a => a.data.name === name)) {
                    this.$buefy.dialog.alert(`Private search filter with name '${name}' already exists!`);
                    return;
                }

                await ADMIN_SERVICE.storeAdminData({
                    type: ADMIN_DATA_TYPE.TALENT_SEARCH_FILTER,
                    data: {
                        name: this.searchName,
                        filters,
                    },
                });
                this.adminSearches = await ADMIN_SERVICE.getAdminData(ADMIN_DATA_TYPE.TALENT_SEARCH_FILTER);
            } else {
                if (this.globalSearches.find(a => a.data.name === name)) {
                    this.$buefy.dialog.alert(`Global search filter with name '${name}' already exists!`);
                    return;
                }
                await ADMIN_SERVICE.storeGlobalData({
                    type: ADMIN_DATA_TYPE.TALENT_SEARCH_FILTER,
                    data: {
                        name: this.searchName,
                        filters,
                    },
                });
                this.globalSearches = await ADMIN_SERVICE.getGlobalData(ADMIN_DATA_TYPE.TALENT_SEARCH_FILTER);
            }
            this.searchName = null;
        } catch (err) {
            console.error('Error during search!', err);
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while saving search! ${err.message}`,
                type: 'is-danger',
            });
        }
    }

    async deleteSearch() {
        let search: IApiAdminData;
        if (this.loadPrivateSearch) {
            search = ArrayHelpers.single(this.adminSearches, (a) => a.id == this.selectedFilterId);
        } else {
            search = ArrayHelpers.single(this.globalSearches, (a) => a.id == this.selectedFilterId);
        }

        await this.safeExecuteWithConfirmation(async () => {
                if (this.loadPrivateSearch) {
                    await ADMIN_SERVICE.deleteAdminData(search.id);
                    this.adminSearches = this.adminSearches.filter(as => as.id !== search.id);
                } else {
                    await ADMIN_SERVICE.deleteGlobalData(search.id);
                    this.globalSearches = this.globalSearches.filter(as => as.id !== search.id);
                }
            },
            `Delete Search Filter '${search.data.name}'!`,
            `Are you sure you want to delete ${this.loadPrivateSearch ? 'your privately available' : 'globally available'} search filter ${search.data.name}?`,
            `Could not delete search filter ${search.data.name}!`,
            'is-danger',
        );
    }

    applySearch() {
        let search: any;
        if (this.loadPrivateSearch) {
            search = ArrayHelpers.single(this.adminSearches, (a) => a.id == this.selectedFilterId).data.filters;
        } else {
            search = ArrayHelpers.single(this.globalSearches, (a) => a.id == this.selectedFilterId).data.filters;
        }
        this.userId = search.userId;
        this.states = search.states;
        this.name = search.name;
        this.surname = search.surname;
        this.email = search.email;
        this.commitment = search.contractType;
        this.includeCountries = search.includeCountries;
        this.excludeCountries = search.excludeCountries;
        this.fromTimezone = search.fromTimezone;
        this.toTimezone = search.toTimezone;
        this.mainSkillsIncludeAll = search.mainSkillsIncludeAll;
        this.mainSkills = search.mainSkills;
        this.supportSkillsIncludeAll = search.supportSkillsIncludeAll;
        this.supportSkills = search.supportSkills;
        this.anySkills = search.anySkills;
        this.salaryFrom = search.salaryFrom;
        this.salaryTo = search.salaryTo;
        this.hasJobAppliedForSet = search.hasJobAppliedForSet;
        this.availabilities = search.availabilities;
        this.passedQuiz = search.passedQuiz;
        this.passedTechnicalInterview = search.passedTechnicalInterview;
        this.passedFinalInterview = search.passedFinalInterview;
        this.minExperienceYears = search.minExperienceYears;
        this.developerRolesIncludeAll = search.developerRolesIncludeAll;
        this.developerRoleFilter = search.developerRoles;
        this.registrationFromDate = search.registrationFromDate;
        this.registrationToDate = search.registrationToDate;
        this.jobId = search.appliedForJobId;
        this.applicationFromDate = search.applicationFromDate;
        this.applicationToDate = search.applicationToDate;
        this.excludeCustomerIoCampaigns = search.excludeCustomerIoCampaigns;
        this.hasManagementExperience = search.hasManagementExperience;
        this.techExperiences = search.techExperiences;
        this.techExperienceIncludeAll = search.techExperienceIncludeAll;
        this.adminTags = search.excludeAdminTags;
    }
}
