











import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiAdminUser } from '@/admin/services/api/AdminService.dtos';
import AdminAccountCard from '@/admin/components/AdminAccountCard.vue';

@Component({
    components: {
        AdminAccountCard,
    }
})
export default class AdminAccountModal extends Vue {
    @Prop({})
    profile!: IApiAdminUser;
}
