






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SocialLink extends Vue {
    @Prop()
    readonly type!: string;

    @Prop()
    readonly link!: string;

    get formatLink(): string {
        if (this.link.startsWith('http')) {
            return this.link;
        }

        return `//${this.link}`;
    }

    async copyToClipboard(property: string, data: string) {
        await navigator.clipboard.writeText(data);
        this.$buefy.toast.open({
            type: 'is-success',
            message: `Copied user's ${property} url to clipboard!`,
        });
    }
}
