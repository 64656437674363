import { ApiServiceBase } from '@/shared/service/ServiceBase';
import {
  IApiCreateQuizCategoryQuestionRequest,
  IApiCreateQuizCategoryRequest,
  IApiCreateQuizCategoryScoreLevelRequest,
  IApiCreateQuizRequest,
  IApiQuiz,
  IApiQuizCategory,
  IApiQuizCategoryQuestion,
  IApiQuizCategoryScoreLevel,
} from '@/admin/services/api/AdminQuizService.dtos';
import { CATEGORY_STATE, QUESTION_STATE, QUIZ_STATE, UUID } from '@/shared/data/constants';

export class AdminQuizService extends ApiServiceBase {
  public constructor(baseApiUrl?: string) {
    super(baseApiUrl || '/api/user-service/admin/quizzes');
  }

  public async getAllQuizzes(): Promise<IApiQuiz[]> {
    return this.safeGetJson('/');
  }

  public async getQuiz(quizId: UUID): Promise<IApiQuiz> {
    return this.safeGetJson(`/${quizId}`);
  }

  public async createQuiz(req: IApiCreateQuizRequest): Promise<IApiQuiz> {
    return this.safePostJson('/', req);
  }

  public async updateQuiz(quizId: UUID, req: IApiCreateQuizRequest): Promise<IApiQuiz> {
    return this.safePatchJson(`/${quizId}`, req);
  }

  public async getAllQuizCategories(quizId: UUID): Promise<IApiQuizCategory[]> {
    return this.safeGetJson(`/${quizId}/categories`);
  }

  public async getQuizCategory(quizId: UUID, categoryId: UUID): Promise<IApiQuizCategory> {
    return this.safeGetJson(`/${quizId}/categories/${categoryId}`);
  }

  public async createQuizCategory(quizId: UUID, req: IApiCreateQuizCategoryRequest): Promise<IApiQuizCategory> {
    return this.safePostJson(`/${quizId}/categories`, req);
  }

  public async updateQuizCategory(quizId: UUID, categoryId: UUID, req: IApiCreateQuizCategoryRequest): Promise<IApiQuizCategory> {
    return this.safePatchJson(`/${quizId}/categories/${categoryId}`, req);
  }

  public async getAllQuizCategoryScoreLevels(quizId: UUID, categoryId: UUID): Promise<IApiQuizCategoryScoreLevel[]> {
    return this.safeGetJson(`/${quizId}/categories/${categoryId}/scoreLevels`);
  }

  public async createQuizCategoryScoreLevels(quizId: UUID, categoryId: UUID, levels: IApiCreateQuizCategoryScoreLevelRequest[]): Promise<void> {
    await this.safePostResponse(`/${quizId}/categories/${categoryId}/scoreLevels`, levels);
  }

  public async getAllQuizCategoryQuestions(quizId: UUID, categoryId: UUID): Promise<IApiQuizCategoryQuestion[]> {
    return this.safeGetJson(`/${quizId}/categories/${categoryId}/questions`);
  }

  public async createQuizCategoryQuestion(quizId: UUID, categoryId: UUID, req: IApiCreateQuizCategoryQuestionRequest): Promise<IApiQuizCategoryQuestion> {
    return this.safePostJson(`/${quizId}/categories/${categoryId}/questions`, req);
  }

  public async updateQuizCategoryQuestion(quizId: UUID, categoryId: UUID, questionId: UUID, req: IApiCreateQuizCategoryQuestionRequest): Promise<IApiQuizCategoryQuestion> {
    return this.safePatchJson(`/${quizId}/categories/${categoryId}/questions/${questionId}`, req);
  }

  public async updateQuizState(quiz: IApiQuiz, state: QUIZ_STATE): Promise<IApiQuiz> {
    const { id, ...req } = Object.assign({}, quiz, { state });

    return this.safePatchJson(`/${id}`, req);
  }

  public async updateQuizCategoryQuestionState(question: IApiQuizCategoryQuestion, state: QUESTION_STATE): Promise<IApiQuizCategoryQuestion> {
    const { quizId, categoryId, id, ...req } = Object.assign({}, question, { state });

    return this.safePatchJson(`/${quizId}/categories/${categoryId}/questions/${id}`, req);
  }

  public async updateQuizCategoryState(category: IApiQuizCategory, state: CATEGORY_STATE): Promise<IApiQuizCategory> {
    const { quizId, id, ...req } = Object.assign({}, category, { state });

    return this.safePatchJson(`/${quizId}/categories/${id}`, req);
  }
}

export const ADMIN_QUIZ_SERVICE = new AdminQuizService();
