













import { Component, Prop } from 'vue-property-decorator';
import { IApiAdminNote, IApiAdminUser } from '@/admin/services/api/AdminService.dtos';
import { Getter } from 'vuex-class';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { UUID } from '@/shared/data/constants';
import ComponentBase from '@/admin/components/ComponentBase';
import { ADMIN_USER_NOTE_SERVICE } from '@/admin/services/api/AdminUserNoteService';
import AdminNoteEditor from '@/admin/views/dashboard/views/modals/AdminNoteEditor.vue';

@Component({})
export default class AdminNote extends ComponentBase {
    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;

    @Prop()
    note!: IApiAdminNote;

    @Prop()
    userId!: UUID;

    async removeNote() {
        await this.safeExecuteWithConfirmation(async () => {
                    await ADMIN_USER_NOTE_SERVICE.deleteNote(this.note.id);
                    await this.$emit('note-changed');
                }, 'Removing Note',
                'Are you sure?',
                'Failed to remove this note! Please check logs/refresh page!',
                'is-warning');
    }

    async editNote() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: AdminNoteEditor,
                props: {
                    userId: this.userId,
                    existingNote: this.note,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });

        await this.$emit('note-changed');
    }
}
