
























































































































































































import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { IApiAdminUser, IApiBasicCompanyData } from '@/admin/services/api/AdminService.dtos';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { USER_STATE } from '@/shared/data/constants';
import { USER_STATE_LABEL } from '@/shared/data/default-labels';
import { Codetables, CodetableService, CompanyCategory, CompanySize } from '@/shared/service/CodetableService';
import { ADMIN_PERMISSIONS, checkPermission } from '@/admin/services/PermissionService';
import ComponentBase from '@/admin/components/ComponentBase';
import AdminNoteEditor from '@/admin/views/dashboard/views/modals/AdminNoteEditor.vue';
import AddAdminTag from '@/admin/views/dashboard/views/modals/AddAdminTag.vue';
import AdminTag from '@/admin/views/dashboard/views/user-search/AdminTag.vue';
import AdminNote from '@/admin/views/dashboard/views/user-search/AdminNote.vue';
import UserCompanyEdit from '@/admin/views/dashboard/views/company-search/UserCompanyEdit.vue';
import { ADMIN_ROUTES } from '@/admin/router';
import { ISelectableItem } from '@/shared/data/types';

@Component({
    components: {
        AdminTag,
        AdminNote,
    },
})
export default class CompanyCard extends ComponentBase {
    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;

    USER_STATE = USER_STATE;
    USER_STATE_LABEL = USER_STATE_LABEL;

    @Prop({})
    companyData!: ISelectableItem<IApiBasicCompanyData>;

    @Prop({ default: false })
    selectable!: boolean;

    data: ISelectableItem<IApiBasicCompanyData> | null = null;

    companySizes: CompanySize[] = [];
    companyCategories: CompanyCategory[] = [];

    activeTab = null;

    checkPermission = checkPermission;
    ADMIN_PERMISSIONS = ADMIN_PERMISSIONS;

    get hasActions(): boolean {
        return this.canDelete || this.canApprove;
    }

    get canDelete(): boolean {
        return this.company.state !== USER_STATE.DELETED && checkPermission(this.profile, ADMIN_PERMISSIONS.COMPANY_DELETE);
    }

    get canApprove(): boolean {
        return this.company.state === USER_STATE.IN_APPROVAL && checkPermission(this.profile, ADMIN_PERMISSIONS.COMPANY_APPROVE);
    }

    get canEdit(): boolean {
        return checkPermission(this.profile, ADMIN_PERMISSIONS.COMPANY_EDIT);
    }

    get canCreateJob(): boolean {
        return checkPermission(this.profile, ADMIN_PERMISSIONS.JOB_EDITOR);
    }

    get notesAndTagsLabel(): string {
        return `Notes (${this.company.adminNotes.length}) & Tags (${this.company.adminTags.length})`;
    }

    get company(): IApiBasicCompanyData {
        if (this.data) {
            return this.data.item;
        }

        throw new Error('Company data not set!');
    }

    get mailto(): string {
        return `mailto:${this.company.email}`;
    }

    get category(): string | null {
        if (this.company.properties.COMPANY_CATEGORY) {
            const filtered = this.companyCategories.find(cs => cs.name === this.company.properties.COMPANY_CATEGORY);
            if (filtered) {
                return filtered.description;
            }
        }

        return null;
    }

    get size(): string | null {
        if (this.company.properties.COMPANY_SIZE) {
            const filtered = this.companySizes.find(cs => cs.name === this.company.properties.COMPANY_SIZE);
            if (filtered) {
                return filtered.displayName;
            }
        }

        return null;
    }

    async mounted() {
        this.data = this.companyData;

        const results = await CodetableService.getCodetables(Codetables.COMPANY_CATEGORIES, Codetables.COMPANY_SIZES);
        this.companyCategories = results.company_categories as CompanyCategory[];
        this.companySizes = results.company_sizes as CompanySize[];
    }

    async deleteUser() {
        await this.safeExecuteWithConfirmation(async () => {
                    await ADMIN_SERVICE.deleteCompany(this.company.userId);
                    await this.$emit('deleted');
                }, 'Deleting Company',
                `Are you sure you want to <b>delete</b> company ${this.company.properties.COMPANY_NAME}?`,
                'Failed to delete company! Please check logs/refresh page!',
                'is-danger');
    }

    async approve() {
        await this.safeExecuteWithConfirmation(async () => {
                    await ADMIN_SERVICE.approveCompany(this.company.userId);
                    await this.refreshUser();
                }, 'Company Approval',
                `Are you sure you want to <b>approve</b> company ${this.company.properties.COMPANY_NAME}?`,
                'Failed to approve company! Please check logs/refresh page!',
                'is-success');
    }

    async createJobPosition() {
        await this.$router.push({
            name: ADMIN_ROUTES.CREATE_JOB, query: {
                companyUserId: this.company.userId,
            },
        });
    }

    async editCompany() {
        await new Promise<void>((resolve => {
            this.$buefy.modal.open({
                component: UserCompanyEdit,
                props: {
                    company: this.company,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        }));

        await this.refreshUser();
    }

    async refreshUser() {
        const selected = this.data?.selected;
        const item = await ADMIN_SERVICE.getCompanyUserData(this.company.userId);
        this.data = {
            item,
            selected: selected ?? false,
        };
    }

    async addNewTag() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: AddAdminTag,
                props: {
                    user: this.company,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });

        await this.refreshUser();
    }

    async addNewNote() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: AdminNoteEditor,
                props: {
                    userId: this.company.userId,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });

        await this.refreshUser();
    }

    async copyToClipboard(property: string, data: string) {
        await navigator.clipboard.writeText(data);
        this.$buefy.toast.open({
            type: 'is-success',
            message: `Copied ${property} ${data} to clipboard!`,
        });
    }

    async openCompanyJobs() {
        if (this.company.userId) {
            await this.$router.push({
                name: ADMIN_ROUTES.JOBS,
                query: {
                    companyUserId: this.company.userId,
                },
            });
        }
    }

    async companySelected() {
        this.$emit('selected', this.company);
    }
}
