
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CATEGORY_STATE } from '@/shared/data/constants';
import { IApiQuiz, IApiQuizCategory } from '@/admin/services/api/AdminQuizService.dtos';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

@Component({
    components: {
        RichTextEditor,
    }
})
export default class CreateNewQuizCategoryModal extends Vue {
    buttonLoading = false;

    @Prop()
    readonly quiz!: IApiQuiz;

    @Prop({
        required: false,
        default: () => null,
    })
    readonly category!: IApiQuizCategory | null;

    name: string | null = null;
    description: string | null = null;
    sortOrder: number | null = null;
    nrQuestions = 5;

    async mounted() {
        if (this.category) {
            this.name = this.category.name;
            this.description = this.category.description;
            this.sortOrder = this.category.sortOrder;
            this.nrQuestions = this.category.nrQuestions;
        }
    }

    closeModal(refresh: boolean) {
        this.$emit('close', refresh);
    }

    async save() {
        this.buttonLoading = true;
        try {
            if (this.category) {
                await ADMIN_QUIZ_SERVICE.updateQuizCategory(this.quiz.id, this.category.id, {
                    name: this.name as string,
                    description: this.description as string,
                    sortOrder: this.sortOrder as number,
                    nrQuestions: this.nrQuestions,
                    state: this.category.state,
                });
            } else {
                await ADMIN_QUIZ_SERVICE.createQuizCategory(this.quiz.id, {
                    name: this.name as string,
                    description: this.description as string,
                    sortOrder: this.sortOrder as number,
                    nrQuestions: this.nrQuestions,
                    state: CATEGORY_STATE.DRAFT,
                });
            }
            this.closeModal(true);
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while creating quiz category! ${e.message}`,
                type: 'is-danger',
            });
        }
        this.buttonLoading = false;
    }
}
