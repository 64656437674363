import { render, staticRenderFns } from "./TalentSummary.vue?vue&type=template&id=6b604dcb&scoped=true&"
import script from "./TalentSummary.vue?vue&type=script&lang=ts&"
export * from "./TalentSummary.vue?vue&type=script&lang=ts&"
import style0 from "./TalentSummary.vue?vue&type=style&index=0&id=6b604dcb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b604dcb",
  null
  
)

export default component.exports