export enum JobBenefits {
  FLEXIBLE_HOURS = 'FLEXIBLE_HOURS',
  PERFORMANCE_BONUS = 'PERFORMANCE_BONUS',
  RETIREMENT_PLAN = 'RETIREMENT_PLAN',
  CHILDCARE_ASSISTANCE = 'CHILDCARE_ASSISTANCE',
  HEALTH_COVERAGE = 'HEALTH_COVERAGE',
  COMPUTER = 'COMPUTER',
  COMPANY_RETREATS = 'COMPANY_RETREATS',
  DIGITAL_LIBRARY = 'DIGITAL_LIBRARY',
  EDUCATION_STIPEND = 'EDUCATION_STIPEND',
  EQUITY = 'EQUITY',
  PAID_SICK_DAYS = 'PAID_SICK_DAYS',
  MATERNITY_LEAVE = 'MATERNITY_LEAVE',
  WELLNESS_BENEFITS = 'WELLNESS_BENEFITS',
  PAID_TIME_OFF = 'PAID_TIME_OFF',
  REMOTE_OFFICE_BUDGET = 'REMOTE_OFFICE_BUDGET',
  INTERNET_ALLOWANCE = 'INTERNET_ALLOWANCE',
  VACATION_BIRTHDAY = 'VACATION_BIRTHDAY',
  FULLY_REMOTE = 'FULLY_REMOTE',
}

export const JobBenefitsData: Record<JobBenefits, {
  title: string,
}> = {
  [JobBenefits.FLEXIBLE_HOURS]: {
    title: 'Flexible hours',
  },
  [JobBenefits.PERFORMANCE_BONUS]: {
    title: 'Performance bonus',
  },
  [JobBenefits.RETIREMENT_PLAN]: {
    title: 'Retirement Plan',
  },
  [JobBenefits.CHILDCARE_ASSISTANCE]: {
    title: 'Childcare Assistance',
  },
  [JobBenefits.HEALTH_COVERAGE]: {
    title: 'Health coverage',
  },
  [JobBenefits.COMPUTER]: {
    title: 'Computer',
  },
  [JobBenefits.COMPANY_RETREATS]: {
    title: 'Company retreats',
  },
  [JobBenefits.DIGITAL_LIBRARY]: {
    title: 'Digital Library',
  },
  [JobBenefits.EDUCATION_STIPEND]: {
    title: 'Education Stipend',
  },
  [JobBenefits.EQUITY]: {
    title: 'Equity',
  },
  [JobBenefits.PAID_SICK_DAYS]: {
    title: 'Paid Sick Days',
  },
  [JobBenefits.MATERNITY_LEAVE]: {
    title: 'Paid Maternity/Paternity Leave',
  },
  [JobBenefits.WELLNESS_BENEFITS]: {
    title: 'Wellness benefits',
  },
  [JobBenefits.PAID_TIME_OFF]: {
    title: 'Paid time off',
  },
  [JobBenefits.REMOTE_OFFICE_BUDGET]: {
    title: 'Remote Office Budget',
  },
  [JobBenefits.INTERNET_ALLOWANCE]: {
    title: 'Internet allowance',
  },
  [JobBenefits.VACATION_BIRTHDAY]: {
    title: 'Vacation on birthday',
  },
  [JobBenefits.FULLY_REMOTE]: {
    title: 'Fully remote',
  },
}