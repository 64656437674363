
















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ADMIN_ROUTES } from '@/admin/router';
import { IApiJobData, IApiJobModifyRequest, IApiJobSectionData } from '@/admin/services/api/AdminJobService.dtos';
import { ADMIN_JOB_SERVICE } from '@/admin/services/api/AdminJobService';
import { JOB_STATE, UUID } from '@/shared/data/constants';
import {
    Codetables,
    CodetableService,
    CompanySize,
    Country,
    Currency,
    DeveloperRole,
    Skill,
    TimeZone,
} from '@/shared/service/CodetableService';
import { JobBenefits, JobBenefitsData } from '@/admin/views/dashboard/views/jobs/Jobs.dtos';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { IApiBasicCompanyData } from '@/admin/services/api/AdminService.dtos';
import TagInput from '@/admin/components/inputs/TagInput.vue';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

interface JobsForm {
    positionTitle: string;
    skills: { name: string, level: string }[];
    tech: string[];
    role: string;
    quantity: number | '';
    countries: [],
    salaryCurrency: string;
    salaryFrom: number | null;
    salaryTo: number | null;
    salaryText: string | null;
    salaryAsText: boolean;
    salaryEquity: boolean;
    salaryEquityFrom: number | '';
    salaryEquityTo: number | '';
    salaryEquityUnknown: boolean;
    timezoneFrom: string | null;
    timezoneTo: string | null;
    worldwide: boolean;
    textSections: IApiJobSectionData[];
    conditionsHtml: string;
    goodToHave: string;
    benefits: string[];
    needsOptimizations: boolean;
    contractType: string;
}

interface JobsAddForm {
    state: JOB_STATE,
    featured: boolean,
    sortOrder: number,
}

@Component({
    components: {
        TagInput,
        RichTextEditor,
    },
})
export default class JobsCreate extends Vue {

    JobBenefitsData = JobBenefitsData;

    timezones: TimeZone[] = [];
    allSkills: string[] = [];
    companySizes: CompanySize[] = [];
    roles: DeveloperRole[] = [];
    currency: Currency[] = [];
    countries: string[] = [];
    userCompany: IApiBasicCompanyData | null = null;

    salaryRangeDisabled = false;

    form: JobsForm = {
        positionTitle: '',
        skills: [],
        tech: [],
        countries: [],
        role: '',
        quantity: '',
        salaryCurrency: '',
        salaryFrom: null,
        salaryTo: null,
        salaryText: null,
        salaryAsText: false,
        salaryEquity: false,
        salaryEquityFrom: '',
        salaryEquityTo: '',
        salaryEquityUnknown: false,
        timezoneFrom: null,
        timezoneTo: null,
        worldwide: false,
        conditionsHtml: '',
        goodToHave: '',
        benefits: [],
        needsOptimizations: false,
        textSections: [
            {
                order: 1,
                name: 'Job Functions',
                htmlText: '',
            },
            {
                order: 2,
                name: 'Qualifications And Requirements',
                htmlText: '',
            },
            {
                order: 3,
                name: 'Desirable Skills',
                htmlText: '',
            },
        ],
        contractType: '',
    };

    addForm: JobsAddForm = {
        state: JOB_STATE.ACTIVE,
        featured: false,
        sortOrder: 0,
    };

    skillsForm: {
        search: string,
        skill: string | null,
        level: string,
    } = {
        search: '',
        skill: null,
        level: '',
    };

    search = {
        company: '',
    };
    filteredSkills: string[] = [];

    get JobStates() {
        return Object.values(JOB_STATE);
    }

    get sortedSections() {
        return this.form.textSections.sort((a, b) => a.order - b.order);
    }

    get benefits() {
        return Object.values(JobBenefits);
    }

    skillNames(text: string) {
        this.filteredSkills = this.allSkills.filter((skill) => {
            return skill.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });
    }

    selectSkill(skill: string) {
        if (skill) {
            this.skillsForm.skill = skill;
        } else {
            this.skillsForm.skill = null;
        }
    }

    moveSectionDown(section: IApiJobSectionData) {
        const nextSection = this.form.textSections.find(t => t.order === (section.order + 1));

        if (nextSection) {
            section.order = nextSection.order;
            nextSection.order = section.order - 1;
        }
    }

    moveSectionUp(section: IApiJobSectionData) {
        const previousSection = this.form.textSections.find(t => t.order === (section.order - 1));

        if (previousSection) {
            section.order = previousSection.order;
            previousSection.order = section.order + 1;
        }
    }

    removeSection(orderNumber: number) {
        this.form.textSections = this.form.textSections.filter(t => t.order !== orderNumber);
        this.reorderSections();
    }

    addNewSection() {
        this.reorderSections();

        let latestOrderNumber = 0;

        if (this.form.textSections.length > 0) {
            latestOrderNumber = this.form.textSections[this.form.textSections.length - 1].order;
        }

        this.form.textSections.push({
            name: '',
            order: latestOrderNumber + 1,
            htmlText: '',
        });
    }

    reorderSections() {
        let i = 1;
        for (const section of this.form.textSections) {
            section.order = i++;
        }
    }

    addSkill() {
        if (this.skillsForm.skill && this.skillsForm.level && this.form.skills.find(s => s.name === this.skillsForm.skill) === undefined) {
            this.form.skills.push({
                name: this.skillsForm.skill,
                level: this.skillsForm.level,
            });
            this.skillsForm.skill = null;
            this.skillsForm.level = '';
            this.skillsForm.search = '';
        }
    }

    removeSkill(skill: string) {
        this.form.skills = this.form.skills.filter(s => s.name !== skill);
    }

    worldwideChanged() {
        this.form.timezoneFrom = null;
        this.form.timezoneTo = null;
        this.form.countries = [];
    }

    salaryAsTextChanged(val: boolean) {
        if (val) {
            this.form.salaryFrom = null;
            this.form.salaryTo = null;
            this.salaryRangeDisabled = true;
        } else {
            this.form.salaryText = null;
            this.salaryRangeDisabled = false;
        }
    }

    async save() {
        if (!this.form.worldwide && (!this.form.timezoneTo || !this.form.timezoneFrom)) {
            this.$buefy.dialog.alert({
                title: 'Incorrect values!',
                type: 'is-danger',
                message: 'If worldwide is not checked you have to select timezone range!',
            });
            return;
        }

        if (this.form.salaryAsText && (!this.form.salaryText || this.form.salaryText.length === 0)) {
            this.$buefy.dialog.alert({
                title: 'Incorrect values!',
                type: 'is-danger',
                message: 'If salary as text is checked you have to enter a text value!',
            });
            return;
        }

        if (!this.form.salaryAsText && (!this.form.salaryFrom || !this.form.salaryTo)) {
            this.$buefy.dialog.alert({
                title: 'Incorrect values!',
                type: 'is-danger',
                message: 'If salary as text is not checked you have to enter salary range!',
            });
            return;
        }

        try {
            const data: IApiJobModifyRequest = {
                dataVersion: 1,
                data: this.form as IApiJobData,
                state: this.addForm.state,
                featured: this.addForm.featured,
                sortOrder: this.addForm.sortOrder,
                companyUserId: this.userCompany?.userId as UUID,
            };
            await ADMIN_JOB_SERVICE.createJob(data);

            await this.$router.push({ name: ADMIN_ROUTES.JOBS });
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error while saving!',
                message: 'Error while creating job position! Please check browser logs!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        }
    }

    async mounted() {
        const codetables = await CodetableService.getCodetables(Codetables.TIMEZONES, Codetables.SKILLS, Codetables.COMPANY_SIZES, Codetables.DEVELOPER_ROLES, Codetables.CURRENCIES, Codetables.COUNTRIES);
        this.timezones = codetables.timezones as TimeZone[];
        this.allSkills = (codetables.skills as Skill[]).map((s) => s.name);
        this.companySizes = codetables.skills as CompanySize[];
        this.roles = (codetables.developer_roles as DeveloperRole[]);
        this.currency = (codetables.currencies as Currency[]);
        this.countries = (codetables.countries as Country[]).map(c => c.name);

        this.userCompany = await ADMIN_SERVICE.getCompanyUserData(this.$route.query.companyUserId as UUID);
        // must be in sync with company frontend
        this.form.textSections = [
            {
                order: 0,
                name: 'About Job Position',
                htmlText: '',
            },
            {
                order: 1,
                name: 'This is a great opportunity because',
                htmlText: '',
            },
            {
                order: 2,
                name: 'Projects you will work on',
                htmlText: '',
            },
        ];
    }
}
