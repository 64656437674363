

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiUserVettingRecord } from '@/admin/services/api/AdminService.dtos';
import dayjs from 'dayjs';

@Component({})
export default class Quiz extends Vue {
    @Prop()
    readonly record!: IApiUserVettingRecord;

    get completedDateLabel(): string {
        if(this.record.updatedAt){
            return dayjs().format('ddd MMM D, h:mm A UTCZ');
        }
        return '/'
    }

    get quizLabel(): string {
        if (this.record.results && this.record.results.name) {
            return `${this.record.results.name} Quiz`;
        }
        return 'Quiz';
    }

    get passedLabel(): string {
        if (this.record.success === true) {
            return 'Passed';
        } else {
            return 'Failed';
        }
    }
}
