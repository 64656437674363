var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Editing company "+_vm._s(_vm.company.properties.COMPANY_NAME)+" details ")])]),(_vm.properties)?_c('section',{staticClass:"modal-card-body"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('validation-provider',{attrs:{"name":"Company Logo","rules":{image: true, size: 2000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('b-field',{class:{ 'is-danger': errors.length > 0 },attrs:{"label":"Company Logo","type":{ 'is-danger': errors.length > 0 },"message":{ 'Company logo has to be less than 2MB in size!': errors.length > 0 }}},[_c('div',{staticClass:"profile-picture-container",staticStyle:{"cursor":"pointer"},on:{"click":_vm.selectFile}},[(_vm.logoUrl === null)?_c('figure',{staticClass:"image is-empty"},[_c('div',{staticClass:"profile-picture-placeholder"},[_c('b-icon',{attrs:{"icon":"camera","size":"is-medium","type":"is-grey"}})],1)]):_c('figure',{staticClass:"image has-image-centered is-128x128"},[_c('img',{attrs:{"src":_vm.logoUrl,"alt":"Company logo"}})]),_c('input',{ref:"file",attrs:{"type":"file","name":"file","hidden":""},on:{"change":function($event){return _vm.onFileChange(validate, $event)}}})])])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Company Name","rules":"required|min:2|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Company Name","type":{ 'is-danger': errors.length > 0 },"message":{ 'Company name is invalid (required, minimum 2 characters and max 100!)': errors.length > 0 }}},[_c('b-input',{model:{value:(_vm.properties.COMPANY_NAME),callback:function ($$v) {_vm.$set(_vm.properties, "COMPANY_NAME", $$v)},expression:"properties.COMPANY_NAME"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Company Tag Line","rules":"required|min:15|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Company Tag Line","type":{ 'is-danger': errors.length > 0 },"message":{ 'Company tag line is invalid (required, minimum 15 characters and max 100!)': errors.length > 0 }}},[_c('rich-text-editor',{model:{value:(_vm.properties.COMPANY_TAGLINE),callback:function ($$v) {_vm.$set(_vm.properties, "COMPANY_TAGLINE", $$v)},expression:"properties.COMPANY_TAGLINE"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Company Description","rules":"required|min:30|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Company Description","type":{ 'is-danger': errors.length > 0 },"message":{ 'Company description is invalid (required, minimum 30 characters and max 200!)': errors.length > 0 }}},[_c('rich-text-editor',{attrs:{"show-menu":"true","resizable":"true"},model:{value:(_vm.properties.COMPANY_DESCRIPTION),callback:function ($$v) {_vm.$set(_vm.properties, "COMPANY_DESCRIPTION", $$v)},expression:"properties.COMPANY_DESCRIPTION"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column pt-13"},[_c('div',{staticClass:"container has-text-centered"},[_c('b-button',{staticClass:"has-margin-5",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"has-margin-5",attrs:{"native-type":"submit","type":"is-primary"}},[_vm._v(" Save ")])],1)])])])]}}],null,false,1706664139)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }