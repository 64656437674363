import {
    DomainExperienceLevel,
    EnglishLevel,
    InterviewPositionRecommendationLevel,
    ProblemSolvingLevel,
    ProductivityLevel,
    TaskResultLevel,
} from '@/shared/service/CodetableService';
import {
    DOMAIN_EXPERIENCE_LEVEL,
    ENGLISH_LEVEL,
    INTERVIEW_POSITION_RECOMMENDATION_LEVEL,
    PROBLEM_SOLVING_LEVEL,
    PRODUCTIVITY_LEVEL,
    TASK_RESULT_LEVEL,
} from '@/shared/data/constants';

export const MAX_COMMUNICATION_SCORE = 6;
export const scoreCommunication = (level: EnglishLevel): number => {
    switch (level.name) {
        case ENGLISH_LEVEL.A1:
            return 1;
        case ENGLISH_LEVEL.A2:
            return 2;
        case ENGLISH_LEVEL.B1:
            return 3;
        case ENGLISH_LEVEL.B2:
            return 4;
        case ENGLISH_LEVEL.C1:
            return 5;
        case ENGLISH_LEVEL.C2:
            return 6;
        default:
            throw new Error(`Communication score for level ${level.name} is not calculable!`);
    }
};

export const MAX_DOMAIN_EXPERIENCE_SCORE = 5;
export const scoreDomainExperience = (level: DomainExperienceLevel): number => {
    switch (level.name) {
        case DOMAIN_EXPERIENCE_LEVEL.BEGINNER:
            return 1;
        case DOMAIN_EXPERIENCE_LEVEL.ADVANCED_BEGINNER:
            return 2;
        case DOMAIN_EXPERIENCE_LEVEL.COMPETENT:
            return 3;
        case DOMAIN_EXPERIENCE_LEVEL.ADVANCED:
            return 4;
        case DOMAIN_EXPERIENCE_LEVEL.EXPERT:
            return 5;
        default:
            throw new Error(`Domain experience score for level ${level.name} is not calculable!`);
    }
};

export const MAX_PROBLEM_SOLVING_SCORE = 4;
export const scoreProblemSolving = (level: ProblemSolvingLevel): number => {
    switch (level.name) {
        case PROBLEM_SOLVING_LEVEL.NOT_APPLICABLE:
            return 0;
        case PROBLEM_SOLVING_LEVEL.BEGINNER:
            return 1;
        case PROBLEM_SOLVING_LEVEL.INTERMEDIATE:
            return 2;
        case PROBLEM_SOLVING_LEVEL.ADVANCED:
            return 3;
        case PROBLEM_SOLVING_LEVEL.MASTER:
            return 4;
        default:
            throw new Error(`Problem solving score for level ${level.name} is not calculable!`);
    }
};

export const MAX_PRODUCTIVITY_SCORE = 5;
export const scoreProductivity = (level: ProductivityLevel): number => {
    switch (level.name) {
        case PRODUCTIVITY_LEVEL.VERY_SLOW:
            return 1;
        case PRODUCTIVITY_LEVEL.SLOW:
            return 2;
        case PRODUCTIVITY_LEVEL.INTERMEDIATE:
            return 3;
        case PRODUCTIVITY_LEVEL.FAST:
            return 4;
        case PRODUCTIVITY_LEVEL.VERY_FAST:
            return 5;
        default:
            throw new Error(`Productivity score for level ${level.name} is not calculable!`);
    }
};

export const MAX_TASKS_SCORE = 5;
export const tasksScore = (levels: TaskResultLevel[]): number => {
    if (levels.length === 0) return 0;

    const total = levels.map(level => {
        switch (level.name) {
            case TASK_RESULT_LEVEL.VERY_POOR:
                return 1;
            case TASK_RESULT_LEVEL.POOR:
                return 2;
            case TASK_RESULT_LEVEL.BASIC:
                return 3;
            case TASK_RESULT_LEVEL.GOOD:
                return 4;
            case TASK_RESULT_LEVEL.EXCEPTIONAL:
                return 5;
            default:
                throw new Error(`Task Result score for level ${level.name} is not calculable!`);
        }
    }).reduce((sum, v) => sum + v, 0);

    return total / levels.length;
};

export const MAX_RECOMMENDATION_SCORE = (positions: number) => positions * 3;
export const recommendationScore = (levels: InterviewPositionRecommendationLevel[]): number => {
    if (levels.length === 0) return 0;

    return levels.map(level => {
        switch (level.name) {
            case INTERVIEW_POSITION_RECOMMENDATION_LEVEL.NOT_RECOMMENDED:
                return 0 as number;
            case INTERVIEW_POSITION_RECOMMENDATION_LEVEL.NOT_SURE:
                return 1;
            case INTERVIEW_POSITION_RECOMMENDATION_LEVEL.WOULD_RECOMMEND:
                return 2;
            case INTERVIEW_POSITION_RECOMMENDATION_LEVEL.HIGHLY_RECOMMEND:
                return 3;
            default:
                throw new Error(`Recommendation Score for level ${level.name} is not calculable!`);
        }
    }).reduce((sum, v) => sum + v, 0);
};
