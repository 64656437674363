import request, { ProgressEvent } from 'superagent';
import { COMMON_GETTERS, store } from '../store';

export const DEFAULT_TIMEOUT = 5000;

export interface IApiServiceGetOptions {
  authenticated: boolean;
  timeout?: number;
}

const DEFAULT_API_SERVICE_GET_OPTIONS: IApiServiceGetOptions = {
  authenticated: true,
  timeout: DEFAULT_TIMEOUT,
};

export interface IApiServicePostOptions {
  authenticated?: boolean;
  timeout?: number;
}

const DEFAULT_API_SERVICE_POST_OPTIONS: IApiServicePostOptions = {
  authenticated: true,
  timeout: DEFAULT_TIMEOUT,
};

export abstract class ServiceBase {
  protected static authRequest(req: request.SuperAgentRequest): request.SuperAgentRequest {
    const token = store.getters[COMMON_GETTERS.ID_TOKEN];
    if (!token) {
      throw new Error('User not logged in!');
    } else {
      return req.set('Authorization', `Bearer ${token}`);
    }
  }
}

export abstract class ApiServiceBase {
  protected idToken?: string;

  protected constructor(private baseApiUrl: string) {}

  public authenticate(idToken: string) {
    this.idToken = idToken;
  }

  protected authedRequest(req: request.SuperAgentRequest): request.SuperAgentRequest {
    if (!this.idToken) {
      throw new Error('User not logged in!');
    } else {
      return req.set('Authorization', `Bearer ${this.idToken}`);
    }
  }

  protected apiUrl(url: string): string {
    return `${this.baseApiUrl}${url}`;
  }

  protected async safeGetJson<T>(url: string, query?: any, options?: IApiServiceGetOptions): Promise<T> {
    const actualUrl = this.apiUrl(url);
    const actualOptions = options ? Object.assign({}, DEFAULT_API_SERVICE_GET_OPTIONS, options) : DEFAULT_API_SERVICE_GET_OPTIONS;

    try {
      let req = actualOptions.authenticated ? this.authedRequest(request.get(actualUrl)) : request.get(actualUrl);

      if (query) {
        req = req.query(query);
      }

      const result = await req.timeout(actualOptions.timeout as number);

      return result.body;
    } catch (err) {
      throw ApiServiceBase.handleError(`Error while executing GET @ '${actualUrl}'!`, err);
    }
  }

  protected async safePostResponse(url: string, data?: any, options?: IApiServicePostOptions): Promise<request.Response> {
    const actualUrl = this.apiUrl(url);
    const actualOptions = options ? Object.assign({}, DEFAULT_API_SERVICE_POST_OPTIONS, options) : DEFAULT_API_SERVICE_POST_OPTIONS;

    try {
      let req = actualOptions.authenticated ? this.authedRequest(request.post(actualUrl)) : request.post(actualUrl);

      if (data !== undefined) {
        req = req.send(data);
      }
      return await req.timeout(actualOptions.timeout as number);
    } catch (err) {
      throw ApiServiceBase.handleError(`Error while executing POST @ '${actualUrl}'!`, err);
    }
  }

  protected async safePostJson<T>(url: string, data?: any, options?: IApiServicePostOptions): Promise<T> {
    return (await this.safePostResponse(url, data, options)).body;
  }

  protected async safePatchJson<T>(url: string, data: any, options?: IApiServicePostOptions): Promise<T> {
    return (await this.safePatchResponse(url, data, options)).body;
  }

  protected async safePatchResponse(url: string, data?: any, options?: IApiServicePostOptions): Promise<request.Response> {
    const actualUrl = this.apiUrl(url);
    const actualOptions = options ? Object.assign({}, DEFAULT_API_SERVICE_POST_OPTIONS, options) : DEFAULT_API_SERVICE_POST_OPTIONS;

    try {
      const req = actualOptions.authenticated ? this.authedRequest(request.patch(actualUrl)) : request.patch(actualUrl);

      return await req.send(data).timeout(DEFAULT_TIMEOUT);
    } catch (err) {
      throw ApiServiceBase.handleError(`Error while executing PATCH @ '${actualUrl}'!`, err);
    }
  }

  protected async safeDeleteResponse(url: string, data?: any, options?: IApiServicePostOptions): Promise<request.Response> {
    const actualUrl = this.apiUrl(url);
    const actualOptions = options ? Object.assign({}, DEFAULT_API_SERVICE_POST_OPTIONS, options) : DEFAULT_API_SERVICE_POST_OPTIONS;

    try {
      let req = actualOptions.authenticated ? this.authedRequest(request.delete(actualUrl)) : request.delete(actualUrl);

      if (data !== undefined) {
        req = req.send(data);
      }
      return await req.timeout(DEFAULT_TIMEOUT);
    } catch (err) {
      throw ApiServiceBase.handleError(`Error while executing DELETE @ '${actualUrl}'!`, err);
    }
  }

  protected async safeUploadUserAvatar(file: Blob | Buffer, filename?: string, progressCallback?: (event: ProgressEvent) => void): Promise<string> {
    const actualUrl = this.apiUrl('/uploadAvatar');
    try {
      let req = this.authedRequest(request.post(actualUrl)).attach('file', file, filename ? { filename } : undefined);

      if (progressCallback) {
        req = req.on('progress', progressCallback);
      }

      const result = await req.timeout(2 * DEFAULT_TIMEOUT);

      return result.text;
    } catch (err) {
      throw ApiServiceBase.handleError(`Error while executing uploading avatar @ '${actualUrl}'!`, err);
    }
  }

  public static handleError(msg: string, err: any): Error {
    if (err && err.response && err.response.text) {
      const obj = JSON.parse(err.response.text);
      if (obj.message) {
        console.error(`${msg} Backend returned error: ${obj.message}`);
      }
    } else {
      console.error(`${msg}`);
    }
    return err;
  }
}
