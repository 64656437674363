




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiBasicCompanyData, IApiBasicTalentData, IApiBasicUserData } from '@/admin/services/api/AdminService.dtos';
import { IApiAvailableTag } from '@/admin/services/api/AdminUserTagService.dtos';
import { ADMIN_USER_TAG_SERVICE } from '@/admin/services/api/AdminUserTagService';
import { USER_TYPE } from '@/shared/data/constants';

@Component({})
export default class AddAdminTag extends Vue {
    @Prop()
    user!: IApiBasicUserData;

    buttonLoading = false;

    selectedTag: string | null = null;
    availableTags: IApiAvailableTag[] = [];

    closeModal(tag?: string) {
        this.$emit('close', tag);
    }

    async mounted() {
        const allAvailableTags = await ADMIN_USER_TAG_SERVICE.getAvailableTags();

        if (this.user.type === USER_TYPE.talent) {
            const talent = this.user as IApiBasicTalentData;
            const talentTags = talent.adminTags.map((at) => at.tag);
            this.availableTags = allAvailableTags.filter((at) => !talentTags.includes(at.name));
        } else if (this.user.type === USER_TYPE.company) {
            const company = this.user as IApiBasicCompanyData;
            const companyTags = company.adminTags.map((at) => at.tag);
            this.availableTags = allAvailableTags.filter((at) => !companyTags.includes(at.name));
        }
    }

    async save() {
        this.buttonLoading = true;
        try {
            await ADMIN_USER_TAG_SERVICE.createTag({
                userId: this.user.userId,
                tag: this.selectedTag as string,
            });
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while adding admin tag! ${err.message}`,
                type: 'is-danger',
            });
        }

        this.buttonLoading = false;
        this.closeModal(this.selectedTag as string);
    }
}
