















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ADMIN_CALENDLY_SERVICE } from '@/admin/services/api/AdminCalendlyService';
import Interview from '@/admin/components/Interview.vue';
import { Getter } from 'vuex-class';
import { COMMON_GETTERS } from '@/shared/store';
import { AuthProfile } from '@/shared/service/AuthService';
import { addDays, DAY_OF_WEEK_MAP, formatDate, formatTime, formatTimezone, getDateWithoutTime, getWeekMonday, getWeekSunday } from '@/tests/utils/general.utils';
import { CALENDLY_LINK_TYPE_LABEL } from '@/shared/data/default-labels';
import { ADMIN_ROUTES } from '@/admin/router';
import { CALENDLY_EVENT_RESOLUTION } from '@/admin/services/api/AdminCalendlyService.dtos';

interface IDayEvents {
    dayName: string;
    dayOfWeek: number;
    date: Date;
    isToday: boolean;
    events: any[];
}

interface IWeekEvents {
    weekStart: Date;
    weekEnd: Date;
    dayEvents: IDayEvents[];
}

@Component({
    components: {
        Interview,
    },
})
export default class InterviewSchedule extends Vue {
    @Getter(COMMON_GETTERS.AUTH_PROFILE) authProfile!: AuthProfile | null;

    eventsByWeek: IWeekEvents[] = [];

    showClaimed: 'no' | 'everyone' | 'me-unclaimed' | 'me' | 'others' = 'everyone';
    showCanceled = false;
    showMenu = false;
    fromDate: Date = new Date();
    toDate: Date = new Date();

    formatDateSlovenian = formatDate;
    formatTimeSlovenian = formatTime;
    formatTimezoneSlovenian = formatTimezone;

    TYPE_MAP = CALENDLY_LINK_TYPE_LABEL;
    CALENDLY_EVENT_RESOLUTION = CALENDLY_EVENT_RESOLUTION;

    @Watch('$route.query')
    async onQueryChange() {
        if (this.$route.query.eventId) {
            const eventId = this.$route.query.eventId as string;
            await new Promise<void>((resolve) => {
                this.$buefy.modal.open({
                    component: Interview,
                    props: {
                        eventId,
                    },
                    hasModalCard: true,
                    parent: this,
                    trapFocus: true,
                    canCancel: false,
                    events: {
                        close: () => {
                            resolve();
                        },
                    },
                });
            });

            await this.$router.push({ name: ADMIN_ROUTES.INTERVIEW_SCHEDULE });
        }
    }

    async mounted() {
        this.fromDate = getDateWithoutTime(getWeekMonday(this.fromDate));
        this.toDate = getWeekSunday(addDays(getDateWithoutTime(getWeekSunday(this.toDate)), 1));

        if (this.$route.query.eventId) {
            const eventId = this.$route.query.eventId as string;
            await new Promise<void>((resolve) => {
                this.$buefy.modal.open({
                    component: Interview,
                    props: {
                        eventId,
                    },
                    hasModalCard: true,
                    parent: this,
                    trapFocus: true,
                    canCancel: false,
                    events: {
                        close: () => {
                            resolve();
                        },
                    },
                });
            });

            await this.$router.push({ name: ADMIN_ROUTES.INTERVIEW_SCHEDULE });
        }

        await this.refresh();
    }

    async refresh() {
        try {
            let events = await ADMIN_CALENDLY_SERVICE.getAllEvents(this.fromDate, this.toDate);

            if (this.showClaimed === 'no') {
                events = events.filter((e) => e.interviewer === null);
            } else if (this.showClaimed === 'me') {
                events = events.filter((e) => e.interviewer === this.authProfile?.email);
            } else if (this.showClaimed === 'me-unclaimed') {
                events = events.filter((e) => e.interviewer === null || e.interviewer === this.authProfile?.email);
            } else if (this.showClaimed === 'others') {
                events = events.filter((e) => e.interviewer !== null && e.interviewer !== this.authProfile?.email);
            }

            if (!this.showCanceled) {
                events = events.filter((e) => e.event.status === 'active');
            }

            const weeks: IWeekEvents[] = [];
            const today = new Date();
            for (const event of events) {
                const startTime = new Date(event.event.start_time);
                const monday = getDateWithoutTime(getWeekMonday(startTime));

                const existingWeek = weeks.find((w) => w.weekStart.toISOString() === monday.toISOString());
                const dayWithoutTime = getDateWithoutTime(startTime);
                const weekDay = dayWithoutTime.getDay();

                if (existingWeek !== undefined) {
                    const existingDay = existingWeek.dayEvents.find((e) => e.dayOfWeek === weekDay);
                    if (existingDay !== undefined) {
                        existingDay.events.push(event);
                    } else {
                        existingWeek.dayEvents.push({
                            date: dayWithoutTime,
                            dayName: DAY_OF_WEEK_MAP.get(weekDay) as string,
                            dayOfWeek: weekDay,
                            isToday: dayWithoutTime.getFullYear() === today.getFullYear() && dayWithoutTime.getMonth() === today.getMonth() && dayWithoutTime.getDate() === today.getDate(),
                            events: [event],
                        });
                    }
                } else {
                    const sunday = getDateWithoutTime(getWeekSunday(startTime));

                    weeks.push({
                        weekStart: monday,
                        weekEnd: sunday,
                        dayEvents: [
                            {
                                date: dayWithoutTime,
                                dayName: DAY_OF_WEEK_MAP.get(weekDay) as string,
                                dayOfWeek: weekDay,
                                isToday: dayWithoutTime.getFullYear() === today.getFullYear() && dayWithoutTime.getMonth() === today.getMonth() && dayWithoutTime.getDate() === today.getDate(),
                                events: [event],
                            },
                        ],
                    });
                }
            }

            // fill in empty days
            for (const week of weeks) {
                const days = week.dayEvents;

                const mondayDate = week.weekStart;
                const tuesdayDate = addDays(week.weekStart, 1);
                const wednesdayDate = addDays(week.weekStart, 2);
                const thursdayDate = addDays(week.weekStart, 3);
                const fridayDate = addDays(week.weekStart, 4);
                const saturdayDate = addDays(week.weekStart, 5);
                const sundayDate = addDays(week.weekStart, 6);

                const monday = days.find((d) => d.dayName === 'Monday') || {
                    date: mondayDate,
                    dayName: 'Monday',
                    dayOfWeek: 1,
                    isToday: mondayDate.getFullYear() === today.getFullYear() && mondayDate.getMonth() === today.getMonth() && mondayDate.getDate() === today.getDate(),
                    events: [],
                };

                const tuesday = days.find((d) => d.dayName === 'Tuesday') || {
                    date: tuesdayDate,
                    dayName: 'Tuesday',
                    dayOfWeek: 2,
                    isToday: tuesdayDate.getFullYear() === today.getFullYear() && tuesdayDate.getMonth() === today.getMonth() && tuesdayDate.getDate() === today.getDate(),
                    events: [],
                };

                const wednesday = days.find((d) => d.dayName === 'Wednesday') || {
                    date: wednesdayDate,
                    dayName: 'Wednesday',
                    dayOfWeek: 3,
                    isToday: wednesdayDate.getFullYear() === today.getFullYear() && wednesdayDate.getMonth() === today.getMonth() && wednesdayDate.getDate() === today.getDate(),
                    events: [],
                };

                const thursday = days.find((d) => d.dayName === 'Thursday') || {
                    date: thursdayDate,
                    dayName: 'Thursday',
                    dayOfWeek: 4,
                    isToday: thursdayDate.getFullYear() === today.getFullYear() && thursdayDate.getMonth() === today.getMonth() && thursdayDate.getDate() === today.getDate(),
                    events: [],
                };

                const friday = days.find((d) => d.dayName === 'Friday') || {
                    date: fridayDate,
                    dayName: 'Friday',
                    dayOfWeek: 5,
                    isToday: fridayDate.getFullYear() === today.getFullYear() && fridayDate.getMonth() === today.getMonth() && fridayDate.getDate() === today.getDate(),
                    events: [],
                };

                const saturday = days.find((d) => d.dayName === 'Saturday') || {
                    date: saturdayDate,
                    dayName: 'Saturday',
                    dayOfWeek: 6,
                    isToday: saturdayDate.getFullYear() === today.getFullYear() && saturdayDate.getMonth() === today.getMonth() && saturdayDate.getDate() === today.getDate(),
                    events: [],
                };

                const sunday = days.find((d) => d.dayName === 'Sunday') || {
                    date: sundayDate,
                    dayName: 'Sunday',
                    dayOfWeek: 0,
                    isToday: sundayDate.getFullYear() === today.getFullYear() && sundayDate.getMonth() === today.getMonth() && sundayDate.getDate() === today.getDate(),
                    events: [],
                };

                week.dayEvents = [monday, tuesday, wednesday, thursday, friday, saturday, sunday];
            }

            this.eventsByWeek = weeks;
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Failed to fetch events! Please check console for any additional logs!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        }
    }

    async openDetails(eventId: string) {
        await this.$router.push({
            name: ADMIN_ROUTES.INTERVIEW_SCHEDULE, query: {
                eventId,
            },
        });
    }
}
