import { ApiServiceBase } from '@/shared/service/ServiceBase';
import { ADMIN_DATA_TYPE, RESTRICTED_TOKEN_TYPE, UUID } from '@/shared/data/constants';
import {
    IApiAdminData,
    IApiAdminUser,
    IApiBasicCompanyData,
    IApiBasicTalentData,
    IApiCompanyProperties,
    IApiCompanySearchCriteria,
    IApiCreateAdminDataRequest,
    IApiSendCustomCustomerIOEventRequest,
    IApiSetFinalInterviewResultsRequest,
    IApiSetTechnicalInterviewResultsRequest,
    IApiTalentSearchCriteria,
    IApiTemporaryBucketAccessData,
    IApiUpdateAdminDataRequest,
    IApiUpdateFinalInterviewResultsRequest,
    IApiUpdateTalentDataRequest,
} from '@/admin/services/api/AdminService.dtos';
import { PageData } from '@/shared/data/types';

export class AdminService extends ApiServiceBase {
    public constructor(baseApiUrl?: string) {
        super(baseApiUrl || '/api/user-service/admin');
    }

    public async getProfile(): Promise<IApiAdminUser> {
        return this.safeGetJson('/');
    }

    public async getTalentUserData(userId: UUID): Promise<IApiBasicTalentData> {
        return this.safeGetJson(`/talent/${userId}`);
    }

    public async updateTalentData(
        userId: UUID,
        req: IApiUpdateTalentDataRequest,
    ): Promise<void> {
        await this.safePatchResponse(`/talent/${userId}`, req);
    }

    public async getCompanyUserData(userId: UUID): Promise<IApiBasicCompanyData> {
        return this.safeGetJson(`/company/${userId}`);
    }

    public async approveCompany(userId: UUID): Promise<void> {
        await this.safePostResponse(`/company/${userId}/approve`);
    }

    public async deleteCompany(userId: UUID): Promise<void> {
        await this.safePostResponse(`/deleteCompany/${userId}`);
    }

    public async deleteTalent(userId: UUID): Promise<void> {
        await this.safePostResponse(`/deleteTalent/${userId}`);
    }

    public async setTechnicalInterviewResults(
        userId: UUID,
        req: IApiSetTechnicalInterviewResultsRequest,
    ): Promise<void> {
        await this.safePostResponse(`/technicalInterviewResults/${userId}`, req);
    }

    public async setFinalInterviewResults(
        userId: UUID,
        req: IApiSetFinalInterviewResultsRequest,
    ): Promise<void> {
        await this.safePostResponse(`/finalInterviewResults/${userId}`, req);
    }

    public async updateTalentSummary(
        userId: UUID,
        summary: string,
    ): Promise<void> {
        await this.safePostResponse(`/updateTalentSummary/${userId}`, {
            summary,
        });
    }

    public async updateFinalInterviewResults(
        vettingRecordId: UUID,
        req: IApiUpdateFinalInterviewResultsRequest,
    ): Promise<void> {
        await this.safePostResponse(
            `/updateFinalInterviewResults/${vettingRecordId}`,
            req,
        );
    }

    public async updateTechnicalInterviewResults(
        vettingRecordId: UUID,
        req: IApiSetTechnicalInterviewResultsRequest,
    ): Promise<void> {
        await this.safePostResponse(
            `/updateTechnicalInterviewResults/${vettingRecordId}`,
            req,
        );
    }

    public async removeVettingRecord(vettingRecordId: UUID): Promise<void> {
        await this.safePostResponse(`/removeVettingRecord/${vettingRecordId}`);
    }

    public async findTalent(
        criteria: IApiTalentSearchCriteria,
        page: number,
        perPage: number,
    ): Promise<PageData<IApiBasicTalentData>> {
        const request = Object.assign({}, criteria, { perPage, page });
        return this.safePostJson('/findTalent', request, {
            timeout: 60000,
        });
    }

    public async findCompanies(
        criteria: IApiCompanySearchCriteria,
        page: number,
        perPage: number,
    ): Promise<PageData<IApiBasicCompanyData>> {
        const request = Object.assign({}, criteria, { perPage, page });
        return this.safePostJson('/findCompanies', request, {
            timeout: 60000,
        });
    }

    public async generateRestrictedToken(
        type: RESTRICTED_TOKEN_TYPE,
        usableBy?: UUID | null,
        usableUntil?: Date | null,
        metadata?: any | null,
    ): Promise<string> {
        const response = await this.safePostResponse('/generateRestrictedToken', {
            type,
            usableBy,
            usableUntil,
            metadata,
        });

        return response.text;
    }

    public async sendCustomerIOEvent(
        events: IApiSendCustomCustomerIOEventRequest[],
    ): Promise<void> {
        await this.safePostResponse('/customerIoEvents', events, {
            timeout: 60000,
        });
    }

    public async getCustomerIoAvailabilityEventCompanies(): Promise<string[]> {
        return this.safeGetJson('/customerIoAvailabilityEventCompanies');
    }

    public async getStaticFileUploadCredentials(): Promise<IApiTemporaryBucketAccessData> {
        return await this.safePostJson('/staticFileUploadCredentials');
    }

    public async getOperationalFileUploadCredentials(): Promise<IApiTemporaryBucketAccessData> {
        return await this.safePostJson('/operationalFileUploadCredentials');
    }

    public async updateCompanyUser(
        userId: UUID,
        properties: IApiCompanyProperties,
    ): Promise<void> {
        await this.safePostResponse(`/company/${userId}/update`, {
            properties,
        });
    }

    public async getAdminData(type?: ADMIN_DATA_TYPE): Promise<IApiAdminData[]> {
        return await this.safeGetJson('/data/admin', {
            type,
        });
    }

    public async storeAdminData(
        req: IApiCreateAdminDataRequest,
    ): Promise<IApiAdminData> {
        return await this.safePostJson('/data/admin', req);
    }

    public async updateAdminData(
        id: UUID,
        req: IApiUpdateAdminDataRequest,
    ): Promise<IApiAdminData> {
        return await this.safePatchJson(`/data/admin/${id}`, req);
    }

    public async deleteAdminData(id: UUID): Promise<void> {
        await this.safeDeleteResponse(`/data/admin/${id}`);
    }

    public async getGlobalData(type?: ADMIN_DATA_TYPE): Promise<IApiAdminData[]> {
        return await this.safeGetJson('/data/global', {
            type,
        });
    }

    public async storeGlobalData(
        req: IApiCreateAdminDataRequest,
    ): Promise<IApiAdminData> {
        return await this.safePostJson('/data/global', req);
    }

    public async updateGlobalData(
        id: UUID,
        req: IApiUpdateAdminDataRequest,
    ): Promise<IApiAdminData> {
        return await this.safePatchJson(`/data/global/${id}`, req);
    }

    public async deleteGlobalData(id: UUID): Promise<void> {
        await this.safeDeleteResponse(`/data/global/${id}`);
    }
}

export const ADMIN_SERVICE = new AdminService();
