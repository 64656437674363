import { IApiTemporaryBucketAccessData } from '@/admin/services/api/AdminService.dtos';
import uuid from 'uuid';
import { S3, config } from 'aws-sdk';

// eslint-disable-next-line
config.httpOptions!.timeout = 0;

export class S3ClientService {
  private readonly s3: S3;

  constructor(private readonly settings: IApiTemporaryBucketAccessData) {
    this.s3 = new S3({
      endpoint: `s3.${settings.endpoint}`,
      region: settings.region,
      credentials: {
        secretAccessKey: settings.secret,
        accessKeyId: settings.key,
        sessionToken: settings.token,
      },
    });
  }

  private toS3Folder(destinationFolder: string): string {
    if (this.settings.folder && this.settings.folder.length > 0) {
      return `${this.settings.folder}/${destinationFolder}`;
    }

    return destinationFolder;
  }

  private getFileUrl(destinationKey: string): string {
    return `https://${this.settings.bucket}.s3.${this.settings.endpoint}/${destinationKey}`;
  }

  public async uploadFile(file: File,
                          isPublic: boolean,
                          destinationFolder: string,
                          contentType: string,
                          keyName?: string,
                          progressCallback?: (percentage: number) => void): Promise<string> {
    const actualName = keyName !== undefined ? keyName : uuid.v4();
    const extension = file.name.split('.').pop() as string;
    const actualDestination = this.toS3Folder(`${destinationFolder}/${actualName}.${extension}`);

    await new Promise<void>((resolve, reject) => {
      this.s3.putObject({
        Bucket: this.settings.bucket,
        Key: actualDestination,
        Body: file,
        ContentType: contentType,
        ACL: isPublic ? 'public-read' : undefined,
      }, (err) => {
        if (err) {
          console.error('Error uploading to AWS S3 bucket!', err);
          reject(err);
        } else {
          resolve();
        }
      }).on('httpUploadProgress', progress => {
        if (progressCallback) {
          const progressPercentage = Math.round(progress.loaded / progress.total * 100);
          try {
            progressCallback(progressPercentage);
          } catch (e) {
            // do nothing
          }
        }
      });
    })

    return this.getFileUrl(actualDestination);
  }

  public static getVideoContentType(extension: string): string {
    switch (extension) {
      case 'mp4':
        return 'video/mp4';
      case 'flv':
        return 'video/x-flv';
      case 'mpeg':
        return 'video/mpeg';
      case 'avi':
        return 'video/x-msvideo';
      default:
        throw new Error(`Extension '${extension}' is not amount supported video extensions!`);
    }
  }

  public static getImageContentType(extension: string): string {
    switch (extension) {
      case 'jpg':
        return 'image/jpeg';
      case 'jpeg':
        return 'image/jpeg';
      case 'svg':
        return 'image/svg+xml';
      case 'png':
        return 'image/png';
      case 'bmp':
        return 'image/bmp';
      case 'gif':
        return 'image/gif';
      default:
        throw new Error(`Extension '${extension}' is not amount supported image extensions!`);
    }
  }
}