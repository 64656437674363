
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiCustomerIOEventHistoryData } from '@/admin/services/api/AdminService.dtos';

@Component({})
export default class CustomerIOEvent extends Vue {
    @Prop()
    readonly data!: IApiCustomerIOEventHistoryData;
}
