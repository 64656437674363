






































































import { Component, Prop } from 'vue-property-decorator';
import ComponentBase from '@/admin/components/ComponentBase';
import { IApiBasicCompanyData, IApiCompanyProperties } from '@/admin/services/api/AdminService.dtos';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';
import { ValidationResult } from 'vee-validate/dist/types/types';
import { S3ClientService } from '@/admin/services/S3ClientService';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';

@Component({
    components: {
        RichTextEditor,
    },
})
export default class UserCompanyEdit extends ComponentBase {
    @Prop({})
    company!: IApiBasicCompanyData;

    properties: IApiCompanyProperties | null = null;
    logoUrl: string | null = null;

    mounted() {
        this.properties = Object.assign({}, this.company.properties);
        if (this.properties.COMPANY_LOGO_URL) {
            this.logoUrl = this.properties.COMPANY_LOGO_URL;
        }
    }

    async selectFile() {
        (this.$refs.file as HTMLElement).click();
    }

    async onFileChange(validate: (event: Event) => Promise<ValidationResult>, event: Event) {
        const results: ValidationResult = await validate(event);

        if (results.valid && this.properties) {
            const props = this.properties;
            const target = event.target as HTMLInputElement;
            const files = target.files as FileList;
            const file = files[0];

            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image;
                img.onload = async () => {
                    if (img.width === img.height) {
                        try {
                            const extension = file.name.split('.').pop() as string;
                            const contentType = S3ClientService.getImageContentType(extension);
                            const data = await ADMIN_SERVICE.getOperationalFileUploadCredentials();
                            const s3Client = new S3ClientService(data);

                            props.COMPANY_LOGO_URL = await s3Client.uploadFile(file, true, 'avatars', contentType);
                            this.logoUrl = props.COMPANY_LOGO_URL;
                        } catch (err) {
                            this.$buefy.dialog.alert({
                                title: 'File error',
                                message: 'Failed to upload company logo! Please try again later or contact support at support@remotesome.com',
                                type: 'is-danger',
                                hasIcon: true,
                                icon: 'times-circle',
                            });
                        }
                    } else {
                        this.$buefy.dialog.alert({
                            title: 'File error',
                            message: 'Image aspect ration must be 1:1!',
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                        });
                    }
                };
                img.src = reader.result as string;
            };
            reader.readAsDataURL(file);
            this.$forceUpdate();
        } else {
            const firstErrorType: string = Object.keys(results.failedRules)[0];
            let message;
            switch (firstErrorType) {
                case 'size':
                    message = 'The image you selected is too big, please select an image smaller than 2 MB.';
                    break;
                case 'image':
                default:
                    message = 'The file you selected is not in a valid format, please choose an image file.';
                    break;
            }

            this.$buefy.dialog.alert({
                title: 'File error',
                message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        }
    }

    async save() {
        try {
            await ADMIN_SERVICE.updateCompanyUser(this.company.userId, this.properties as IApiCompanyProperties);
            this.$emit('close');
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error while saving!',
                message: 'Error while saving a company! Please check browser logs!',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        }
    }
}
