


































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    IApiBasicTalentData,
    IApiInterviewQuestion,
    IApiTechnicalInterviewPositionRecommendation,
    IApiTechnicalInterviewTask,
    IApiUserVettingRecord,
} from '@/admin/services/api/AdminService.dtos';
import { CALENDLY_LINK_TYPE, DEVELOPER_ROLE } from '@/shared/data/constants';
import { CALENDLY_LINK_TYPE_LABEL, DEVELOPER_ROLE_LABEL } from '@/shared/data/default-labels';
import ComponentBase from '@/admin/components/ComponentBase';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';
import {
    CodetableResult,
    Codetables,
    CodetableService,
    DomainExperienceLevel,
    EnglishLevel,
    InterviewPositionRecommendation,
    InterviewPositionRecommendationLevel,
    InterviewTaskDefinition,
    OperatingSystem,
    ProblemSolvingLevel,
    ProductivityLevel,
    TaskResultLevel,
} from '@/shared/service/CodetableService';
import SkillSelector from '@/admin/components/SkillSelector.vue';
import { minLength, required } from 'vuelidate/lib/validators';
import {
    MAX_COMMUNICATION_SCORE,
    MAX_DOMAIN_EXPERIENCE_SCORE,
    MAX_PROBLEM_SOLVING_SCORE,
    MAX_PRODUCTIVITY_SCORE,
    MAX_RECOMMENDATION_SCORE,
    MAX_TASKS_SCORE,
    recommendationScore,
    scoreCommunication,
    scoreDomainExperience,
    scoreProblemSolving,
    scoreProductivity,
    tasksScore,
} from './scoring';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { ArrayHelpers } from '@/shared/utils/ArrayHelpers';
import { IEditTask } from '@/admin/views/dashboard/views/modals/technical-interview/v3/types';
import InterviewTask from '@/admin/views/dashboard/views/modals/technical-interview/v3/InterviewTask.vue';

interface Question {
    id: number;
    question: string | null;
    answerNote: string | null;
}

interface PositionRecommendation {
    position: string;
    decision: string | null;
}

interface IForm {
    operatingSystem: string | null;
    programmingLanguages: string[];
    frameworksOrLibraries: string[];

    englishLevel: string | null;
    englishLevelNotes: string | null;

    domainExperience: string | null;
    domainExperienceNotes: string | null;

    problemSolving: string | null;
    problemSolvingNotes: string | null;

    productivity: string | null;
    productivityNotes: string | null;

    questions: Question[];
    tasks: IEditTask[];

    overallNotes: string | null;
    positionRecommendations: PositionRecommendation[];
}

@Component({
    components: {
        InterviewTask,
        RichTextEditor,
        SkillSelector,
    },
})
export default class SetOrEditTechnicalInterviewResults extends ComponentBase {
    @Prop()
    user!: IApiBasicTalentData;

    @Prop()
    scheduledOn!: string;

    @Prop()
    type!: CALENDLY_LINK_TYPE;

    @Prop()
    eventId!: string;

    @Prop({ default: null })
    existingRecord!: IApiUserVettingRecord | null;

    form: IForm = {
        operatingSystem: null,
        programmingLanguages: [],
        frameworksOrLibraries: [],

        englishLevel: null,
        englishLevelNotes: null,

        domainExperience: null,
        domainExperienceNotes: null,

        problemSolving: null,
        problemSolvingNotes: null,

        productivity: null,
        productivityNotes: null,

        questions: [],
        tasks: [],

        overallNotes: null,
        positionRecommendations: [],
    };

    get englishLevel(): EnglishLevel | null {
        if (this.form.englishLevel && this.codetables) {
            return ArrayHelpers.single(this.codetables.english_levels as EnglishLevel[], l => l.name === this.form.englishLevel);
        }
        return null;
    }

    get domainExperience(): DomainExperienceLevel | null {
        if (this.form.domainExperience && this.codetables) {
            return ArrayHelpers.single(this.codetables.domain_experience_levels as DomainExperienceLevel[], l => l.name === this.form.domainExperience);
        }

        return null;
    }

    get problemSolving(): ProblemSolvingLevel | null {
        if (this.form.problemSolving && this.codetables) {
            return ArrayHelpers.single(this.codetables.problem_solving_levels as ProblemSolvingLevel[], l => l.name === this.form.problemSolving);
        }

        return null;
    }

    get productivity(): ProductivityLevel | null {
        if (this.form.productivity && this.codetables) {
            return ArrayHelpers.single(this.codetables.productivity_levels as ProductivityLevel[], l => l.name === this.form.productivity);
        }

        return null;
    }

    get areGeneralInformationValid(): boolean {
        return !this.$v.form.operatingSystem?.$invalid && !this.$v.form.programmingLanguages?.$invalid && !this.$v.form.frameworksOrLibraries?.$invalid;
    }

    get isProblemSolvingValid(): boolean {
        return !this.$v.form.problemSolving?.$invalid && !this.$v.form.problemSolvingNotes?.$invalid;
    }

    taskDefinition(name: string): InterviewTaskDefinition | null {
        if (this.codetables) {
            return ArrayHelpers.single(this.codetables.interview_tasks as InterviewTaskDefinition[], t => t.name === name);
        }

        return null;
    }

    taskResultLevel(name: string): TaskResultLevel | null {
        if (this.codetables) {
            return ArrayHelpers.single(this.codetables.task_result_levels as TaskResultLevel[], l => l.name === name);
        }

        return null;
    }

    position(name: string): InterviewPositionRecommendation | null {
        if (this.codetables) {
            return ArrayHelpers.single(this.codetables.interview_position_recommendations as InterviewPositionRecommendation[], l => l.name === name);
        }

        return null;
    }

    positionDecision(name: string): InterviewPositionRecommendationLevel | null {
        if (this.codetables) {
            return ArrayHelpers.single(this.codetables.interview_position_recommendation_levels as InterviewPositionRecommendationLevel[], l => l.name === name);
        }

        return null;
    }

    validations() {
        let validations: any = {
            form: {
                operatingSystem: {
                    required,
                },
                programmingLanguages: {
                    required,
                    minLength: minLength(1),
                },
                frameworksOrLibraries: {
                    required,
                    minLength: minLength(1),
                },

                englishLevel: {
                    required,
                },
                englishLevelNotes: {},
                domainExperience: {
                    required,
                },
                domainExperienceNotes: {},
                problemSolving: {
                    required,
                },
                problemSolvingNotes: {},
                productivity: {
                    required,
                },
                productivityNotes: {},
                questions: {
                    required,
                    minLength: minLength(1),
                    $each: {
                        question: {
                            required,
                            minLength: minLength(5),
                        },
                        answerNote: {
                            required,
                            minLength: minLength(30),
                        },
                    },
                },
                overallNotes: {
                    required,
                    minLength: minLength(100),
                },
                positionRecommendations: {
                    required,
                    minLength: minLength(this.codetables?.interview_position_recommendations?.length || 1),
                    $each: {
                        decision: {
                            required,
                        },
                    },
                },
            },
        };

        const problemSolving = this.problemSolving;
        if (problemSolving !== null && problemSolving.name === 'NOT_APPLICABLE') {
            validations.form.problemSolvingNotes = {
                required,
                minLength: minLength(30),
            };
        }

        return validations;
    }

    role: DEVELOPER_ROLE | null = null;

    get isEdit(): boolean {
        return this.existingRecord !== null;
    }

    get interviewTypeLabel(): string {
        if (CALENDLY_LINK_TYPE_LABEL[this.type]) {
            return CALENDLY_LINK_TYPE_LABEL[this.type] as string;
        } else {
            return 'Technical Interview';
        }
    }

    get roleText(): string {
        if (this.role && DEVELOPER_ROLE_LABEL[this.role]) {
            return DEVELOPER_ROLE_LABEL[this.role];
        }

        return 'Unknown Role';
    }

    get filteredOperatingSystems(): string[] {
        if (this.codetables && this.form.operatingSystem !== null) {
            const setOs = this.form.operatingSystem as string;
            return (this.codetables.operating_systems as OperatingSystem[])
                .map(os => os.displayName)
                .filter(os => os.toLowerCase().indexOf(setOs.toLowerCase()) >= 0);
        }

        return [];
    }

    get availableTaskDefinitions(): InterviewTaskDefinition[] {
        if (this.codetables && this.role) {
            const all = this.codetables.interview_tasks as InterviewTaskDefinition[];

            return all.filter(t => t.role === (this.role as string));
        }

        return [];
    }

    get taskAvailable(): boolean {
        return this.form.tasks.length < this.availableTaskDefinitions.length;
    }

    get sortedTasks(): IEditTask[] {
        return this.form.tasks.sort((a, b) => a.id - b.id);
    }

    addNewTask() {
        if (this.availableTaskDefinitions.length >= 1) {
            this.form.tasks.push({
                id: this.sortedTasks.length + 1,
                task: this.availableTaskDefinitions[0].name,
                taskResult: null,
                taskResultNotes: null,
            });
        }
    }

    moveTaskUp(id: number) {
        if (id === 1) {
            return;
        }

        const task = this.sortedTasks[id - 1];
        const previousTask = this.sortedTasks[id - 2];

        task.id = previousTask.id;
        previousTask.id = id;
    }

    moveTaskDown(id: number) {
        if (id === this.sortedTasks.length) {
            return;
        }

        const task = this.sortedTasks[id - 1];
        const nextTask = this.sortedTasks[id];

        task.id = nextTask.id;
        nextTask.id = id;
    }

    removeTask(id: number) {
        this.form.tasks = this.form.tasks.filter(q => q.id !== id);

        let i = 1;
        const tasks = this.sortedTasks;
        for (const task of tasks) {
            task.id = i++;
        }
    }

    get sortedQuestions(): Question[] {
        return this.form.questions.sort((a, b) => a.id - b.id);
    }

    addNewQuestion() {
        this.form.questions.push({
            id: this.sortedQuestions.length + 1,
            question: null,
            answerNote: null,
        });
    }

    moveQuestionUp(id: number) {
        if (id === 1) {
            return;
        }

        const question = this.sortedQuestions[id - 1];
        const previousQuestion = this.sortedQuestions[id - 2];

        question.id = previousQuestion.id;
        previousQuestion.id = id;
    }

    moveQuestionDown(id: number) {
        if (id === this.sortedQuestions.length) {
            return;
        }

        const question = this.sortedQuestions[id - 1];
        const nextQuestion = this.sortedQuestions[id];

        question.id = nextQuestion.id;
        nextQuestion.id = id;
    }

    removeQuestion(id: number) {
        this.form.questions = this.form.questions.filter(q => q.id !== id);

        let i = 1;
        const questions = this.sortedQuestions;
        for (const question of questions) {
            question.id = i++;
        }
    }

    codetables: CodetableResult | null = null;

    async mounted() {
        this.codetables = await CodetableService.getCodetables(
            Codetables.OPERATING_SYSTEMS,
            Codetables.INTERVIEW_POSITION_RECOMMENDATIONS,
            Codetables.INTERVIEW_POSITION_RECOMMENDATION_LEVELS,
            Codetables.ENGLISH_LEVELS,
            Codetables.DOMAIN_EXPERIENCE_LEVELS,
            Codetables.PROBLEM_SOLVING_LEVELS,
            Codetables.PRODUCTIVITY_LEVELS,
            Codetables.INTERVIEW_TASKS,
            Codetables.TASK_RESULT_LEVELS,
        );

        switch (this.type) {
            case CALENDLY_LINK_TYPE.BACKEND_TECHNICAL_INTERVIEW:
                this.role = DEVELOPER_ROLE.BACKEND;
                break;
            case CALENDLY_LINK_TYPE.FRONTEND_TECHNICAL_INTERVIEW:
                this.role = DEVELOPER_ROLE.FRONTEND;
                break;
            case CALENDLY_LINK_TYPE.BLOCKCHAIN_ETH_ENGINEER_TECHNICAL_INTERVIEW:
                this.role = DEVELOPER_ROLE.BLOCKCHAIN_ETH;
                break;
            case CALENDLY_LINK_TYPE.DATA_ENGINEER_TECHNICAL_INTERVIEW:
                this.role = DEVELOPER_ROLE.DATA_SCIENCE;
                break;
            case CALENDLY_LINK_TYPE.FLUTTER_TECHNICAL_INTERVIEW:
                this.role = DEVELOPER_ROLE.MOBILE;
                break;
            case CALENDLY_LINK_TYPE.DEVOPS_KUBERNETES_TECHNICAL_INTERVIEW:
                this.role = DEVELOPER_ROLE.DEVOPS;
                break;
            default:
                throw new Error(`Calendly link type '${this.type}' is not yet supported!`);
        }

        let addRecommendations = true;
        if (this.existingRecord === null) {
            this.addNewQuestion();
            this.addNewTask();
        } else {
            if (this.existingRecord.results) {
                if (this.existingRecord.results.version === 3) {
                    this.form.operatingSystem = this.existingRecord.results.operatingSystem;
                    this.form.programmingLanguages.push(...this.existingRecord.results.programmingLanguages);
                    this.form.frameworksOrLibraries.push(...this.existingRecord.results.frameworksOrLibraries);

                    this.form.englishLevel = this.existingRecord.results.englishLevel.name;
                    this.form.englishLevelNotes = this.existingRecord.results.englishLevelNotes || null;

                    this.form.domainExperience = this.existingRecord.results.domainExperience.name;
                    this.form.domainExperienceNotes = this.existingRecord.results.domainExperienceNotes || null;

                    this.form.problemSolving = this.existingRecord.results.problemSolving.name;
                    this.form.problemSolvingNotes = this.existingRecord.results.problemSolvingNotes || null;

                    this.form.productivity = this.existingRecord.results.productivity.name;
                    this.form.productivityNotes = this.existingRecord.results.productivityNotes || null;

                    let id = 1;
                    for (const q of this.existingRecord.results.questions) {
                        this.form.questions.push({
                            id: id++,
                            question: q.question,
                            answerNote: q.answerNote,
                        });
                    }

                    id = 1;
                    for (const t of this.existingRecord.results.tasks) {
                        this.form.tasks.push({
                            id: id++,
                            taskResult: t.taskResult.name,
                            task: t.task.name,
                            taskResultNotes: t.taskResultNotes,
                        });
                    }

                    this.form.overallNotes = this.existingRecord.results.overallNotes;

                    for (const rec of this.existingRecord.results.positionRecommendations) {
                        this.form.positionRecommendations.push({
                            position: rec.position.name,
                            decision: rec.decision.name,
                        });
                    }

                    addRecommendations = false;
                } else if (this.existingRecord.results.version === 2) {
                    this.form.questions = this.existingRecord.results.questions;
                    this.form.overallNotes = this.existingRecord.results.overallNotes;
                } else {
                    // version 1
                    this.form.overallNotes = this.existingRecord.results.summary;
                }
            }
        }

        if (addRecommendations) {
            const positions = this.codetables.interview_position_recommendations as InterviewPositionRecommendation[];
            for (const pos of positions) {
                this.form.positionRecommendations.push({
                    position: pos.name,
                    decision: null,
                });
            }
        }
    }

    getScore(): number {
        if (this.$v.form.$invalid) {
            return 0;
        }

        const communicationScore = scoreCommunication(this.englishLevel as EnglishLevel);
        const domainExperienceScore = scoreDomainExperience(this.domainExperience as DomainExperienceLevel);
        const problemSolvingScore = scoreProblemSolving(this.problemSolving as ProblemSolvingLevel);
        const productivityScore = scoreProductivity(this.productivity as ProductivityLevel);
        const tScore = tasksScore(this.form.tasks.map(t => this.taskResultLevel(t.taskResult as string) as TaskResultLevel));
        const rScore = recommendationScore(this.form.positionRecommendations.map(r => this.positionDecision(r.decision as string) as InterviewPositionRecommendationLevel));

        return communicationScore + domainExperienceScore + problemSolvingScore + productivityScore + tScore + rScore;
    }

    getMaxScore(): number {
        if (this.$v.form.$invalid) {
            return 0;
        }

        return MAX_COMMUNICATION_SCORE + MAX_DOMAIN_EXPERIENCE_SCORE + MAX_PROBLEM_SOLVING_SCORE + MAX_PRODUCTIVITY_SCORE +
            MAX_TASKS_SCORE + MAX_RECOMMENDATION_SCORE(this.form.positionRecommendations.length);
    }

    async save() {
        this.$v.$touch();
        const interviewTasks = this.$refs['interviewTask'] as Vue[];

        for (const interviewTask of interviewTasks) {
            interviewTask.$v.$touch();
        }

        const tasksToWait = interviewTasks.filter(t => t.$v.task.$pending);
        if (tasksToWait.length > 0) {
            await Promise.all(tasksToWait.map(t => this.waitForChange(() => !t.$v.task.$pending)));
        }

        if (this.$v.form.$pending) {
            await this.waitForChange(() => !this.$v.form.$pending);
        }

        const invalidTasks = interviewTasks.filter(t => t.$v.task.$invalid);

        if (this.$v.form.$invalid || invalidTasks.length > 0) {
            this.$buefy.dialog.alert('You still need to complete evaluation!');
            return;
        }

        try {
            const tasks: IApiTechnicalInterviewTask[] = this.form.tasks.map(t => {
                return {
                    task: this.taskDefinition(t.task as string) as InterviewTaskDefinition,
                    taskResult: this.taskResultLevel(t.taskResult as string) as TaskResultLevel,
                    taskResultNotes: t.taskResultNotes,
                };
            });

            const questions: IApiInterviewQuestion[] = this.form.questions.map(q => {
                return {
                    question: q.question as string,
                    answerNote: q.answerNote as string,
                };
            });

            const positionRecommendations: IApiTechnicalInterviewPositionRecommendation[] = this.form.positionRecommendations.map(r => {
                return {
                    position: this.position(r.position) as InterviewPositionRecommendation,
                    decision: this.positionDecision(r.decision as string) as InterviewPositionRecommendationLevel,
                };
            });

            const req = {
                evaluationResults: {
                    role: this.role as DEVELOPER_ROLE,
                    operatingSystem: this.form.operatingSystem as string,
                    programmingLanguages: this.form.programmingLanguages,
                    frameworksOrLibraries: this.form.frameworksOrLibraries,

                    englishLevel: this.englishLevel as EnglishLevel,
                    englishLevelNotes: this.form.englishLevelNotes,

                    domainExperience: this.domainExperience as DomainExperienceLevel,
                    domainExperienceNotes: this.form.domainExperienceNotes,

                    problemSolving: this.problemSolving as ProblemSolvingLevel,
                    problemSolvingNotes: this.form.problemSolvingNotes,

                    productivity: this.productivity as ProductivityLevel,
                    productivityNotes: this.form.productivityNotes,

                    questions,
                    tasks,

                    overallNotes: this.form.overallNotes as string,
                    positionRecommendations,
                },
                success: true,
                score: this.getScore(),
                maxScore: this.getMaxScore(),
                scheduledOn: this.scheduledOn,
                eventId: this.eventId,
                type: this.type,
            };

            if (this.existingRecord) {
                await ADMIN_SERVICE.updateTechnicalInterviewResults(this.existingRecord.id, req);
            } else {
                await ADMIN_SERVICE.setTechnicalInterviewResults(this.user.userId, req);
            }

            await this.closeModal();
        } catch (e) {
            console.error('Error while setting technical interview results!', e);
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while setting technical interview results! ${e.message}`,
                type: 'is-danger',
            });
        }
    }

    async closeModal() {
        await this.$emit('close');
    }
}
