export class ArrayHelpers {
  public static single<T>(array: T[], predicate: (e: T) => boolean): T {
    const result = this.singleOrNull(array, predicate);

    if (result === null) {
      throw new Error('No elements found for this predicate!');
    }

    return result;
  }

  public static singleOrNull<T>(array: T[], predicate: (e: T) => boolean): T | null {
    const filtered = array.filter(predicate);

    if (filtered.length === 1) {
      return filtered[0];
    }

    if (filtered.length > 1) {
      throw new Error(`More than one element found for this predicate - found ${filtered.length} elements!`);
    }

    return null;
  }

  public static uniqueObjects(arr: Record<string, any>[], key: string) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  public static unique(arr: any[]) {
    return arr.filter((value, index, array) => array.indexOf(value) === index);
  }

  public static sortBy<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
    const shallowCopy = [...array];

    shallowCopy.sort(comparator);

    return shallowCopy;
  }

  public static chunked<T>(array: T[], chunkSize: number): Array<Array<T>> {
    return array.reduce((resArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resArray[chunkIndex]) {
        resArray[chunkIndex] = [];
      }

      resArray[chunkIndex].push(item)

      return resArray;
    }, new Array<Array<T>>());
  }
}
