









































































import { Component, Prop } from 'vue-property-decorator';
import ComponentBase from '@/admin/components/ComponentBase';
import { IApiBasicTalentData } from '@/admin/services/api/AdminService.dtos';
import { RESTRICTED_TOKEN_TYPE } from '@/shared/data/constants';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { required } from 'vuelidate/lib/validators';

enum GenerationState {
    ERROR,
    SUCCESS,
    PROCESSING,
}

@Component({})
export default class GenerateTalentSharedLink extends ComponentBase {
    @Prop({ required: true })
    users!: IApiBasicTalentData[];

    validUntilDate: Date | null = null;
    processing = false;
    linksGenerated = false;

    GenerationState = GenerationState;

    validations() {
        return {
            validUntilDate: {
                required,
            },
        };
    }

    formatter(d: Date): string {
        return d.toLocaleDateString();
    }

    async copyCSV() {
        if (this.linksGenerated) {
            let csvContent = 'UserID,Name,Expiration,Link\n';
            const until = this.validUntilDate?.toISOString();
            let rows = 0;
            for (const user of this.users) {
                const userData = user as any;
                if (userData.linkProcessing && userData.linkProcessing.state == GenerationState.SUCCESS) {
                    rows++;
                    csvContent += `${user.userId},"${user.properties.TALENT_NAME} ${user.properties.TALENT_LAST_NAME}",${until},${userData.linkProcessing.link}\n`;
                }
            }

            if (rows > 0) {
                await this.copyToClipboard('CSV data', csvContent);
            }
        }
    }

    async generate() {
        this.$v.$touch();
        if (this.$v.$pending) {
            await this.waitForChange(() => !this.$v.form.$pending);
        }

        this.processing = true;

        for (const user of this.users) {
            const userData = user as any;
            if (userData.linkProcessing && userData.linkProcessing.link) {
                continue;
            }

            userData.linkProcessing = {
                link: null,
                error: null,
                state: GenerationState.PROCESSING,
            };
            this.$forceUpdate();

            try {
                const result = await ADMIN_SERVICE.generateRestrictedToken(
                    RESTRICTED_TOKEN_TYPE.COMPANY_CANDIDATE_ACCESS,
                    undefined,
                    this.validUntilDate,
                    {
                        userId: user.userId,
                    },
                );
                userData.linkProcessing.link = `${process.env.VUE_APP_COMPANY_URL}/talent/${result}`;
                userData.linkProcessing.state = GenerationState.SUCCESS;
                this.$forceUpdate();
            } catch (e) {
                userData.linkProcessing.state = GenerationState.ERROR;
                userData.linkProcessing.error = e;
                this.$forceUpdate();
            }
        }

        this.processing = false;
        this.linksGenerated = true;

        await this.copyCSV();
    }

    mounted() {
        for (const user of this.users) {
            delete (user as any).linkProcessing;
        }
    }
}
