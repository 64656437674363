import * as auth0 from 'auth0-js';
import { Auth0DecodedHash } from 'auth0-js';
import { localStorageLoad, localStorageRemove, localStorageSet } from '@/shared/utils/LocalStorageHelpers';

export class AuthService {
  private readonly webAuth: auth0.WebAuth;

  public constructor(clientID: string, domain: string) {
    this.webAuth = new auth0.WebAuth({
      domain,
      clientID,
    });
  }

  public handleAuth(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash((error, result) => {
        if (error) {
          reject(error);
        } else {
          AuthService.localLogin(result as Auth0DecodedHash);
          resolve();
        }
      });
    });
  }

  public static authData(): AuthData | null {
    const idToken = localStorageLoad('id_token');
    const idTokenExpiration = localStorageLoad('id_token_expiration');
    const profile = localStorageLoad('auth_profile');

    if (idToken && idTokenExpiration && profile) {
      const idTokenExpirationDate = new Date(parseInt(idTokenExpiration, 10));

      if (idTokenExpirationDate <= new Date()) {
        AuthService.localLogout();
      } else {
        return {
          idToken,
          idTokenExpiration: idTokenExpirationDate,
          profile: JSON.parse(profile),
        };
      }
    }

    return null;
  }

  public static localLogin(authResult: Auth0DecodedHash): void {
    const idToken = authResult.idToken;
    const profile = authResult.idTokenPayload;

    const tokenExpiry = new Date(profile.exp * 1000);

    localStorageSet('id_token', idToken as string);
    localStorageSet('auth_profile', JSON.stringify(profile));
    localStorageSet('id_token_expiration', tokenExpiry.getTime().toString());
  }

  public static localLogout() {
    localStorageRemove('id_token');
    localStorageRemove('auth_profile');
    localStorageRemove('id_token_expiration');
    localStorageRemove('adminProfile');
  }

  public logout(): void {
    AuthService.localLogout();
    this.webAuth.logout({
      returnTo: process.env.VUE_APP_LANDER_PAGE_URL,
    });
  }
}

export interface AuthProfile {
  exp: number;
  picture: string;
  aud: string;
  email: string;
  name: string;
  nickname: string;
  sub: string;
}

export interface AuthData {
  idToken: string;
  idTokenExpiration: Date;
  profile: AuthProfile;
}
