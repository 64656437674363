var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[(_vm.category)?[_vm._v(" Editing ")]:[_vm._v(" Create New ")],_vm._v(" Quiz Category for Quiz "),_c('span',{staticStyle:{"font-style":"italic"}},[_vm._v(_vm._s(_vm.quiz.name))])],2)]),_c('section',{staticClass:"modal-card-body"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"content"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","type":{ 'is-danger': errors.length > 0 },"message":{ 'Name is required': errors.length > 0 }}},[_c('b-input',{attrs:{"placeholder":"Enter name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Description","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Description","type":{ 'is-danger': errors.length > 0 },"message":{ 'Description is required': errors.length > 0 }}},[_c('rich-text-editor',{attrs:{"show-menu":"true","resizable":"true"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"SortOrder","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Sort Order","type":{ 'is-danger': errors.length > 0 },"message":{ 'Sort Order is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","min":"0","placeholder":"Enter quiz sort order"},model:{value:(_vm.sortOrder),callback:function ($$v) {_vm.sortOrder=$$v},expression:"sortOrder"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"NumberOfQuestions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Number of Questions","type":{ 'is-danger': errors.length > 0 },"message":{ 'Number of Questions is required': errors.length > 0 }}},[_c('b-input',{attrs:{"type":"number","min":"0","placeholder":"Enter number of category questions"},model:{value:(_vm.nrQuestions),callback:function ($$v) {_vm.nrQuestions=$$v},expression:"nrQuestions"}})],1)]}}],null,true)}),_c('div',{staticClass:"has-text-centered"},[_c('b-button',{staticStyle:{"margin":"10px 10px 10px 10px"},attrs:{"type":"is-warning"},on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v("Cancel ")]),_c('b-button',{staticStyle:{"margin":"10px 10px 10px 10px"},attrs:{"native-type":"submit","type":"is-success","loading":_vm.buttonLoading}},[_vm._v(" Save ")])],1)],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }