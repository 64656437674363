import { Vue } from 'vue-property-decorator';
import uuid from 'uuid';

type KeyboardEventListener = (e: KeyboardEvent) => Promise<void>;
const EVENT_LISTENER_MAP: Map<string, KeyboardEventListener> = new Map();

export default class ComponentBase extends Vue {
    protected async waitForChange(watchExpr: () => boolean): Promise<void> {
        return new Promise<void>(resolve => {
            const unwatch = this.$watch(watchExpr, (pending) => {
                if (pending) {
                    unwatch();
                    resolve();
                }
            }, { immediate: true });
        });
    }

    protected addCloseEventListener(action: KeyboardEventListener): string {
        const id = uuid.v4();

        const f = async (e: KeyboardEvent) => {
            if (e.code === 'Escape') {
                await action(e);
            }
        };

        EVENT_LISTENER_MAP.set(id, f);
        document.addEventListener('keydown', f);

        return id;
    }

    protected removeCloseEventListener(id: string | null) {
        if (id && EVENT_LISTENER_MAP.has(id)) {
            const f = EVENT_LISTENER_MAP.get(id) as KeyboardEventListener;
            document.removeEventListener('keydown', f);
        }
    }

    protected async copyToClipboard(property: string, data: string): Promise<void> {
        await navigator.clipboard.writeText(data);
        this.$buefy.toast.open(`Copied ${property} to Clipboard!`);
    }

    protected copyElementHtmlToClipboard(property: string, elementId: string) {
        const el = document.getElementById(elementId);
        if (el) {
            const data = el.innerHTML;
            this.copyHtmlToClipboard(property, data);
        }
    }

    protected copyHtmlToClipboard(property: string, data: string) {
        const listener = (e: ClipboardEvent) => {
            e.clipboardData?.setData('text/html', data);
            e.clipboardData?.setData('text/plain', data);
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.$buefy.toast.open(`Copied ${property} to Clipboard!`);
    }

    protected async safeExecuteWithConfirmation(
        job: () => Promise<void>,
        actionName: string,
        confirmationMessage: string,
        errorMessage: string,
        type: string,
    ): Promise<void> {
        const confirmed = await new Promise<boolean>(resolve => {
            this.$buefy.dialog.confirm({
                title: actionName,
                message: confirmationMessage,
                confirmText: 'Confirm',
                type: type,
                hasIcon: true,
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
            });
        });

        if (confirmed) {
            await this.safeExecute(job, errorMessage);
        }
    }

    protected async safeExecute(
        job: () => Promise<void>,
        errorMessage: string,
    ): Promise<void> {
        try {
            await job();
        } catch (e) {
            console.error(errorMessage, e);
            this.$buefy.dialog.alert({
                title: 'Error!',
                message: errorMessage,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
            });
        }
    }
}
