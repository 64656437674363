export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * Math.floor(max));
};

const dateTimeFormat = new Intl.DateTimeFormat("sl-SI", {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: false
});

const dateFormat = new Intl.DateTimeFormat("sl-SI", {
  year: "numeric",
  month: "numeric",
  day: "numeric"
});

const timeFormat = new Intl.DateTimeFormat("sl-SI", {
  hour: "numeric",
  minute: "numeric",
  hour12: false
});

const timezoneSloFormat = new Intl.DateTimeFormat("sl-SI", {
  timeZoneName: "short"
});

export const formatDateTime = (date: Date): string => {
  return dateTimeFormat.format(date);
};

export const formatDate = (date: Date): string => {
  return dateFormat.format(date);
};

export const formatTime = (date: Date): string => {
  return timeFormat.format(date);
};

export const formatTimezone = (date: Date): string => {
  const parts = timezoneSloFormat.formatToParts(date);
  const timeZonePart = parts.find(p => p.type === "timeZoneName");

  if (timeZonePart) {
    return timeZonePart.value;
  } else {
    throw Error("Something went wrong!");
  }
};

export const getWeekMonday = (date: Date): Date => {
  const dayOfWeek = date.getDay();

  if (dayOfWeek === 1) return date;

  const cloned = new Date(date.getTime());
  cloned.setDate(cloned.getDate() - (dayOfWeek - 1));
  return cloned;
};

export const getWeekSunday = (date: Date): Date => {
  const dayOfWeek = date.getDay();

  if (dayOfWeek === 0) return date;

  const cloned = new Date(date.getTime());
  cloned.setDate(cloned.getDate() + (6 - (dayOfWeek - 1)));
  return cloned;
};

export const getDateWithoutTime = (date: Date): Date => {
  return new Date(date.toDateString());
};

export const addDays = (date: Date, n: number): Date => {
  const cloned = new Date(date.getTime());
  cloned.setDate(cloned.getDate() + n);
  return cloned;
};

export const subtractDays = (date: Date, n: number): Date => {
  const cloned = new Date(date.getTime());
  cloned.setDate(cloned.getDate() - n);
  return cloned;
};

export const DAY_OF_WEEK_MAP: Map<number, string> = new Map([
  [0, "Sunday"],
  [1, "Monday"],
  [2, "Tuesday"],
  [3, "Wednesday"],
  [4, "Thursday"],
  [5, "Friday"],
  [6, "Saturday"]
]);

export const getUTCDate = (date: Date): Date => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};
