import { IApiAdminUser } from '@/admin/services/api/AdminService.dtos';

export enum ADMIN_GROUPS {
    SUPER_ADMIN = 'SUPER_ADMIN',
    TECHNICAL_INTERVIEWER = 'TECHNICAL_INTERVIEWER',
    PRE_SCREENING_INTERVIEWER = 'PRE_SCREENING_INTERVIEWER',
}

export enum ADMIN_PERMISSIONS {
    // talent related permissions
    TALENT_SEARCH = 'TALENT_SEARCH',
    TALENT_GET = 'TALENT_GET',
    TALENT_WAITLIST = 'TALENT_WAITLIST',
    TALENT_REJECT = 'TALENT_REJECT',
    TALENT_ACTIVATE = 'TALENT_ACTIVATE',
    TALENT_DEACTIVATE = 'TALENT_DEACTIVATE',
    TALENT_EDIT = 'TALENT_EDIT',
    TALENT_DELETE = 'TALENT_DELETE',
    TALENT_APPROVE = 'TALENT_APPROVE',
    TALENT_SET_TECHNICAL_INTERVIEW_RESULTS = 'TALENT_SET_TECHNICAL_INTERVIEW_RESULTS',
    TALENT_SET_FINAL_INTERVIEW_RESULTS = 'TALENT_SET_FINAL_INTERVIEW_RESULTS',
    TALENT_FAKE_HACKERRANK_RESULTS = 'TALENT_FAKE_HACKERRANK_RESULTS',
    GENERATE_RESTRICTED_TOKENS = 'GENERATE_RESTRICTED_TOKENS',

    // company related permissions
    COMPANY_SEARCH = 'COMPANY_SEARCH',
    COMPANY_GET = 'COMPANY_GET',
    COMPANY_WAITLIST = 'COMPANY_WAITLIST',
    COMPANY_REJECT = 'COMPANY_REJECT',
    COMPANY_ACTIVATE = 'COMPANY_ACTIVATE',
    COMPANY_DEACTIVATE = 'COMPANY_DEACTIVATE',
    COMPANY_DELETE = 'COMPANY_DELETE',
    COMPANY_APPROVE = 'COMPANY_APPROVE',
    COMPANY_EDIT = 'COMPANY_EDIT',

    // general admin permissions
    CALENDLY_DATA = 'CALENDLY_DATA',
    ANALYTICS_DATA = 'ANALYTICS_DATA',
    QUIZ_EDIT = 'QUIZ_EDIT',
    USER_NOTE_EDIT = 'USER_NOTE_EDIT',
    SEND_CUSTOMER_IO_EVENTS = 'SEND_CUSTOMER_IO_EVENTS',

    CLEAR_CACHE = 'CLEAR_CACHE',
    JOB_EDITOR = 'JOB_EDITOR',

    CALENDLY_SCHEDULER = 'CALENDLY_SCHEDULER',
}

export const checkPermission = (admin: IApiAdminUser, permission: ADMIN_PERMISSIONS): boolean => {
    return admin.permissions.map(p => p.name).includes(permission);
};

export const isSuperAdmin = (admin: IApiAdminUser): boolean => {
    return admin.groups.find(g => g.name === 'SUPER_ADMIN') !== undefined;
};
