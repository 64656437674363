






















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ALL_USER_STATES, USER_STATE, UUID } from '@/shared/data/constants';
import { USER_STATE_LABEL } from '@/shared/data/default-labels';
import TagInput from '@/admin/components/inputs/TagInput.vue';
import { Codetables, CodetableService, Country } from '@/shared/service/CodetableService';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { ArrayHelpers } from '@/shared/utils/ArrayHelpers';
import { getUTCDate } from '@/tests/utils/general.utils';
import { ISelectableItem, PageData } from '@/shared/data/types';
import { IApiBasicCompanyData } from '@/admin/services/api/AdminService.dtos';
import CompanyCard from '@/admin/components/CompanyCard.vue';

@Component({
    components: {
        TagInput,
        CompanyCard,
    },
})
export default class CompanySearch extends Vue {
    @Prop({ default: null })
    pickOne!: boolean | null;

    userId: UUID | null = null;
    email: string | null = null;
    name: string | null = null;
    firstName: string | null = null;
    lastName: string | null = null;
    country: string | null = null;
    registrationFromDate: Date | null = null;
    registrationToDate: Date | null = null;

    countries: Country[] = [];
    states: string[] = [];

    buttonLoading = false;
    dataLoading = false;
    nrItemsPerPage = 20;
    currentPageNumber = 1;

    pageData: PageData<ISelectableItem<IApiBasicCompanyData>> | null = null;
    searchResultsPerPage = this.nrItemsPerPage;

    get selectedUserIds(): UUID[] {
        if (this.pageData) {
            return this.pageData.data.filter(d => d.selected).map(d => d.item.userId);
        }
        return [];
    }

    formatter(d: Date): string {
        return d.toLocaleDateString();
    }

    get allUserStates() {
        return ALL_USER_STATES.map(s => USER_STATE_LABEL[s]);
    }

    get filteredCountries() {
        if (this.country !== null) {
            const filterCountry = this.country;
            return this.countries.filter(c => {
                return c.name.toLowerCase().indexOf(filterCountry.toLowerCase()) >= 0;
            }).map(c => c.name);
        }
        return [];
    }

    async onPageChange(page: number) {
        this.currentPageNumber = page;
        await this.refresh();
    }

    async mounted() {
        const results = await CodetableService.getCodetables(Codetables.COUNTRIES);

        this.countries = results.countries as Country[];
    }

    async search() {
        this.buttonLoading = true;
        try {
            await this.refresh(1);
        } catch (err) {
            console.error('Error during search!', err);
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error during search! ${err.message}`,
                type: 'is-danger',
            });
        }
        this.buttonLoading = false;
    }

    async companySelected(company: IApiBasicCompanyData) {
        if (this.pickOne === true) {
            this.$emit('close', company);
        }
    }

    async refresh(forcePage?: number) {
        this.dataLoading = true;

        try {
            this.pageData = {
                totalItems: 0,
                data: [],
            };

            const data = (await ADMIN_SERVICE.findCompanies(
                    {
                        userIds: this.userId ? [this.userId.trim()] : null,
                        states: this.states.map(s => ArrayHelpers.single(ALL_USER_STATES, (us) => USER_STATE_LABEL[us] === s) as USER_STATE),
                        name: this.name ? this.name.trim() : undefined,
                        firstName: this.firstName ? this.firstName.trim() : undefined,
                        lastName: this.lastName ? this.lastName.trim() : undefined,
                        country: this.country ? this.country.trim() : undefined,
                        email: this.email ? this.email.trim() : undefined,
                        registrationFromDate: this.registrationFromDate ? getUTCDate(this.registrationFromDate).toISOString() : undefined,
                        registrationToDate: this.registrationToDate ? getUTCDate(this.registrationToDate).toISOString() : undefined,
                    },
                    forcePage ? forcePage : this.currentPageNumber,
                    this.nrItemsPerPage,
            )) as PageData<IApiBasicCompanyData>;

            this.pageData = {
                totalItems: data.totalItems,
                data: data.data.map(d => {
                    return {
                        item: d,
                        selected: false,
                    };
                }),
            };

            this.searchResultsPerPage = this.nrItemsPerPage;
        } catch (err) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while refreshing data! ${err.message}`,
                type: 'is-danger',
            });
        }
        this.dataLoading = false;
    }

    async selectCompany() {
        if (this.pageData) {
            const selected = this.pageData.data.filter(d => d.selected);
            if (selected.length === 1) {
                this.$emit('close', selected[0].item);
            }
        }
    }
}
