



























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Codetables, CodetableService, QuestionDifficulty } from '@/shared/service/CodetableService';
import { QUESTION_STATE, QUESTION_TYPE, QUESTION_DIFFICULTY } from '@/shared/data/constants';
import { IApiQuiz, IApiQuizCategory, IApiQuizCategoryQuestion, IMultichoiceCodeSnippetQuestionMetadataV1, IMultichoiceQuestionMetadataV1 } from '@/admin/services/api/AdminQuizService.dtos';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

@Component({
    components: {
        RichTextEditor,
    }
})
export default class CreateNewQuizCategoryQuestionModal extends Vue {
    buttonLoading = false;

    @Prop()
    readonly quiz!: IApiQuiz;

    @Prop()
    readonly category!: IApiQuizCategory;

    @Prop({
        required: false,
        default: () => null,
    })
    readonly question!: IApiQuizCategoryQuestion | null;

    QUESTION_TYPE = QUESTION_TYPE;
    QUESTION_DIFFICULTY = QUESTION_DIFFICULTY;

    type: QUESTION_TYPE = QUESTION_TYPE.TALENT_SKILL_CHECK_MULTICHOICE;
    difficulty: QUESTION_DIFFICULTY = QUESTION_DIFFICULTY.EASY;
    title: string | null = null;
    imageUrl: string | null = null;
    choice1: string | null = null;
    choice2: string | null = null;
    choice3: string | null = null;
    choice4: string | null = null;
    correctAnswerIndex: number | null = null;
    availableTimeSeconds: number | null = null;
    correctPoints: number | null = null;
    incorrectPoints: number | null = null;

    allQuestionDifficulties: QuestionDifficulty[] = [];

    async mounted() {
        this.allQuestionDifficulties = (await CodetableService.getCodetables(Codetables.QUESTION_DIFFICULTIES)).question_difficulties as QuestionDifficulty[];
        this.setDefaults();

        if (this.question) {
            this.type = this.question.type;
            this.difficulty = this.question.difficulty;
            this.title = this.question.title;
            this.choice1 = this.question.metadata.choices[0];
            this.choice2 = this.question.metadata.choices[1];
            this.choice3 = this.question.metadata.choices[2];
            this.choice4 = this.question.metadata.choices[3];
            this.correctAnswerIndex = this.question.metadata.correctAnswerIndex;
            if (this.question.type === QUESTION_TYPE.TALENT_SKILL_CHECK_MULTICHOICE_CODE_SNIPPET) {
                this.imageUrl = (this.question.metadata as IMultichoiceCodeSnippetQuestionMetadataV1).imageUrl;
            }

            this.availableTimeSeconds = this.question.availableTimeSeconds;
            this.correctPoints = this.question.correctPoints;
            this.incorrectPoints = this.question.incorrectPoints;
        }
    }

    closeModal(refresh: boolean) {
        this.$emit('close', refresh);
    }

    async save() {
        this.buttonLoading = true;
        try {
            let metadata: IMultichoiceQuestionMetadataV1 | IMultichoiceCodeSnippetQuestionMetadataV1;

            if (this.type === QUESTION_TYPE.TALENT_SKILL_CHECK_MULTICHOICE_CODE_SNIPPET) {
                metadata = {
                    choices: [this.choice1 as string, this.choice2 as string, this.choice3 as string, this.choice4 as string],
                    correctAnswerIndex: this.correctAnswerIndex as number,
                    imageUrl: this.imageUrl as string,
                };
            } else if (this.type === QUESTION_TYPE.TALENT_SKILL_CHECK_MULTICHOICE) {
                metadata = {
                    choices: [this.choice1 as string, this.choice2 as string, this.choice3 as string, this.choice4 as string],
                    correctAnswerIndex: this.correctAnswerIndex as number,
                };
            } else {
                throw new Error(`${this.type} question type is not supported!`);
            }

            if (this.question) {
                await ADMIN_QUIZ_SERVICE.updateQuizCategoryQuestion(this.quiz.id, this.category.id, this.question.id, {
                    title: this.title as string,
                    type: this.type,
                    difficulty: this.difficulty,
                    metadata,
                    metadataVersion: 1,
                    state: this.question.state,
                    availableTimeSeconds: this.availableTimeSeconds as number,
                    correctPoints: this.correctPoints as number,
                    incorrectPoints: this.incorrectPoints as number,
                });
            } else {
                await ADMIN_QUIZ_SERVICE.createQuizCategoryQuestion(this.quiz.id, this.category.id, {
                    title: this.title as string,
                    type: this.type,
                    difficulty: this.difficulty,
                    metadata,
                    metadataVersion: 1,
                    state: QUESTION_STATE.DRAFT,
                    availableTimeSeconds: this.availableTimeSeconds as number,
                    correctPoints: this.correctPoints as number,
                    incorrectPoints: this.incorrectPoints as number,
                });
            }

            this.closeModal(true);
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: e.message,
                type: 'is-danger',
            });
        }
        this.buttonLoading = false;
    }

    setDefaults() {
        const difficultyCodetables = this.allQuestionDifficulties.filter((d) => d.name === this.difficulty);
        if (difficultyCodetables.length !== 1) {
            throw new Error('Something is wrong here!');
        }
        const d = difficultyCodetables[0];

        this.availableTimeSeconds = d.defaultAvailableTimeSeconds;
        this.correctPoints = d.defaultCorrectPoints;
        this.incorrectPoints = d.defaultIncorrectPoints;
    }
}
