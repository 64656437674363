

















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ADMIN_ROUTES } from '@/admin/router';
import {
    IApiJob,
    IApiJobData,
    IApiJobModifyRequest,
    IApiJobSectionData,
} from '@/admin/services/api/AdminJobService.dtos';
import { ADMIN_JOB_SERVICE } from '@/admin/services/api/AdminJobService';
import {
    Codetables,
    CodetableService,
    Country,
    Currency,
    DeveloperRole,
    Skill,
    TimeZone,
} from '@/shared/service/CodetableService';
import { JOB_STATE, UUID } from '@/shared/data/constants';
import { JobBenefits, JobBenefitsData } from '@/admin/views/dashboard/views/jobs/Jobs.dtos';
import TagInput from '@/admin/components/inputs/TagInput.vue';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

interface JobsForm {
    positionTitle: string;
    skills: { name: string, level: string }[];
    tech: string[];
    role: string;
    quantity: number | '';
    countries: string[];
    salaryCurrency: string;
    salaryFrom: number | null;
    salaryTo: number | null;
    salaryText: string | null;
    salaryAsText: boolean;
    salaryEquity: boolean;
    salaryEquityFrom: number | '';
    salaryEquityTo: number | '';
    salaryEquityUnknown: boolean;
    timezoneFrom: string | null;
    timezoneTo: string | null;
    worldwide: boolean | null;
    textSections: IApiJobSectionData[];
    conditionsHtml: string;
    goodToHave: string;
    benefits: string[];
    contractType: string;
}

interface JobsAddForm {
    state: JOB_STATE,
    featured: boolean,
    sortOrder: number,
}

@Component({
    components: {
        TagInput,
        RichTextEditor,
    },
})
export default class JobsEdit extends Vue {
    jobId?: string;
    job?: IApiJob | null = null;

    JobBenefitsData = JobBenefitsData;

    timezones: TimeZone[] = [];
    roles: DeveloperRole[] = [];
    currency: Currency[] = [];
    countries: string[] = [];
    allSkills: string[] = [];

    salaryRangeDisabled = false;

    form: JobsForm = {
        positionTitle: '',
        skills: [],
        countries: [],
        tech: [],
        role: '',
        quantity: '',
        salaryCurrency: '',
        salaryFrom: null,
        salaryTo: null,
        salaryEquity: false,
        salaryEquityFrom: '',
        salaryText: null,
        salaryAsText: false,
        salaryEquityTo: '',
        salaryEquityUnknown: false,
        timezoneFrom: null,
        timezoneTo: null,
        worldwide: null,
        textSections: [],
        conditionsHtml: '',
        goodToHave: '',
        benefits: [],
        contractType: '',
    };

    addForm: JobsAddForm = {
        state: JOB_STATE.ACTIVE,
        featured: false,
        sortOrder: 0,
    };

    skillsForm: {
        search: string,
        skill: string | null,
        level: string,
    } = {
        search: '',
        skill: null,
        level: '',
    };

    search = {
        company: '',
    };

    filteredSkills: string[] = [];

    get JobStates() {
        return Object.values(JOB_STATE);
    }

    get sortedSections() {
        return (this.form.textSections || []).sort((a, b) => a.order - b.order);
    }

    get benefits() {
        return Object.values(JobBenefits);
    }

    skillNames(text: string) {
        this.filteredSkills = this.allSkills.filter((skill) => {
            return skill.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        });
    }

    selectSkill(skill: string) {
        if (skill) {
            this.skillsForm.skill = skill;
        } else {
            this.skillsForm.skill = null;
        }
    }

    moveSectionDown(section: IApiJobSectionData) {
        const nextSection = this.form.textSections.find(t => t.order === (section.order + 1));

        if (nextSection) {
            section.order = nextSection.order;
            nextSection.order = section.order - 1;
        }
    }

    moveSectionUp(section: IApiJobSectionData) {
        const previousSection = this.form.textSections.find(t => t.order === (section.order - 1));

        if (previousSection) {
            section.order = previousSection.order;
            previousSection.order = section.order + 1;
        }
    }

    removeSection(orderNumber: number) {
        this.form.textSections = this.form.textSections.filter(t => t.order !== orderNumber);
        this.reorderSections();
    }

    addNewSection() {
        this.reorderSections();

        let latestOrderNumber = 0;

        if (this.form.textSections.length > 0) {
            latestOrderNumber = this.form.textSections[this.form.textSections.length - 1].order;
        }

        this.form.textSections.push({
            name: '',
            order: latestOrderNumber + 1,
            htmlText: '',
        });
    }

    reorderSections() {
        let i = 1;
        for (const section of this.form.textSections) {
            section.order = i++;
        }
    }

    addSkill() {
        if (this.skillsForm.skill && this.skillsForm.level && this.form.skills.find(s => s.name === this.skillsForm.skill) === undefined) {
            this.form.skills.push({
                name: this.skillsForm.skill,
                level: this.skillsForm.level,
            });
            this.skillsForm.skill = null;
            this.skillsForm.level = '';
            this.skillsForm.search = '';
        }
    }

    removeSkill(skill: string) {
        this.form.skills = this.form.skills.filter(s => s.name !== skill);
    }

    worldwideChanged() {
        this.form.timezoneFrom = null;
        this.form.timezoneTo = null;
        this.form.countries = [];
    }

    salaryAsTextChanged(val: boolean) {
        if (val) {
            this.form.salaryFrom = null;
            this.form.salaryTo = null;
            this.salaryRangeDisabled = true;
        } else {
            this.form.salaryText = null;
            this.salaryRangeDisabled = false;
        }
    }

    async save() {
        if (!this.form.worldwide && (!this.form.timezoneTo || !this.form.timezoneFrom)) {
            this.$buefy.dialog.alert({
                title: 'Incorrect values!',
                type: 'is-danger',
                message: 'If worldwide is not checked you have to select timezone range!',
            });
            return;
        }

        if (this.form.salaryAsText && (!this.form.salaryText || this.form.salaryText.length === 0)) {
            this.$buefy.dialog.alert({
                title: 'Incorrect values!',
                type: 'is-danger',
                message: 'If salary as text is checked you have to enter a text value!',
            });
            return;
        }

        if (!this.form.salaryAsText && (!this.form.salaryFrom || !this.form.salaryTo)) {
            this.$buefy.dialog.alert({
                title: 'Incorrect values!',
                type: 'is-danger',
                message: 'If salary as text is not checked you have to enter salary range!',
            });
            return;
        }

        this.$buefy.dialog.confirm({
            title: 'Save changes?',
            message: 'Do you really want to save changes?',
            confirmText: 'Confirm',
            type: 'is-warning',
            hasIcon: true,
            onConfirm: async () => {
                try {
                    if (this.jobId) {
                        const data: IApiJobModifyRequest = {
                            dataVersion: 1,
                            data: this.form as IApiJobData,
                            state: this.addForm.state,
                            featured: this.addForm.featured,
                            sortOrder: this.addForm.sortOrder,
                            companyUserId: this.job?.companyUserId as UUID,
                        };
                        await ADMIN_JOB_SERVICE.updateJob(this.jobId, data);

                        await this.$router.push({ name: ADMIN_ROUTES.JOBS });
                    }

                } catch (err) {
                    this.$buefy.dialog.alert({
                        title: 'Error while saving!',
                        message: 'Error while creating job position! Please check browser logs!',
                        type: 'is-danger',
                        hasIcon: true,
                        icon: 'times-circle',
                    });
                }
            },
            onCancel: () => null,
        });

    }

    async mounted() {
        const codetables = await CodetableService.getCodetables(Codetables.TIMEZONES, Codetables.SKILLS, Codetables.DEVELOPER_ROLES, Codetables.CURRENCIES, Codetables.COUNTRIES);
        this.timezones = codetables.timezones as TimeZone[];
        this.allSkills = (codetables.skills as Skill[]).map((s) => s.name);
        this.roles = (codetables.developer_roles as DeveloperRole[]);
        this.currency = (codetables.currencies as Currency[]);
        this.countries = (codetables.countries as Country[]).map(c => c.name);

        if (this.$route.params.jobId) {
            this.jobId = this.$route.params.jobId;
            this.job = await ADMIN_JOB_SERVICE.getJob(this.jobId);
            this.form = this.job.data as JobsForm;

            if (!this.form.salaryAsText) {
                this.form.salaryAsText = false;
                this.salaryRangeDisabled = false;
            } else {
                this.salaryRangeDisabled = true;
            }

            if (!this.form.worldwide) {
                this.form.worldwide = false;
            }

            if (!Array.isArray(this.form.countries)) {
                this.form.countries = [];
            }

            this.search.company = this.job.company.data.name;
            this.addForm.featured = this.job.featured;
            this.addForm.sortOrder = this.job.sortOrder;
            this.addForm.state = this.job.state;
        }
    }
}
