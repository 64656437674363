



















































































































































































































































































import { Component, Prop } from 'vue-property-decorator';
import {
    IApiAdminUser,
    IApiBasicTalentData,
    IApiTechnicalInterviewPositionRecommendation,
    IApiUserVettingRecord,
} from '@/admin/services/api/AdminService.dtos';
import { CALENDLY_LINK_TYPE_LABEL, DEVELOPER_ROLE_LABEL, SENIORITY_LEVEL_LABEL } from '@/shared/data/default-labels';
import {
    CALENDLY_LINK_TYPE,
    DEVELOPER_ROLE,
    INTERVIEW_POSITION_RECOMMENDATION_LEVEL,
    SENIORITY_LEVEL,
} from '@/shared/data/constants';
import dayjs from 'dayjs';
import { Getter } from 'vuex-class';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { ADMIN_PERMISSIONS, checkPermission } from '@/admin/services/PermissionService';
import ComponentBase from '@/admin/components/ComponentBase';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import SetOrEditTechnicalInterviewResults
    from '@/admin/views/dashboard/views/modals/technical-interview/v3/SetOrEditTechnicalInterviewResults.vue';

@Component({
    components: {},
})
export default class TechnicalInterview extends ComponentBase {
    @Prop()
    readonly record!: IApiUserVettingRecord;

    @Prop()
    readonly user!: IApiBasicTalentData;

    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;

    DEVELOPER_ROLE_LABEL = DEVELOPER_ROLE_LABEL;
    SENIORITY_LEVEL_LABEL = SENIORITY_LEVEL_LABEL;

    showDetails = false;

    get hasEditPermission(): boolean {
        return checkPermission(this.profile, ADMIN_PERMISSIONS.TALENT_EDIT);
    }

    get interviewTypeLabel(): string {
        if (this.record.metadata?.type && CALENDLY_LINK_TYPE_LABEL[this.record.metadata?.type as CALENDLY_LINK_TYPE]) {
            return CALENDLY_LINK_TYPE_LABEL[this.record.metadata?.type as CALENDLY_LINK_TYPE] as string;
        } else {
            return 'Technical Interview';
        }
    }

    get scheduledDateLabel(): string {
        if (this.record.scheduledOn) {
            return dayjs(this.record.scheduledOn).format('ddd MMM D, h:mm A UTCZ');
        } else {
            return 'Not available!';
        }
    }

    get scoreMark(): string {
        if (this.record.results) {
            if (this.record.results.version === 2 || this.record.results.version === 1) {
                return SENIORITY_LEVEL_LABEL[this.record.results.seniorityLevel as SENIORITY_LEVEL];
            } else if (this.record.results.version === 3) {
                const recommendations = this.record.results.positionRecommendations as IApiTechnicalInterviewPositionRecommendation[];

                let recommendedPositions: string[] = [];

                for (const rec of recommendations) {
                    if (rec.decision.name === INTERVIEW_POSITION_RECOMMENDATION_LEVEL.WOULD_RECOMMEND) {
                        recommendedPositions.push(`${rec.position.displayName} (✓)`);
                    } else if (rec.decision.name === INTERVIEW_POSITION_RECOMMENDATION_LEVEL.HIGHLY_RECOMMEND) {
                        recommendedPositions.push(`${rec.position.displayName} (✓✓)`);
                    }
                }

                return recommendedPositions.join(' ');
            }
        }
        return '';
    }

    async deleteRecord() {
        await this.safeExecuteWithConfirmation(async () => {
                await ADMIN_SERVICE.removeVettingRecord(this.record.id);
                this.$emit('refresh-user');
            },
            'Deleting Vetting Record',
            'Are you 100% sure you want to delete this vetting record?',
            'Failed to delete this vetting record! Please check logs/refresh page!',
            'is-danger');
    }

    async editResults() {
        let type: CALENDLY_LINK_TYPE | undefined = this.record.metadata.type;
        if (!type) {
            switch (this.record.results.role) {
                case DEVELOPER_ROLE.BACKEND:
                    type = CALENDLY_LINK_TYPE.BACKEND_TECHNICAL_INTERVIEW;
                    break;
                case DEVELOPER_ROLE.FRONTEND:
                    type = CALENDLY_LINK_TYPE.FRONTEND_TECHNICAL_INTERVIEW;
                    break;
                case DEVELOPER_ROLE.BLOCKCHAIN_ETH:
                    type = CALENDLY_LINK_TYPE.BLOCKCHAIN_ETH_ENGINEER_TECHNICAL_INTERVIEW;
                    break;
                case DEVELOPER_ROLE.DATA_SCIENCE:
                    type = CALENDLY_LINK_TYPE.DATA_ENGINEER_TECHNICAL_INTERVIEW;
                    break;
                case DEVELOPER_ROLE.MOBILE:
                    type = CALENDLY_LINK_TYPE.FLUTTER_TECHNICAL_INTERVIEW;
                    break;
                case DEVELOPER_ROLE.DEVOPS:
                    type = CALENDLY_LINK_TYPE.DEVOPS_KUBERNETES_TECHNICAL_INTERVIEW;
                    break;
                default:
                    throw new Error(`Role '${this.record.results.role}' is not yet mapped!`);
            }
        }

        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: SetOrEditTechnicalInterviewResults,
                props: {
                    existingRecord: this.record,
                    user: this.user,
                    type: type,
                    eventId: '',
                    scheduledOn: '',
                },
                hasModalCard: true,
                parent: this,
                canCancel: false,
                fullScreen: true,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });
        this.$emit('refresh-user');
    }
}
