import { AuthData } from '@/shared/service/AuthService';
import { Module } from 'vuex';
import { COMMON_MUTATIONS, XCommonState } from '@/shared/store';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';
import { ADMIN_USER_TAG_SERVICE } from '@/admin/services/api/AdminUserTagService';
import { ADMIN_USER_NOTE_SERVICE } from '@/admin/services/api/AdminUserNoteService';
import { ADMIN_JOB_SERVICE } from '@/admin/services/api/AdminJobService';
import { ADMIN_CALENDLY_SERVICE } from '@/admin/services/api/AdminCalendlyService';
import { IApiAdminUser } from '@/admin/services/api/AdminService.dtos';
import { localStorageLoad, localStorageSet } from '@/shared/utils/LocalStorageHelpers';

export enum ADMIN_GETTERS {
  PROFILE = 'PROFILE',
}

export enum ADMIN_MUTATIONS {
  SET_PROFILE = 'SET_PROFILE'
}

export enum ADMIN_ACTIONS {
  PROCESS_LOGIN = 'PROCESS_LOGIN',
}

export interface XAdminState {
  profile: IApiAdminUser | null;
}

export const ADMIN_NAMESPACE = 'ADMIN';

export const adminNamespace = (storeEntity: ADMIN_ACTIONS | ADMIN_MUTATIONS | ADMIN_GETTERS): string => {
  return `${ADMIN_NAMESPACE}/${storeEntity}`;
};

export const adminModule: Module<XAdminState, XCommonState> = {
  namespaced: true,
  state: {
    profile: null,
  },
  getters: {
    [ADMIN_GETTERS.PROFILE]: (state) => state.profile,
  },
  mutations: {
    [ADMIN_MUTATIONS.SET_PROFILE]: (state, payload: IApiAdminUser) => {
      state.profile = payload;
    },
  },
  actions: {
    [ADMIN_ACTIONS.PROCESS_LOGIN]: async (ctx, payload: AuthData) => {
      ctx.commit(COMMON_MUTATIONS.LOGIN, payload, { root: true });
      ADMIN_SERVICE.authenticate(payload.idToken);
      ADMIN_QUIZ_SERVICE.authenticate(payload.idToken);
      ADMIN_USER_TAG_SERVICE.authenticate(payload.idToken);
      ADMIN_USER_NOTE_SERVICE.authenticate(payload.idToken);
      ADMIN_JOB_SERVICE.authenticate(payload.idToken);
      ADMIN_CALENDLY_SERVICE.authenticate(payload.idToken);

      const cachedProfile = localStorageLoad('adminProfile');

      let adminProfile: IApiAdminUser | null = null;
      const now = new Date();
      if (cachedProfile) {
        const res = JSON.parse(cachedProfile);

        if (res.profile.email === payload.profile.email) {
          const timeDiffMs = now.getTime() - new Date(res.cachingDate).getTime();
          const diffMins = Math.round(((timeDiffMs % 86400000) % 3600000) / 60000); // minutes
          if (diffMins < 30) {
            adminProfile = res.profile;
          }
        }
      }

      if (adminProfile === null) {
        adminProfile = await ADMIN_SERVICE.getProfile();
        localStorageSet('adminProfile', JSON.stringify({
          profile: adminProfile,
          cachingDate: now.toISOString(),
        }));
      }

      ctx.commit(ADMIN_MUTATIONS.SET_PROFILE, adminProfile);
    },
  },
};
