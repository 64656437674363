






































import { Component, Prop } from 'vue-property-decorator';
import { IApiAdminUser, IApiBasicTalentData, IApiUserVettingRecord } from '@/admin/services/api/AdminService.dtos';
import dayjs from 'dayjs';
import { ADMIN_PERMISSIONS, checkPermission } from '@/admin/services/PermissionService';
import { ADMIN_GETTERS, adminNamespace } from '@/admin/store';
import { Getter } from 'vuex-class';
import SetOrEditFinalInterviewResults from '@/admin/views/dashboard/views/modals/SetOrEditFinalInterviewResults.vue';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';
import ComponentBase from '@/admin/components/ComponentBase';

@Component({})
export default class FinalInterview extends ComponentBase {
    @Prop()
    readonly user!: IApiBasicTalentData;

    @Prop()
    readonly record!: IApiUserVettingRecord;

    @Getter(adminNamespace(ADMIN_GETTERS.PROFILE)) profile!: IApiAdminUser;

    get hasEditPermission(): boolean {
        return checkPermission(this.profile, ADMIN_PERMISSIONS.TALENT_EDIT);
    }

    get scheduledDateLabel(): string {
        if (this.record.scheduledOn) {
            return dayjs(this.record.scheduledOn).format('ddd MMM D, h:mm A UTCZ');
        }
        return '/';
    }

    get summaryText(): string {
        if (this.record.results?.summary) {
            return this.record.results.summary;
        } else {
            return 'No summary!';
        }
    }

    get passedLabel(): string {
        if (this.record.success === true) {
            return 'Passed';
        } else {
            return 'Failed';
        }
    }

    showSummary() {
        this.$buefy.dialog.alert(this.summaryText);
    }

    async deleteRecord() {
        await this.safeExecuteWithConfirmation(async () => {
                    await ADMIN_SERVICE.removeVettingRecord(this.record.id);
                    this.$emit('refresh-user');
                },
                'Deleting Vetting Record',
                'Are you 100% sure you want to delete this vetting record?',
                'Failed to delete this vetting record! Please check logs/refresh page!',
                'is-danger');
    }

    async editResults() {
        await new Promise<void>((resolve) => {
            this.$buefy.modal.open({
                component: SetOrEditFinalInterviewResults,
                props: {
                    existingRecord: this.record,
                    user: this.user,
                    eventId: '',
                    scheduledOn: '',
                },
                hasModalCard: true,
                parent: this,
                canCancel: false,
                trapFocus: true,
                events: {
                    close: () => {
                        resolve();
                    },
                },
            });
        });
        this.$emit('refresh-user');
    }
}
