








































import { Component, Vue } from 'vue-property-decorator';
import { UUID } from '@/shared/data/constants';
import GenerateNewQuizCategoryScoreLevelsModal from '@/admin/views/dashboard/views/quizzes/modals/GenerateNewQuizCategoryScoreLevelsModal.vue';
import { IApiQuiz, IApiQuizCategory, IApiQuizCategoryScoreLevel } from '@/admin/services/api/AdminQuizService.dtos';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';

@Component({})
export default class QuizCategoryScoreLevels extends Vue {
    quizId: UUID | null = null;
    quiz: IApiQuiz | null = null;
    categoryId: UUID | null = null;
    category: IApiQuizCategory | null = null;
    scoreLevels: IApiQuizCategoryScoreLevel[] = [];
    dataLoading = false;
    levels: number[] = [];

    async refresh() {
        try {
            this.dataLoading = true;
            this.scoreLevels = await ADMIN_QUIZ_SERVICE.getAllQuizCategoryScoreLevels(this.quizId as string, this.categoryId as string);
            this.levels = this.scoreLevels.map((d) => d.level);
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while fetching quiz category score levels! ${e.message}`,
                type: 'is-danger',
            });
        } finally {
            this.dataLoading = false;
        }
    }

    async mounted() {
        this.quizId = this.$route.params.quizId;
        this.categoryId = this.$route.params.categoryId;

        this.quiz = await ADMIN_QUIZ_SERVICE.getQuiz(this.quizId);
        this.category = await ADMIN_QUIZ_SERVICE.getQuizCategory(this.quizId, this.categoryId);

        await this.refresh();
    }

    async generateScoreLevels() {
        const refresh = await new Promise<boolean>((resolve) => {
            this.$buefy.modal.open({
                component: GenerateNewQuizCategoryScoreLevelsModal,
                props: {
                    quiz: this.quiz,
                    category: this.category,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                fullScreen: true,
                events: {
                    close: (refreshParent: boolean) => {
                        resolve(refreshParent);
                    },
                },
            });
        });

        if (refresh) {
            await this.refresh();
        }
    }
}
