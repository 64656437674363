import { ApiServiceBase } from '@/shared/service/ServiceBase';
import { IApiCreateUserNoteRequest, IApiUpdateUserNoteRequest } from '@/admin/services/api/AdminUserNoteService.dtos';
import { UUID } from '@/shared/data/constants';

export class AdminUserNoteService extends ApiServiceBase {
  public constructor(baseApiUrl?: string) {
    super(baseApiUrl || '/api/user-service/admin/usernotes');
  }

  public async createNote(req: IApiCreateUserNoteRequest): Promise<UUID> {
    const response = await this.safePostResponse('/', req);
    return response.text;
  }

  public async updateNote(noteId: UUID, req: IApiUpdateUserNoteRequest): Promise<void> {
    await this.safePatchResponse(`/${noteId}`, req);
  }

  public async deleteNote(noteId: UUID): Promise<void> {
    await this.safeDeleteResponse(`/${noteId}`);
  }
}

export const ADMIN_USER_NOTE_SERVICE = new AdminUserNoteService();
