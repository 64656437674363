import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AdminAuth from '../views/auth/AdminAuth.vue';
import Callback from '../views/auth/Callback.vue';
import NotFound from '../../shared/components/NotFound.vue';
import Auth from '../views/auth/Auth.vue';
import { authGuard, noAuthGuard } from '@/shared/router';
import Dashboard from '@/admin/views/dashboard/Dashboard.vue';
import TalentSearch from '@/admin/views/dashboard/views/TalentSearch.vue';
import Quizzes from '@/admin/views/dashboard/views/quizzes/Quizzes.vue';
import Jobs from '@/admin/views/dashboard/views/jobs/Jobs.vue';
import QuizCategories from '@/admin/views/dashboard/views/quizzes/QuizCategories.vue';
import QuizCategoryScoreLevels from '@/admin/views/dashboard/views/quizzes/QuizCategoryScoreLevels.vue';
import QuizCategoryQuestions from '@/admin/views/dashboard/views/quizzes/QuizCategoryQuestions.vue';
import InterviewSchedule from '@/admin/views/dashboard/views/scheduling/InterviewSchedule.vue';
import JobsCreate from '@/admin/views/dashboard/views/jobs/JobsCreate.vue';
import JobsEdit from '@/admin/views/dashboard/views/jobs/JobsEdit.vue';
import CompanySearch from '@/admin/views/dashboard/views/CompanySearch.vue';
import RestrictedTokenGenerator from '@/admin/views/dashboard/OneTimeTokenGenerator.vue';

Vue.use(VueRouter);

export enum ADMIN_ROUTES {
    AUTH = 'AUTH',
    AUTH_CALLBACK = 'AUTH_CALLBACK',

    TALENT_SEARCH = 'TALENT_SEARCH',
    COMPANY_SEARCH = 'COMPANY_SEARCH',
    QUIZZES = 'QUIZZES',
    QUIZ_CATEGORIES = 'QUIZ_CATEGORIES',
    QUIZ_CATEGORY_SCORE_LEVELS = 'QUIZ_CATEGORY_SCORE_LEVELS',
    QUIZ_CATEGORY_QUESTIONS = 'QUIZ_CATEGORY_QUESTIONS',
    JOBS = 'JOBS',
    EDIT_JOB = 'EDIT_JOB',
    CREATE_JOB = 'CREATE_JOB',
    INTERVIEW_SCHEDULE = 'INTERVIEW_SCHEDULE',
    RESTRICTED_TOKEN_GENERATOR = 'RESTRICTED_TOKEN_GENERATOR',
}

const routes: RouteConfig[] = [
    {
        path: '/auth',
        beforeEnter: noAuthGuard,
        component: Auth,
        children: [
            {
                path: 'login',
                component: AdminAuth,
                name: ADMIN_ROUTES.AUTH,
            },
            {
                path: 'callback',
                name: ADMIN_ROUTES.AUTH_CALLBACK,
                component: Callback,
            },
        ],
    },
    {
        path: '/',
        beforeEnter: authGuard(ADMIN_ROUTES.AUTH),
        component: Dashboard,
        children: [
            {
                path: 'talent-search',
                name: ADMIN_ROUTES.TALENT_SEARCH,
                component: TalentSearch,
            },
            {
                path: 'company-search',
                name: ADMIN_ROUTES.COMPANY_SEARCH,
                component: CompanySearch,
            },
            {
                path: 'restricted-token-generator',
                name: ADMIN_ROUTES.RESTRICTED_TOKEN_GENERATOR,
                component: RestrictedTokenGenerator,
            },
            {
                path: 'jobs',
                name: ADMIN_ROUTES.JOBS,
                component: Jobs,
            },
            {
                path: 'jobs/new',
                name: ADMIN_ROUTES.CREATE_JOB,
                component: JobsCreate,
            },
            {
                path: 'jobs/edit/:jobId',
                name: ADMIN_ROUTES.EDIT_JOB,
                component: JobsEdit,
            },
            {
                path: 'quizzes',
                name: ADMIN_ROUTES.QUIZZES,
                component: Quizzes,
            },
            {
                path: 'quiz-categories/:quizId',
                name: ADMIN_ROUTES.QUIZ_CATEGORIES,
                component: QuizCategories,
            },
            {
                path: 'quiz-categories/:quizId/score-levels/:categoryId',
                name: ADMIN_ROUTES.QUIZ_CATEGORY_SCORE_LEVELS,
                component: QuizCategoryScoreLevels,
            },
            {
                path: 'quiz-categories/:quizId/questions/:categoryId',
                name: ADMIN_ROUTES.QUIZ_CATEGORY_QUESTIONS,
                component: QuizCategoryQuestions,
            },
            {
                path: 'scheduler',
                name: ADMIN_ROUTES.INTERVIEW_SCHEDULE,
                component: InterviewSchedule,
            },
            {
                path: '*',
                redirect: { name: ADMIN_ROUTES.TALENT_SEARCH },
            },
        ],
    },
    {
        path: '*',
        component: NotFound,
        beforeEnter: authGuard(ADMIN_ROUTES.AUTH),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
