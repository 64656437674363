












































































import { Component, Vue } from 'vue-property-decorator';
import { CATEGORY_STATE, UUID } from '@/shared/data/constants';
import CreateNewQuizCategoryModal from '@/admin/views/dashboard/views/quizzes/modals/CreateNewQuizCategoryModal.vue';
import { ADMIN_ROUTES } from '@/admin/router';
import { IApiQuiz, IApiQuizCategory } from '@/admin/services/api/AdminQuizService.dtos';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';

@Component({})
export default class QuizCategories extends Vue {
    dataLoading = false;
    quiz: IApiQuiz | null = null;
    categories: IApiQuizCategory[] = [];
    ids: UUID[] = [];
    quizId: UUID | null = null;
    CATEGORY_STATE = CATEGORY_STATE;

    async refresh() {
        try {
            this.dataLoading = true;
            this.categories = await ADMIN_QUIZ_SERVICE.getAllQuizCategories(this.quizId as string);
            this.ids = this.categories.map((d) => d.id);
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while fetching categories! ${e.message}`,
                type: 'is-danger',
            });
        } finally {
            this.dataLoading = false;
        }
    }

    async mounted() {
        this.quizId = this.$route.params.quizId;
        this.quiz = await ADMIN_QUIZ_SERVICE.getQuiz(this.quizId as string);

        await this.refresh();
    }

    async editCategory(category: IApiQuizCategory) {
        const refresh = await new Promise<boolean>((resolve) => {
            this.$buefy.modal.open({
                component: CreateNewQuizCategoryModal,
                props: {
                    quiz: this.quiz,
                    category: category,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: (refreshParent: boolean) => {
                        resolve(refreshParent);
                    },
                },
            });
        });

        if (refresh) {
            await this.refresh();
        }
    }

    async createNewCategory() {
        const refresh = await new Promise<boolean>((resolve) => {
            this.$buefy.modal.open({
                component: CreateNewQuizCategoryModal,
                props: {
                    quiz: this.quiz,
                },
                hasModalCard: true,
                parent: this,
                trapFocus: true,
                events: {
                    close: (refreshParent: boolean) => {
                        resolve(refreshParent);
                    },
                },
            });
        });

        if (refresh) {
            await this.refresh();
        }
    }

    async editCategoryScoreLevels(categoryId: UUID) {
        await this.$router.push({
            name: ADMIN_ROUTES.QUIZ_CATEGORY_SCORE_LEVELS,
            params: {
                quizId: this.quizId as string,
                categoryId,
            },
        });
    }

    async editCategoryQuestions(categoryId: UUID) {
        await this.$router.push({
            name: ADMIN_ROUTES.QUIZ_CATEGORY_QUESTIONS,
            params: {
                quizId: this.quizId as string,
                categoryId,
            },
        });
    }

    async activateCategory(category: IApiQuizCategory) {
        try {
            await ADMIN_QUIZ_SERVICE.updateQuizCategoryState(category, CATEGORY_STATE.ACTIVE);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: e.message,
                type: 'is-danger',
            });
        }
    }

    async putCategoryToDraft(category: IApiQuizCategory) {
        try {
            await ADMIN_QUIZ_SERVICE.updateQuizCategoryState(category, CATEGORY_STATE.DRAFT);
            await this.refresh();
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: e.message,
                type: 'is-danger',
            });
        }
    }
}
