import request from 'superagent';
import { DEFAULT_TIMEOUT, ServiceBase } from '@/shared/service/ServiceBase';
import { ADMIN_GROUPS, ADMIN_PERMISSIONS } from '@/admin/services/PermissionService';
import { localStorageLoad, localStorageSet } from '@/shared/utils/LocalStorageHelpers';
import {
  DOMAIN_EXPERIENCE_LEVEL,
  ENGLISH_LEVEL,
  INTERVIEW_POSITION_RECOMMENDATION_LEVEL,
  PROBLEM_SOLVING_LEVEL,
  PRODUCTIVITY_LEVEL,
  TASK_RESULT_LEVEL,
} from '@/shared/data/constants';

interface CachedValue {
    cachingDate: string;
    value: any;
}

const CACHE_TIME_LIMIT_MINUTES = 30;

export class CodetableService extends ServiceBase {
    private static apiUrl(url: string): string {
        return `/api/user-service/public/codetable${url}`;
    }

    private static getCache(): Map<Codetables, CachedValue> {
        const res = localStorageLoad('codetableCache');
        if (res) {
            return new Map<Codetables, CachedValue>(JSON.parse(res));
        }

        return new Map<Codetables, CachedValue>();
    }

    public static async getCodetables(...codetables: Codetables[]): Promise<CodetableResult> {
        const now = new Date();
        const cache = this.getCache();

        const resultPayload: any = {};
        const codetablesToFetch: Codetables[] = [];

        for (const reqCt of codetables) {
            if (cache.has(reqCt)) {
                const cachedValue = cache.get(reqCt) as CachedValue;
                const timeDiffMs = now.getTime() - new Date(cachedValue.cachingDate).getTime();
                const diffMins = Math.round(((timeDiffMs % 86400000) % 3600000) / 60000); // minutes
                if (diffMins < CACHE_TIME_LIMIT_MINUTES) {
                    resultPayload[reqCt] = cachedValue.value;
                    continue;
                }
            }

            codetablesToFetch.push(reqCt);
        }

        if (codetablesToFetch.length > 0) {
            const result = await request
                .get(this.apiUrl('/'))
                .query(`codetables=${codetablesToFetch.join(',')}`)
                .timeout(DEFAULT_TIMEOUT);

            for (const fetchedCt of codetablesToFetch) {
                cache.set(fetchedCt, {
                    cachingDate: now.toISOString(),
                    value: result.body[fetchedCt],
                });

                resultPayload[fetchedCt] = result.body[fetchedCt];
            }
        }

        localStorageSet('codetableCache', JSON.stringify(Array.from(cache.entries())));

        return resultPayload as CodetableResult;
    }
}

export type CodetableResult = {
    [key in Codetables]:
    | Availability[]
    | AdminPermission[]
    | AdminGroup[]
    | Disability[]
    | AgeGroup[]
    | Race[]
    | Gender[]
    | ManagementTeamSize[]
    | TimeZone[]
    | Country[]
    | WorkPosition[]
    | WorkDuration[]
    | Skill[]
    | Currency[]
    | CompanyCategory[]
    | CompanySize[]
    | DeveloperRole[]
    | TalentEngineeringExperience[]
    | SkillExperienceLevel[]
    | QuestionDifficulty[]
    | EnglishLevel[]
    | DomainExperienceLevel[]
    | ProductivityLevel[]
    | ProblemSolvingLevel[]
    | Region[]
    | TaskResultLevel[]
    | InterviewPositionRecommendation[]
    | InterviewPositionRecommendationLevel[]
    | OperatingSystem[]
    | InterviewTaskDefinition[]
    | QuestionType[];
};

export enum Codetables {
    TIMEZONES = 'timezones',
    COUNTRIES = 'countries',
    REGIONS = 'regions',
    WORK_POSITIONS = 'work_positions',
    WORK_DURATIONS = 'work_durations',
    SKILLS = 'skills',
    CURRENCIES = 'currencies',
    COMPANY_CATEGORIES = 'company_categories',
    COMPANY_SIZES = 'company_sizes',
    DEVELOPER_ROLES = 'developer_roles',
    TALENT_ENGINEERING_EXPERIENCES = 'talent_engineering_experiences',
    SKILL_EXPERIENCE_LEVELS = 'skill_experience_levels',
    QUESTION_DIFFICULTIES = 'question_difficulties',
    QUESTION_TYPES = 'question_types',
    MANAGEMENT_TEAM_SIZES = 'management_team_sizes',
    GENDERS = 'genders',
    RACES = 'races',
    AGE_GROUPS = 'age_groups',
    DISABILITIES = 'disabilities',
    AVAILABILITIES = 'availabilities',
    ADMIN_PERMISSIONS = 'admin_permissions',
    ADMIN_GROUPS = 'admin_groups',
    ENGLISH_LEVELS = 'english_levels',
    DOMAIN_EXPERIENCE_LEVELS = 'domain_experience_levels',
    PRODUCTIVITY_LEVELS = 'productivity_levels',
    PROBLEM_SOLVING_LEVELS = 'problem_solving_levels',
    TASK_RESULT_LEVELS = 'task_result_levels',
    INTERVIEW_POSITION_RECOMMENDATIONS = 'interview_position_recommendations',
    INTERVIEW_POSITION_RECOMMENDATION_LEVELS = 'interview_position_recommendation_levels',
    OPERATING_SYSTEMS = 'operating_systems',
    INTERVIEW_TASKS = 'interview_tasks',
}

export interface IInterviewTaskStage {
    sequence: number;
    name: string;
    url: string;
}

export interface IInterviewTaskDefinitionStageProperties {
    stages: IInterviewTaskStage[];
}

export interface InterviewTaskDefinition {
    name: string;
    displayName: string;
    role: string;
    url: string;
    properties: IInterviewTaskDefinitionStageProperties | null;
}

export interface EnglishLevel {
    name: ENGLISH_LEVEL;
    displayName: string;
    description: string;
    sortOrder: number;
}

export interface DomainExperienceLevel {
    name: DOMAIN_EXPERIENCE_LEVEL;
    displayName: string;
    description: string;
    sortOrder: number;
}

export interface ProductivityLevel {
    name: PRODUCTIVITY_LEVEL;
    displayName: string;
    description: string;
    sortOrder: number;
}

export interface ProblemSolvingLevel {
    name: PROBLEM_SOLVING_LEVEL;
    displayName: string;
    description: string;
    sortOrder: number;
}

export interface TaskResultLevel {
    name: TASK_RESULT_LEVEL;
    displayName: string;
    description: string;
    sortOrder: number;
}

export interface InterviewPositionRecommendation {
    name: string;
    displayName: string;
    sortOrder: number;
}

export interface InterviewPositionRecommendationLevel {
    name: INTERVIEW_POSITION_RECOMMENDATION_LEVEL;
    displayName: string;
    sortOrder: number;
}

export interface OperatingSystem {
    name: string;
    displayName: string;
}

export interface QuestionType {
    name: string;
    display_name: string;
}

export interface QuestionDifficulty {
    name: string;
    displayName: string;
    defaultAvailableTimeSeconds: number;
    defaultCorrectPoints: number;
    defaultIncorrectPoints: number;
}

export interface TimeZone {
    code: string;
    city: string;
}

export interface Country {
    name: string;
    code: string;
}

export interface Region {
    name: string;
    countries: Country[];
}

export const OTHER_WORK_POSITION = 'Other';

export interface WorkPosition {
    name: string;
    description: string;
}

export type WorkDuration = string;

export interface Skill {
    name: string;
}

export interface Currency {
    symbol: string;
    code: string;
    name: string;
}

export interface CompanyCategory {
    name: string;
    description: string;
}

export interface CompanySize {
    name: string;
    displayName: string;
    sortOrder: number;
}

export interface DeveloperRole {
    name: string;
    description?: string;
    displayName: string;
}

export interface TalentEngineeringExperience {
    name: string;
    displayName: string;
    visible: boolean;
}

export interface SkillExperienceLevel {
    name: string;
    displayName: string;
}

export interface TalentSkill {
    skill: string;
    experienceLevel: SkillExperienceLevel;
}

export interface AdminPermission {
    name: ADMIN_PERMISSIONS;
    displayName: string;
}

export interface AdminGroup {
    name: ADMIN_GROUPS;
    displayName: string;
}

export interface ManagementTeamSize {
    name: string;
    displayName: string;
    sortOrder: number;
}

export interface Gender {
    name: string;
    displayName: string;
    sortOrder: number;
}

export interface Race {
    name: string;
    displayName: string;
    sortOrder: number;
}

export interface AgeGroup {
    name: string;
    displayName: string;
    sortOrder: number;
}

export interface Disability {
    name: string;
    displayName: string;
    sortOrder: number;
}

export interface Availability {
    name: string;
    displayName: string;
    sortOrder: number;
}
