

























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IApiQuiz, IApiQuizCategory } from '@/admin/services/api/AdminQuizService.dtos';
import { ADMIN_QUIZ_SERVICE } from '@/admin/services/api/AdminQuizService';
import RichTextEditor from '@/admin/components/RichTextEditor.vue';

interface LevelData {
    level: number;
    name: string | null;
    description: string | null;
    minPercentage: number | null;
    maxPercentage: number | null;
}

@Component({
    components: {
        RichTextEditor,
    }
})
export default class GenerateNewQuizCategoryScoreLevelsModal extends Vue {
    buttonLoading = false;
    activeStep = 0;

    @Prop()
    quiz!: IApiQuiz;

    @Prop()
    category!: IApiQuizCategory;

    nrLevels: number | null = null;
    levelData: LevelData[] = [];
    nextLevelThatNeedsMaxPercentage: number | null = null;

    closeModal(refresh: boolean) {
        this.$emit('close', refresh);
    }

    async generateLevels() {
        if (this.nrLevels && this.nrLevels >= 2) {
            this.levelData = [];
            for (let i = 0; i < this.nrLevels; ++i) {
                this.levelData.push({
                    name: null,
                    level: i + 1,
                    description: null,
                    minPercentage: i === 0 ? 0 : null,
                    maxPercentage: i === this.nrLevels - 1 ? 100 : null,
                });
            }
            this.nextLevelThatNeedsMaxPercentage = 1;
            this.activeStep = 1;
        } else {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: 'Please enter number of desired levels',
                type: 'is-warning',
            });
        }
    }

    async save() {
        this.buttonLoading = true;
        try {
            await ADMIN_QUIZ_SERVICE.createQuizCategoryScoreLevels(
                this.quiz.id,
                this.category.id,
                this.levelData.map((l) => {
                    return {
                        name: l.name as string,
                        description: l.description as string,
                        level: l.level,
                        minPercentage: l.minPercentage as number,
                        maxPercentage: l.maxPercentage as number,
                    };
                })
            );
            this.closeModal(true);
        } catch (e) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: `Error while generating quiz category score levels! ${e.message}`,
                type: 'is-danger',
            });
        }
        this.buttonLoading = false;
    }

    resetEdit(level: number) {
        for (const data of this.levelData) {
            if (data.level === level) {
                data.maxPercentage = null;
            }

            if (data.level > level) {
                data.minPercentage = null;

                if (data.level !== this.levelData.length) {
                    data.maxPercentage = null;
                }
            }
        }

        this.nextLevelThatNeedsMaxPercentage = level;
    }

    setMaxPercentage(level: number) {
        const currentLevel = this.levelData[level - 1];
        const nextLevel = this.levelData[level];

        nextLevel.minPercentage = currentLevel.maxPercentage;

        if (level + 1 != this.nrLevels) {
            this.nextLevelThatNeedsMaxPercentage = level + 1;
        } else {
            this.nextLevelThatNeedsMaxPercentage = null;
        }
    }

    canSave(): boolean {
        for (const level of this.levelData) {
            if (!level.name || level.name.length < 3) {
                return false;
            }

            if (!level.description || level.description.length < 3) {
                return false;
            }

            if (level.minPercentage === null) {
                return false;
            }

            if (level.maxPercentage === null) {
                return false;
            }

            if (level.minPercentage > level.maxPercentage) {
                return false;
            }

            if (level.level === 1 && level.minPercentage !== 0) {
                return false;
            }

            if (level.level === this.levelData.length && level.maxPercentage !== 100) {
                return false;
            }

            if (level.level !== this.levelData.length) {
                const nextLevel = this.levelData[level.level];
                if (level.maxPercentage !== nextLevel.minPercentage) {
                    return false;
                }
            }
        }

        return true;
    }
}
