import { ApiServiceBase } from '@/shared/service/ServiceBase';
import { CALENDLY_EVENT_RESOLUTION } from '@/admin/services/api/AdminCalendlyService.dtos';

export class AdminCalendlyService extends ApiServiceBase {
  public constructor(baseApiUrl?: string) {
    super(baseApiUrl || '/api/user-service/admin/calendly');
  }

  public async getAllEvents(fromDate: Date, toDate: Date): Promise<any[]> {
    return await this.safeGetJson('/', {
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    });
  }

  public async getEvent(eventId: string): Promise<any> {
    return await this.safePostJson('/event', {
      eventId,
    });
  }

  public async claimInterview(eventId: string): Promise<void> {
    await this.safePostResponse('/claim', {
      eventId,
    });
  }

  public async releaseInterview(eventId: string): Promise<void> {
    await this.safePostResponse('/release', {
      eventId,
    });
  }

  public async resolveInterview(eventId: string,
                                resolution: CALENDLY_EVENT_RESOLUTION,
                                notes?: string,
                                videoUrl?: string): Promise<void> {
    await this.safePostResponse('/resolution', {
      eventId,
      resolution,
      notes,
      videoUrl,
    });
  }
}

export const ADMIN_CALENDLY_SERVICE = new AdminCalendlyService();
