


































































































































import { Component, Prop } from 'vue-property-decorator';
import { IApiBasicTalentData, IApiTalentProperties } from '@/admin/services/api/AdminService.dtos';
import { Codetables, CodetableService, Skill, SkillExperienceLevel, TimeZone } from '@/shared/service/CodetableService';
import ComponentBase from '@/admin/components/ComponentBase';
import { ADMIN_SERVICE } from '@/admin/services/api/AdminService';

@Component({})
export default class UserTalentEdit extends ComponentBase {
    @Prop({})
    user!: IApiBasicTalentData;

    properties: IApiTalentProperties | null = null;

    availableSocials = [
        'github', 'gitlab', 'stackoverflow', 'linkedin', 'twitter', 'website', 'other',
    ];

    allTimezones: TimeZone[] = [];
    allSkills: Skill[] = [];
    skillLevels: SkillExperienceLevel[] = [];

    newMainSkill: string | null = null;
    newMainSkillLevel: string | null = null;

    newSupportSkill: string | null = null;
    newSupportSkillLevel: string | null = null;

    filteredMainSkills: string[] = [];

    mainSkillNames(text: string) {
        this.filteredMainSkills = this.allSkills.filter((skill) => {
            return skill.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        }).map(s => s.name);
    }

    selectMainSkill(skill: string) {
        if (skill) {
            this.newMainSkill = skill;
        } else {
            this.newMainSkill = null;
        }
    }

    filteredSupportSkills: string[] = [];

    supportSkillNames(text: string) {
        this.filteredSupportSkills = this.allSkills.filter((skill) => {
            return skill.name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
        }).map(s => s.name);
    }

    selectSupportSkill(skill: string) {
        if (skill) {
            this.newSupportSkill = skill;
        } else {
            this.newSupportSkill = null;
        }
    }

    socialLabel(st: string) {
        switch (st) {
            case 'github':
                return 'Github';
            case 'gitlab':
                return 'Gitlab';
            case 'stackoverflow':
                return 'Stackoverflow';
            case 'linkedin':
                return 'LinkedIn';
            case 'twitter':
                return 'Twitter';
            case 'website':
                return 'Personal Website';
            case 'other':
                return 'Other';
        }
    }

    socials: Record<string, string> = {};

    closeModal() {
        this.$emit('close');
    }

    removeMainSkill(skill: string) {
        if (this.properties && this.properties.TALENT_MAIN_SKILLS) {
            this.properties.TALENT_MAIN_SKILLS = this.properties.TALENT_MAIN_SKILLS.filter(s => s.TALENT_SKILL_NAME !== skill);
        }
    }

    removeSupportSkill(skill: string) {
        if (this.properties && this.properties.TALENT_SUPPORT_SKILLS) {
            this.properties.TALENT_SUPPORT_SKILLS = this.properties.TALENT_SUPPORT_SKILLS.filter(s => s.TALENT_SKILL_NAME !== skill);
        }
    }

    addMainSkill() {
        if (this.properties && this.newMainSkill && this.newMainSkillLevel) {
            const newSkillName = this.newMainSkill.trim();
            if (this.properties.TALENT_MAIN_SKILLS) {
                if (this.properties.TALENT_MAIN_SKILLS.find(tms => tms.TALENT_SKILL_NAME === newSkillName) === undefined) {
                    this.properties.TALENT_MAIN_SKILLS.push({
                        TALENT_SKILL_NAME: newSkillName,
                        TALENT_SKILL_EXPERIENCE: this.newMainSkillLevel,
                    });
                }
            } else {
                this.properties.TALENT_MAIN_SKILLS = [{
                    TALENT_SKILL_NAME: newSkillName,
                    TALENT_SKILL_EXPERIENCE: this.newMainSkillLevel,
                }];
            }

            this.newMainSkill = null;
            this.newMainSkillLevel = null;
        }
    }

    addSupportSkill() {
        if (this.properties && this.newSupportSkill && this.newSupportSkillLevel) {
            const newSkillName = this.newSupportSkill.trim();
            if (this.properties.TALENT_SUPPORT_SKILLS) {
                if (this.properties.TALENT_SUPPORT_SKILLS.find(tms => tms.TALENT_SKILL_NAME === newSkillName) === undefined) {
                    this.properties.TALENT_SUPPORT_SKILLS.push({
                        TALENT_SKILL_NAME: newSkillName,
                        TALENT_SKILL_EXPERIENCE: this.newSupportSkillLevel,
                    });
                }
            } else {
                this.properties.TALENT_SUPPORT_SKILLS = [{
                    TALENT_SKILL_NAME: newSkillName,
                    TALENT_SKILL_EXPERIENCE: this.newSupportSkillLevel,
                }];
            }

            this.newSupportSkill = null;
            this.newSupportSkillLevel = null;
        }
    }

    async mounted() {
        const codetables = await CodetableService.getCodetables(
                Codetables.SKILLS,
                Codetables.SKILL_EXPERIENCE_LEVELS,
                Codetables.TIMEZONES,
        );
        this.allTimezones = codetables.timezones as TimeZone[];
        this.allSkills = codetables.skills as Skill[];
        this.skillLevels = codetables.skill_experience_levels as SkillExperienceLevel[];

        this.properties = Object.assign({}, this.user.properties);

        this.availableSocials.forEach(s => {
            this.socials[s] = '';
        });

        if (this.user.socials) {
            this.user.socials.forEach(s => {
                this.socials[s.social] = s.link;
            });
        }
    }

    async save() {
        await this.safeExecuteWithConfirmation(async () => {
                    const newProps = Object.assign({}, this.properties);
                    if (newProps?.TALENT_SUPPORT_SKILLS && newProps.TALENT_SUPPORT_SKILLS.length === 0) {
                        delete newProps.TALENT_SUPPORT_SKILLS;
                    }
                    await ADMIN_SERVICE.updateTalentData(this.user.userId, {
                        socials: this.socials,
                        properties: newProps,
                    });
                    this.closeModal();
                }, 'Updating talent user',
                'Are you sure you want to update this user?',
                'Something went wrong while updating talent user data! Please check logs/contact administrator!',
                'is-warning');
    }

}
